/* External dependencies */
import React, { useState, useEffect, FormEvent } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Stripe, loadStripe } from '@stripe/stripe-js';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { createPaymentIntent, getStripeConfig } from 'src/api/orders';
import { Elements } from '@stripe/react-stripe-js';
import { getEvent } from 'src/store/ducks/events';
import Spinner from 'src/spinner/Spinner';
import Colors from 'src/colors';
import Event from 'src/event/Event';
import MediaObject from 'src/mediaObject/MediaObject';
import CheckoutForm from './CheckoutForm';
import { Event as EventType } from 'src/types/Event';
import { DEFAULT_FEES, getApplicationFee, getDiscountAmount, getFinalItemAmount } from 'src/store/helpers/tickets';
import './CheckoutPayment.scss';
import { convertToAmountInPennies } from '../store/helpers/tickets';
import { STRIPE_TEST_MODE } from '../App';
import { Form } from 'react-bootstrap';
import Community from 'src/types/Community';
import User from 'src/types/User';
import Analytics, { AnalyticsEventType } from 'src/analytics/Analytics';
import Promotion, { DiscountReward } from 'src/types/Promotion';
import Icon, { Icons } from 'src/icon/Icon';

type OwnProps = {
  event: EventType;
  ticket: any;
  quantity: number;
  incrementStepIndex(): void;
  activePromotion?: Promotion;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = OwnProps & StateProps;

type State = {
  stripePromise?: Promise<Stripe | null>;
  clientSecret?: string;
  checkoutId?: string;
  message?: string;
  isProcessing: boolean;
  loading: boolean;
  joinCommunity: boolean;
};

const CheckoutPayment: React.FC<Props> = ({
  event, ticket, quantity,
  incrementStepIndex,
  activePromotion,
}) => {
  const [stripePromise, setStripePromise] = useState<State['stripePromise']>(undefined);
  const [clientSecret, setClientSecret] = useState<State['clientSecret']>(undefined);
  const [checkoutId, setCheckoutId] = useState<State['checkoutId']>(undefined);
  const [joinCommunity, setJoinCommunity] = useState<State['joinCommunity']>(true);
  const [loading, setLoading] = useState<State['loading']>(false);
  
  const eventId = get(event, 'id');
  const ticketId = get(ticket, 'id');
  const ticketName = get(ticket, 'name');
  const ticketDescription = get(ticket, 'description');
  const ticketPrice = get(ticket, 'priceValue', 0);
  const application_fee_amount = getApplicationFee(ticket, quantity, DEFAULT_FEES);
  const applicationFeeInPennies = convertToAmountInPennies(application_fee_amount);
  const currency = 'usd';
  // Reward
  const activeReward = get(activePromotion, 'rewards.items[0]');
  const rewardDiscount = get(activeReward, 'payload') as DiscountReward;
  const rewardDiscountType = get(rewardDiscount, 'discountType');
  const rewardDiscountValue = get(rewardDiscount, 'value');
  const finalAmount = getFinalItemAmount(ticket, quantity, DEFAULT_FEES, rewardDiscount);
  const finalAmountInPennies = convertToAmountInPennies(finalAmount);

  const eventCreators = get(event, 'creators.items', []);
  const eventCreatorsCommunities = eventCreators.filter(({ type }: User | Community) => type === 'community');
  const eventCommunitiesNames = 
    eventCreatorsCommunities.length === 1
        ? eventCreatorsCommunities[0].name!
        : eventCreatorsCommunities.length === 2
          ? `${eventCreatorsCommunities[0].name!} and ${eventCreatorsCommunities[1].name!}`
          : eventCreatorsCommunities.reduce((acc: string, curr: Community, index: number) => {
            if (index === 0) {
              return curr.name!;
            }

            if (index < eventCreatorsCommunities.length - 1) {
              return `${acc}, ${curr.name}`;
            }

            return `${acc}, and ${curr.name}`;
          }, '');
  const hasCommunityCreators = Boolean(eventCreatorsCommunities && eventCreatorsCommunities.length);

  useEffect(() => {
    const loadConfig = async () => {
      const { publishableKey } = await getStripeConfig({ testMode: STRIPE_TEST_MODE });
      const load = async () => {
        const promiseResponse = await loadStripe(publishableKey);

        return promiseResponse;
      };

      setStripePromise(load);
    };

    loadConfig();
  }, []);

  useEffect(() => {
    const createPayment = async () => {
      setLoading(true);
      try {
        const { clientSecret, checkoutId } = await createPaymentIntent({
          amount: finalAmountInPennies, // Measured in pennies. Multiplied by 100 to convert dollars to pennies.
          currency,
          application_fee_amount: applicationFeeInPennies, // Measured in pennies. Multiplied by 100 to convert dollars to pennies.
          metadata: {
            eventId,
            ticketId,
            quantity,
          },
          order: {
            orderItems: {
              items: [{
                priceValue: ticketPrice,
                currency,
                quantity,
                itemId: ticketId,
              }]
            },
            promotion: activePromotion,
          },
        }, { testMode: STRIPE_TEST_MODE });

        setClientSecret(clientSecret);
        setCheckoutId(checkoutId);
      } catch (e) {
        console.log('createPaymentIntent error: ', e);
      } finally {
        setLoading(false);
      }
    };

    createPayment();
  }, []);

  useEffect(() => {
    if (!checkoutId) return;

    Analytics.track(AnalyticsEventType.checkoutStepViewed, { step: 1, checkout_id: checkoutId });
  }, [checkoutId]);



  const stripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'flat',
      variables: {
        fontFamily: 'gotham-medium, Fallback, sans-serif',
        colorPrimary: Colors.primary,
        // borderRadius: '100px',TODO: See if you can isolate border radius to buttons and fields only
      },
    },
    loader: 'auto',
    defaultValues: {
      billingDetails: {
        name: 'John Doe',
        phone: '888-888-8888',
        address: {
          postal_code: '10001',
          country: 'US',
        }
      },
    },
  } as any;

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ flex: 1, width: '100%' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="bm-CheckoutPayment">
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={stripeElementsOptions}>
          <div className="container d-flex justify-content-between flex-wrap">
            <div className="bm-CheckoutPayment__leftSection">
              <div className="mb-5">
                <Event event={event} imageSize={125} wrap={false} />
              </div>
              <div className="bm-CheckoutPayment__orderSummary mb-3">
                <h6 className="text-white mb-3">Order summary</h6>
                <div className="pt-3 pb-3" style={{ borderTop: '1px solid rgba(255,255,255,0.25)', borderBottom: '1px solid rgba(255,255,255,0.25)' }}>
                  <MediaObject
                    className="bm-CheckoutPayment__orderSummaryItem"
                    text={(
                      <p className="text-white m-0 p-0">{ticketName}</p>
                    )}
                    textColor="light"
                    subtext={(
                      <p className="m-0 p-0" style={{ color: Colors.inactiveTab }}>
                        {ticketDescription}
                      </p>
                    )}
                    right={(
                      <h6 className="text-white m-0 p-0">{`$${getFinalItemAmount(ticket, quantity, DEFAULT_FEES)}`}</h6>
                    )}
                    image={(
                      <div style={{ width: 40 }}>
                        <h4 className="text-white m-0 p-0">x{quantity}</h4>
                      </div>
                    )}
                    imageSize={40}
                    imageMargin="0.75rem"
                    wrap={false}
                  />
                  {Boolean(rewardDiscount) && (
                    <MediaObject
                      className="mt-4"
                      text={(
                        <p className="text-bold text-white m-0 p-0">
                          "{get(activePromotion, 'promoCode')}"
                        </p>
                      )}
                      subtext={(
                        <p className="bm-Text--secondaryText"
                          // style={{ color: Colors.inactiveTab }}
                        >
                          {Boolean(rewardDiscountType === 'amount') ? `$${rewardDiscountValue} off discount` : `${rewardDiscountValue}% off discount`}
                        </p>
                      )}
                      right={(
                        <p className="text-bold text-success">{`–$${getDiscountAmount(ticket, quantity, rewardDiscount).toFixed(2)}`}</p>
                      )}
                      image={(
                        <div style={{ width: 40 }}>
                          <Icon name={Icons.tag2} className="bm-Icon--white" size={30} />
                        </div>
                      )}
                      imageSize={40}
                      imageMargin="0.75rem"
                      wrap={false}
                    />
                  )}
                </div>
                <div>
                  <MediaObject
                    text={(
                      <h4 className="text-white m-0 p-0 mb-1">Total</h4>
                    )}
                    textColor="light"
                    subtext={(
                      <p className="m-0 p-0" style={{ color: Colors.inactiveTab }}>Tax included</p>
                    )}
                    right={(
                      <h4 className="text-white m-0 p-0">{`$${getFinalItemAmount(ticket, quantity, DEFAULT_FEES, rewardDiscount)}`}</h4>
                    )}
                    wrap={false}
                  />
                </div>
                {Boolean(hasCommunityCreators) && (
                  <div className="mt-2">
                    <Form.Check
                      checked={joinCommunity}
                      onChange={(e) => {
                        const checked = get(e, 'target.checked', !joinCommunity);
                        setJoinCommunity(checked);
                      }}
                      type="checkbox"
                      title="Want to join community?"
                      label={`Join the ${eventCommunitiesNames} ${Boolean(eventCreatorsCommunities.length === 1) ? 'community' : 'communities'} to hear about event drops, exclusives, and more announcements!`}
                      className="text-white"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="bm-CheckoutPayment__rightSection">
              <CheckoutForm
                event={event}
                ticket={ticket}
                quantity={quantity}
                incrementStepIndex={incrementStepIndex}
                joinCommunity={joinCommunity}
                activePromotion={activePromotion}
              />
            </div>
          </div>
        </Elements>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState, { event }: OwnProps) => {
  const eventId = get(event, 'id');

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, eventId) || event,
  };
};

export default connect(mapStateToProps)(CheckoutPayment);