/* Internal dependencies */
import React, { FormEvent, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import get from 'lodash/get';
import { PaymentElement } from '@stripe/react-stripe-js';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import { getEvent } from 'src/store/ducks/events';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';
import { StripePaymentElementOptions } from '@stripe/stripe-js';

type OwnProps = {
  event: any;
  ticket: any;
  quantity: number;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = OwnProps & StateProps;

const CheckoutForm: React.FC<Props> = ({ currentUser }) => {
  const paymentOptions: StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
    fields: {
      billingDetails: 'auto',
    },
    setupFutureUsage: 'off_session',
    defaultValues: {
      billingDetails: {
        name: get(currentUser, 'name'),
        phone: get(currentUser, 'phone'),
        address: {
          line1: get(currentUser, 'location.address1'),
          line2: get(currentUser, 'location.address2'),
          city: get(currentUser, 'location.city'),
          state: get(currentUser, 'location.state'),
          country: get(currentUser, 'location.country'),
          postal_code: get(currentUser, 'location.zipCode'),
        },
      },
    },
    wallets: {
      applePay: 'auto',
    },
  } as any;

  return (
    <PaymentElement
      id="payment-element"
      options={paymentOptions}
    />
  );
};

const mapStateToProps = (state: ApplicationState, { event }: OwnProps) => {
  const eventId = get(event, 'id');

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, eventId) || event,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);