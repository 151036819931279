/* External dependencies */
import React, { useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

/* Internal dependencies */
import Button, { Props as ButtonProps } from 'src/button/Button';
import './ModalButton.scss';

type OwnProps = ButtonProps & {
  open: boolean;
  openModal(): void;
  closeModal(): void;
  header?: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
  modalProps?: Partial<ModalProps>;
  render?: (onClick: () => void) => React.ReactNode;
};

type Props = OwnProps;

const ModalButton: React.FC<Props> = (props) => {
  const {
    open,
    openModal,
    closeModal,
    header,
    content,
    footer,
    modalProps,
    render,
  } = props;

  const handleClick = () => {
    openModal && openModal();
  };

  const modal = (
    <Modal
      {...modalProps}
      show={open}
      onShow={openModal}
      onHide={closeModal}
      centered={true}
      size="lg"
    >
      {Boolean(header) && (
        <Modal.Header>
          {header}
        </Modal.Header>
      )}
      <Modal.Body>
        {content}
      </Modal.Body>
      {Boolean(footer) && (
        <Modal.Footer>
          {footer}
        </Modal.Footer>
      )}
    </Modal>
  );

  if (render) {
    return (
      <span>
        {render(handleClick)}
        {modal}
      </span>
    )
  }

  return (
    <>
      <Button {...props} onClick={handleClick} />
      {modal}
    </>
  );
};

export default ModalButton;