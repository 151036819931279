/* External dependencies */
import React, { useState } from 'react';

/* Internal dependencies */
import Button, { Props as ButtonProps } from '../button/Button';

type Props = ButtonProps & {
  asyncOnClick?: (() => Promise<any>) | (() => void);
  immediate?: boolean;
  text?: string;
  popToTop?: boolean;
  render?: (onClick: () => void, loading: boolean) => React.ReactElement<any>;
  waitForAsync?: boolean;
};

type State = {
  loading: boolean;
};

// TODO: fix bug where the previous screen isn't what is expected...might need to solve with global tree for screem (route) hierarchy
const DoneButton: React.FC<Props> = ({
  asyncOnClick, immediate = true, popToTop = false,
  waitForAsync = true, text, render, ...rest
}) =>{
  const [loading, setLoading] = useState<State['loading']>(false);

  const handleClick = async () => {
    let response;

    try {
      setLoading(true);

      if (asyncOnClick) {
        if (waitForAsync) {
          response = await asyncOnClick(); // TODO: add error handling if call fails
        } else {
          asyncOnClick();
        }
      }

      return response;
    } finally {
      setLoading(false);
    }
  };

  if (render) {
    return render(handleClick, loading);
  }

  return (
    <Button variant="link" {...rest} onClick={handleClick} loading={loading}>
      {text || 'Done'}
    </Button>
  );
}

export default DoneButton;
