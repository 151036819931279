/* External dependencies */
import React, { useState } from 'react';
import { ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { LineItem } from '@stripe/stripe-js';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import { addNotification } from 'src/store/ducks/notifications';
import { Dispatch } from 'redux';

/* Internal dependencies */
import { Notification } from 'src/types/Notification';
import Analytics, { AnalyticsEventType } from 'src/analytics/Analytics';
import { addOrder } from 'src/api/orders';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { getEvent } from 'src/store/ducks/events';
import { ApplicationState } from 'src/store';
import User from 'src/types/User';
import { convertToAmountInPennies, DEFAULT_FEES, getApplicationFee, getFinalItemAmount } from 'src/store/helpers/tickets';
import { providerToProviderCode } from './Events';

type OwnProps = {
  event: any;
  ticket: any;
  quantity: number;
  incrementStepIndex(): void;
  joinCommunity: boolean;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  message?: string;
  isProcessing: boolean;
};

const ExpressCheckout: React.FC<Props> = ({
  event,
  ticket,
  quantity,
  incrementStepIndex,
  currentUser,
  addNotification,
  joinCommunity,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<State['message']>(undefined);
  const [isProcessing, setIsProcessing] = useState<State['isProcessing']>(false);

  const currentUserId = get(currentUser, 'id');
  const eventName = get(event, 'name');
  const ticketName = get(ticket, 'name');
  const applicationFee = getApplicationFee(ticket, quantity, DEFAULT_FEES);
  const total = getFinalItemAmount(ticket, quantity, DEFAULT_FEES);

  const lineItems: LineItem[] = [];
  
  for (let i = 0; i < quantity; i++) {
    const total = getFinalItemAmount(ticket, 1, DEFAULT_FEES);

    lineItems.push({
      name: `${eventName} – ${ticketName} (via Beatmatch)`,
      amount: convertToAmountInPennies(total),
    });
  }

  const expressCheckoutOptions = {
    buttonHeight: 50,
    buttonTheme: {
      applePay: 'black',
    },
    paymentMethods: {
      applePay: 'always'
    }
  } as any;

  const onConfirm = async (e: any) => {
    e && e.preventDefault && e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
      redirect: 'if_required',
    });

    console.log('confirmPayment error: ', error);

    if (!error) {
      console.log('currentUserId for paid addOrder...', currentUserId);
      console.log('currentUser for paid addOrder...', currentUser);
      const order = await addOrder({
        orderItems: {
          items: [{
            priceValue: get(ticket, 'priceValue'),
            quantity,
            currency: get(ticket, 'currency'),
            item: ticket,
            itemId: get(ticket, 'id'),
          }],
        },
        tags: {
          items: [
            { itemId: get(event, 'id') },
            { itemId: get(event, 'creatorId') }, // User or Community
          ],
        },
        buyer: pick(currentUser as User, ['id', 'name', 'email', 'phone']),
        userId: get(currentUser, 'id')!,
      }, { joinCommunity });
      const orderId = get(order, 'id');

      console.log('handleSubmit order', order);
      console.log('handleSubmit orderId', orderId);

      try {
        const product = {
          product_id: get(event, 'id'),
          sku: get(ticket, 'id'),
          category: 'event',
          name: get(event, 'name'),
          brand: get(event, 'creator.name'),
          variant: get(ticket, 'name'),
          currency: get(ticket, 'currency'),
          price: get(ticket, 'priceValue'),
          quantity,
          url: `https://www.beatmatch.app/events/${providerToProviderCode[get(event, 'provider')]}/${get(event, 'providerId')}`,
          image_url: get(event, 'images[0].url'),
        };

        Analytics.track(AnalyticsEventType.orderCompleted, {
          order_id: orderId,
          affiliation: 'Beatmatch',
          subtotal: total,
          revenue: applicationFee,
          products: [product],
        });
      } catch (e) {
        console.log('Error logging order completion: ', e);
      }

      incrementStepIndex && incrementStepIndex();
      return;
    }

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
      addNotification({
        title: `Error processing payment: ${error.type}`,
        message: error.message!,
        variant: 'danger',
      });
    } else {
      setMessage("An unexpected error occured.");
      addNotification({
        title: `Error processing payment: ${error.type}`,
        message: error.message! || 'An unexpected error occured.',
        variant: 'danger',
      });
    }

    setIsProcessing(false);
  };

  const onClick = ({ resolve }: any) => {
    const options = {
      emailRequired: true,
      phoneNumberRequired: true,
      billingAddressRequired: true,
      business: { name: 'Beatmatch, Inc.' },
      lineItems,
    };
    resolve(options);
  };

  return (
    <ExpressCheckoutElement
      onConfirm={onConfirm}
      onClick={onClick}
      options={expressCheckoutOptions}
    />
  );
};

const mapStateToProps = (state: ApplicationState, { event }: OwnProps) => {
  const eventId = get(event, 'id');

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, eventId) || event,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpressCheckout);