/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import API from '.';

export const addPromotion = async (promotion: any) =>
  await API.post({
    path: `/promotions`,
    options: {
      body: promotion,
    },
  });

export const addPromotionForEvent = async (eventId: string, promotion: any) => addPromotion({
  ...promotion,
  creatorId: eventId,
});

export const addPromotionForCommunity = async (communityId: string, promotion: any) => addPromotion({
  ...promotion,
  creatorId: communityId,
});

export const updatePromotion = async (promotion: any) =>
  await API.put({
    path: `/promotions/${get(promotion, 'id')}`,
    options: {
      body: promotion,
    },
  });

export const updatePromotionForEvent = async (promotion: any) => updatePromotion(promotion);

export const updatePromotionForCommunity = async (promotion: any) => updatePromotion(promotion);

export const removePromotion = async (promotion: any) =>
  await API.del({
    path: `/promotions/${get(promotion, 'id')}`,
  });

export const getPromoCodeAvailability = async (itemId: string, promoCodeId: string) =>
  await API.get({
    path: `/promotions/getPromoCodeAvailability`,
    options: {
      queryParams: {
        itemId,
        promoCodeId: `promoCode:${promoCodeId}`,
      },
    },
  });

export const redeemPromoCode = async (promoCode: string, itemId: string) =>
  await API.post({
    path: `/promotions/redeem`,
    options: {
      body: { promoCode, itemId },
    },
  });