/* Internal dependencies */
import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import get from 'lodash/get';
import pick from 'lodash/pick';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import { getEvent } from 'src/store/ducks/events';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import Button from 'src/button/Button';
import AuthModalButton from 'src/authModal/AuthModalButton';
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';
import { DEFAULT_FEES, getFinalItemAmount } from 'src/store/helpers/tickets';
import { addOrder } from 'src/api/orders';
import User from 'src/types/User';
import './CheckoutButton.scss';
import Analytics, { AnalyticsEventType } from '../analytics/Analytics';
import { providerToProviderCode } from './Events';
import Promotion, { DiscountReward } from 'src/types/Promotion';

type OwnProps = {
  currentUser: CurrentUserState['user'];
  event: any;
  ticket: any;
  quantity: number;
  iconSize?: number;
  showIcon?: boolean;
  incrementStepIndex(): void;
  jumpStepIndex(index: number): void;
  render?: (params: { onClick: MouseEventHandler; loading: boolean; }) => React.ReactNode;
  activePromotion?: Promotion;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  checkoutModalOpen: boolean;
  loading: boolean;
};

const CheckoutButton: React.FC<Props> = ({
  currentUser,
  event, ticket, quantity = 1,
  render, incrementStepIndex, jumpStepIndex,
  activePromotion,
}) => {
  const [loading, setLoading] = useState<State['loading']>(false);

  const currentUserId = get(currentUser, 'id');
  const ticketPrice = get(ticket, 'priceValue');

  // Reward
  const activeReward = get(activePromotion, 'rewards.items[0]');
  const rewardDiscount = get(activeReward, 'payload') as DiscountReward;


  const handleCheckout = async (currentUser: User) => {
    try {
      setLoading(true);

      if (ticketPrice === 0) {
        console.log('currentUser for free addOrder...', currentUser);
        const order = await addOrder({
          orderItems: {
            items: [{
              priceValue: get(ticket, 'priceValue'),
              quantity,
              currency: get(ticket, 'currency'),
              item: ticket,
              itemId: get(ticket, 'id'),
            }],
          },
          tags: {
            items: [
              { itemId: get(event, 'id') },
              { itemId: get(event, 'creatorId') }, // User or Community
            ],
          },
          buyer: pick(currentUser as User, ['id', 'name', 'email', 'phone']),
          userId: get(currentUser, 'id')!,
        }, { joinCommunity: true });
        const orderId = get(order, 'id');

        console.log('handleCheckout order', order);
        console.log('handleCheckout orderId', orderId);

        try {
          const product = {
            product_id: get(event, 'id'),
            sku: get(ticket, 'id'),
            category: 'event',
            name: get(event, 'name'),
            brand: get(event, 'creator.name'),
            variant: get(ticket, 'name'),
            currency: get(ticket, 'currency'),
            price: get(ticket, 'priceValue'),
            quantity,
            url: `https://www.beatmatch.app/events/${providerToProviderCode[get(event, 'provider')]}/${get(event, 'providerId')}`,
            image_url: get(event, 'images[0].url'),
          };

          console.log('should log order complete w/ product...', product);
          await Analytics.track(AnalyticsEventType.orderCompleted, {
            order_id: orderId,
            affiliation: 'Beatmatch',
            subtotal: 0,
            revenue: 0,
            products: [product],
          });
          console.log('completed logging order complete...');
        } catch (e) {
          console.log('Error logging order completion: ', e);
        }

        jumpStepIndex(2);
      } else {
        incrementStepIndex();
      }
    } catch (e) {
      console.log('handleCheckout error: ', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AuthModalButton
        modalHeader={`Register / Sign in to get ${get(event, 'name', 'this')} tickets`}
        onClick={handleCheckout as any}
        method="phone"
        render={(onClick) => {

          if (render) {
            return render({ onClick, loading });
          }

          return (
            <Button
              loading={loading}
              disabled={!event || !ticket || !quantity}
              className="btn btn-block d-flex justify-content-center align-items-center pl-3 pr-3"
              variant="primary"
              onClick={onClick}
              style={{ borderRadius: 100 }}
            >
              <div className="d-flex align-items-center">
                <h5 className="bm-CheckoutButton_buttonText pt-1 text-bold text-white">{Boolean(ticketPrice === 0) ? 'RSVP – Free' : `Checkout - $${getFinalItemAmount(ticket, quantity, DEFAULT_FEES, rewardDiscount)}`}</h5>
              </div>
            </Button>
          );
        }}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState, { event }: OwnProps) => {
  const eventId = get(event, 'id');

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, eventId) || event,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutButton);