/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import API from '.';
import User from 'src/types/User';
import { PaginationOptions } from 'src/types/Pagination';

export const getSpotifyOrAppleMusicMyRecentlyPlayedTracks = async (currentUser: User, options: PaginationOptions = { limit: 15 }): Promise<any[]> => {
  const isSpotifyUser = get(currentUser, 'credentials.spotify');
  const isAppleMusicUser = get(currentUser, 'credentials.appleMusic');
  const limit = get(options, 'limit', 15);
  let tracks = [];

  if (isSpotifyUser) {
    const { items = [] } = await getMySavedTracks(limit);
    tracks = items;
  } else {
    const spotifyHotHitsUSAPlaylistId = '37i9dQZF1DX0kbJZpiYdZl';
    const { items = [] } = await getPlaylistTracks(spotifyHotHitsUSAPlaylistId, limit);
    tracks = items;
  }

  return tracks;
};

export const getMyRecentlyPlayedTracks = async (userId: string, limit: number = 25) =>
  await API.get({
    path: `/spotify/getMyRecentlyPlayedTracks`,
    options: {
      queryParams: { userId, limit },
    },
  });

export const getMySavedTracks = async (limit: number = 25, offset: number = 0) =>
  await API.get({
    path: `/spotify/getMySavedTracks`,
    options: {
      queryParams: { limit, offset },
    },
  });

export const getMyTopTracks = async (limit: number = 25, offset: number = 0) =>
  await API.get({
    path: `/spotify/getMyTopTracks`,
    options: {
      queryParams: { limit, offset },
    },
  });

export const getMyTopArtists = async (limit: number = 25, offset: number = 0) =>
  await API.get({
    path: `/spotify/getMyTopArtists`,
    options: {
      queryParams: { limit, offset },
    },
  });

export const getUserPlaylists = async (userId: string, limit: number = 25, offset: number = 0) =>
  await API.get({
    path: `/spotify/getUserPlaylists`,
    options: {
      queryParams: { userId, limit, offset },
    },
  });

export const getPlaylistsForSpotifyUser = async (userId: string, limit: number = 25, offset: number = 0) =>
  await API.get({
    path: `/spotify/getPlaylistsForSpotifyUser`,
    options: {
      queryParams: { userId, limit, offset },
    },
  });

export const getPlaylistTracks = async (playlistId: string, limit: number = 25, offset: number = 0) =>
  await API.get({
    path: `/spotify/getPlaylistTracks`,
    options: {
      queryParams: { playlistId, limit, offset },
    },
  });

export const getRecommendations = async ({ limit = 25, seed_tracks = [], seed_artists = [], seed_genres = [] }) =>
  await API.get({
    path: `/spotify/getRecommendations`,
    options: {
      queryParams: {
        limit,
        seed_tracks: seed_tracks.join(','),
        seed_artists: seed_artists.join(','),
        seed_genres: seed_genres.join(','),
      },
    },
  });

export const getAvailableGenreSeeds = async () =>
  await API.get({
    path: `/spotify/getAvailableGenreSeeds`,
  });

export const addTrackToBeatmatchPlaylist = async (providerId: string) =>
  await API.post({
    path: '/spotify/addTrackToBeatmatchPlaylist',
    options: {
      body: { providerId },
    },
  });
