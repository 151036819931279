/* External dependencies */
import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { FormEvent, useCallback, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { connect } from 'react-redux';

/* Internal dependencies */
import ExpressCheckout from './ExpressCheckout';
import PaymentForm from './PaymentForm';
import AddressForm from './AddressForm';
import Colors from 'src/colors';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { getEvent } from 'src/store/ducks/events';
import { ApplicationState } from 'src/store';
import { addOrder } from 'src/api/orders';
import User from 'src/types/User';
import Analytics, { AnalyticsEventType } from '../analytics/Analytics';
import { providerToProviderCode } from './Events';
import { DEFAULT_FEES, getApplicationFee, getFinalItemAmount } from 'src/store/helpers/tickets';
import Promotion, { DiscountReward } from 'src/types/Promotion';

type OwnProps = {
  event: any;
  ticket: any;
  quantity: number;
  incrementStepIndex(): void;
  joinCommunity: boolean;
  activePromotion?: Promotion;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = OwnProps & StateProps;

type State = {
  message?: string;
  isProcessing: boolean;
};

const CheckoutForm: React.FC<Props> = ({
  event,
  ticket,
  quantity,
  incrementStepIndex,
  currentUser,
  joinCommunity = true,
  activePromotion,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<State['message']>(undefined);
  const [isProcessing, setIsProcessing] = useState<State['isProcessing']>(false);

  const currentUserId = get(currentUser, 'id');
  const eventName = get(event, 'name');
  const ticketName = get(ticket, 'name');

  // Reward
  const activeReward = get(activePromotion, 'rewards.items[0]');
  const rewardDiscount = get(activeReward, 'payload') as DiscountReward;
  const finalAmount = getFinalItemAmount(ticket, quantity, DEFAULT_FEES, rewardDiscount);

  const applicationFee = getApplicationFee(ticket, quantity, DEFAULT_FEES);

  const handleSubmit = useCallback(async (e: FormEvent) => {
    try {
      e.preventDefault();

      console.log('confirmPayment stripe: ', stripe);
      console.log('confirmPayment elements: ', elements);

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsProcessing(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/completion`,
        },
        redirect: 'if_required',
      });

      console.log('confirmPayment error: ', error);

      if (!error) {
        console.log('currentUserId for paid addOrder...', currentUserId);
        console.log('currentUser for paid addOrder...', currentUser);
        const order = await addOrder({
          orderItems: {
            items: [{
              priceValue: get(ticket, 'priceValue'),
              quantity,
              currency: get(ticket, 'currency'),
              item: ticket,
              itemId: get(ticket, 'id'),
            }],
          },
          tags: {
            items: [
              { itemId: get(event, 'id') },
              { itemId: get(event, 'creatorId') }, // User or Community
            ],
          },
          buyer: pick(currentUser as User, ['id', 'name', 'email', 'phone']),
          userId: get(currentUser, 'id')!,
        }, { joinCommunity });
        const orderId = get(order, 'id');

        console.log('handleSubmit order', order);
        console.log('handleSubmit orderId', orderId);

        try {
          const product = {
            product_id: get(event, 'id'),
            sku: get(ticket, 'id'),
            category: 'event',
            name: get(event, 'name'),
            brand: get(event, 'creator.name'),
            variant: get(ticket, 'name'),
            currency: get(ticket, 'currency'),
            price: get(ticket, 'priceValue'),
            quantity,
            url: `https://www.beatmatch.app/events/${providerToProviderCode[get(event, 'provider')]}/${get(event, 'providerId')}`,
            image_url: get(event, 'images[0].url'),
          };

          Analytics.track(AnalyticsEventType.orderCompleted, {
            order_id: orderId,
            affiliation: 'Beatmatch',
            subtotal: finalAmount,
            revenue: applicationFee,
            products: [product],
          });
        } catch (e) {
          console.log('Error logging order completion: ', e);
        }

        incrementStepIndex && incrementStepIndex();
        return;
      }

      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured.");
      }
    } catch (e) {
      console.log('handleSubmit error: ', e);
    } finally {
      setIsProcessing(false);
    }
  }, [currentUserId, stripe, elements]);

  return (
    <Form
      id="checkout-form"
      className="bg-white p-4"
      onSubmit={handleSubmit}
      style={{ borderRadius: 20 }}
    >
      <div className="mb-2">
        <ExpressCheckout
          event={event}
          ticket={ticket}
          quantity={quantity}
          incrementStepIndex={incrementStepIndex}
          joinCommunity={joinCommunity}
        />
      </div>
      <div className="d-flex align-items-center mb-2 pt-3 pb-3">
        <div style={{ flex: 1, backgroundColor: Colors.secondaryButton, height: 0.15, width: '100%' }} />
        <p className="m-0 p-0 pl-2 pr-2">Or pay with card</p>
        <div style={{ flex: 1, backgroundColor: Colors.secondaryButton, height: 0.15, width: '100%' }} />
      </div>
      <div className="mb-2">
        <PaymentForm
          event={event}
          ticket={ticket}
          quantity={quantity}
        />
      </div>
      {/* <div className="mb-2">
        <AddressForm />
      </div> */}
      <div>
        <Button
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          type="submit"
          variant="primary"
          className="text-bold"
          style={{ width: '100%', borderRadius: 100, height: 55 }}
        >
          {isProcessing ? <Spinner animation="border" size="sm" /> : 'Purchase'}
        </Button>
        {/* Show any error or success messages */}
        {Boolean(message) && (
          // <div id="payment-message" className="mt-2">{message}</div>
          <Alert
            variant="danger"
            className="mt-4"
            style={{ borderRadius: 10 }}
          >
            <Alert.Heading>Payment error</Alert.Heading>
            {message}
          </Alert>
        )}
      </div>
    </Form>
  );
};

const mapStateToProps = (state: ApplicationState, { event }: OwnProps) => {
  const eventId = get(event, 'id');

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, eventId) || event,
  };
};

export default connect(mapStateToProps)(CheckoutForm);