/* External dependencies */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { getEvent } from 'src/store/ducks/events';
import { Event } from 'src/types/Event';
import PostShareButtons from 'src/blog/PostShareButtons';
import SubscribeButton from 'src/subscribe/SubscribeButton';
import './CheckoutComplete.scss';
import Button from 'src/button/Button';
import Order from 'src/types/Order';
import Analytics, { AnalyticsEventType } from 'src/analytics/Analytics';

type OwnProps = {
  event: Event;
  ticket: any;
  quantity: number;
  order?: Order;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = OwnProps & StateProps;

const CheckoutComplete: React.FC<Props> = ({ currentUser, event, quantity = 1, order }) => {
  const currentUserName = get(currentUser, 'name');
  const eventName = get(event, 'name');
  const eventImageUrl = get(event, 'images[0].url');
  const orderId = get(order, 'id', '');

  useEffect(() => {
    Promise.all([
      Analytics.track(AnalyticsEventType.checkoutStepCompleted, { step: 1, checkout_id: orderId }),
      Analytics.track(AnalyticsEventType.checkoutStepViewed, { step: 2 }),
    ]);

    return () => {
      Analytics.track(AnalyticsEventType.checkoutStepCompleted, { step: 2, checkout_id: orderId });
    };
  }, []);

  return (
    <div
      className="container d-flex flex-column justify-content-center align-items-center pb-4"
      data-aos="flip-left"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1200"
    >
      <div className="d-flex flex-column align-items-center mb-5">
        <Image
          className="bm-CheckoutComplete__ticketImage mb-3"
          src={eventImageUrl}
          style={{ borderRadius: 20 }}
        />
        <h1 className="text-center text-white m-0 p-0 mb-1" style={{ fontSize: 65 }}>TICKETS</h1>
        <h1 className="text-center bm-Text--strokeText m-0 p-0" style={{ fontSize: 65 }}>SECURED!</h1>
      </div>
      <div className="d-flex flex-column align-items-center mb-5">
        <h4 className="text-center text-white mb-3">{currentUserName}, you're going to {eventName}!</h4>
        <p className="text-center text-secondary mb-4">Download the Beatmatch app to access your tickets, meet people going, and receive updates.</p>
        <SubscribeButton
          header="Download the app to access your tickets"
          variant="primary"
          render={({ onClick }) => (
            <Button
              className="pl-4 pr-4 pt-2 pb-2 mb-3"
              onClick={onClick as any}
              variant="primary"
              style={{ borderRadius: 100, minWidth: 250 }}
            >
              Get the app
            </Button>
          )}
        />
        {/* <Link
          to={`/tickets${Boolean(orderId) ? `/${orderId.replace('order:', '')}` : ''}`}
          className="btn btn-secondary"
          style={{ borderRadius: 100, minWidth: 250 }}
        >
          {`View ticket${Boolean(quantity > 1) ? 's' : ''}`}
        </Link> */}
      </div>
      <div className="d-flex flex-column align-items-center">
        <h5 className="text-center text-secondary mb-3">Want to brag to your friends? Share this on social media!</h5>
        <PostShareButtons
          text={`I just secured tickets to ${eventName}! Get your ticket on #Beatmatch!`}
          imageUrl={eventImageUrl}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState, { event }: OwnProps) => {
  const eventId = get(event, 'id');

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, eventId) || event,
  };
};

export default connect(mapStateToProps)(CheckoutComplete);