export const abbreviateFromNow = (fromNow: string) => {
  return fromNow
    .replace('years', 'y')
    .replace('year', 'y')
    .replace('months', 'mo')
    .replace('month', 'mo')
    .replace('days', 'd')
    .replace('day', 'd')
    .replace('hours', 'h')
    .replace('hour', 'h')
    .replace('minutes', 'm')
    .replace('minute', 'm')
    .replace('seconds', 's')
    .replace('second', 's')
    .replace('an', '1')
    .replace('a', '1')
    .replace('few', '')
    .replace('ago', '')
    .replaceAll(' ', '');
};