/* Internal dependencies */
import React, { MouseEventHandler, StyleHTMLAttributes, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import get from 'lodash/get';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import { getEvent } from 'src/store/ducks/events';
import { toggleEvent } from 'src/store/stories/events';
import Icon, { Icons } from 'src/icon/Icon';
import Colors from 'src/colors';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import Button from 'src/button/Button';
import AuthModalButton from 'src/authModal/AuthModalButton';
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';
import BuyButton from './BuyButton';

type OwnProps = {
  event: any;
  buyText?: string;
  iconSize?: number;
  showIcon?: boolean;
  render?: (params: { onClick: MouseEventHandler; loading: boolean; added: boolean; }) => React.ReactNode;
  activeBorderColor?: Colors;
  inactiveBorderColor?: Colors;
  activeBackgroundColor?: Colors;
  inactiveBackgroundColor?: Colors;
  activeTextColor?: Colors;
  inactiveTextColor?: Colors;
  inactiveText?: string;
  fontSize?: string;
  width?: number | string;
  style?: Object;
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & DispatchProps;

type State = {
  loading: boolean;
};

const JoinEventButton: React.FC<Props> = ({
  event, showIcon = true,
  iconSize = 22,
  activeBorderColor = Colors.white,
  inactiveBorderColor = Colors.inactiveTab,
  activeBackgroundColor = Colors.transparent,
  inactiveBackgroundColor = Colors.primary,
  inactiveText = 'Join',
  activeTextColor = Colors.white,
  inactiveTextColor = Colors.white,
  fontSize = '1.25rem',
  width,
  style,
  render,
  ...rest
}) => {
  const [loading, setLoading] = useState<State['loading']>(false);

  const handleJoinEvent = async () => {
    try {
      setLoading(true);
      return await toggleEvent(event);
    } finally {
      setLoading(false);
    }
  };

  const tickets = get(event, 'tickets.items', []);
  const hasTickets = Boolean(tickets && tickets.length);
  const added = get(event, 'added', false);

  if (hasTickets) {
    return (
      <BuyButton
        event={event}
        // textSize={fontSize}
        {...rest}
        // render={Boolean(render) ? (onClick) => {
        //   return render!({ onClick });
        // } : undefined}
      />
    );
  }

  return (
    <AuthModalButton
      modalHeader={`Register / Sign in to join ${get(event, 'name', 'this event')}`}
      modalSubheader="Get tickets, meet everyone going, and receive updates for the event."
      onClick={handleJoinEvent}
      render={(onClick) => {

        if (render) {
          return render({ onClick, added, loading });
        }

        return (
          <Button
            disabled={!event}
            className="btn btn-block d-flex justify-content-center align-items-center pl-3 pr-3"
            variant="primary"
            onClick={onClick}
            style={{
              ...style,
              width,
              borderRadius: 100,
              borderColor: Boolean(added) ? activeBorderColor : inactiveBorderColor,
              borderWidth: Boolean(added) ? 2.5 : 0,
              backgroundColor: Boolean(added) ? activeBackgroundColor : inactiveBackgroundColor,
            }}
          >
            <div className="d-flex align-items-center">
              {Boolean(showIcon) && (
                <div className="mr-2 pb-1">
                  {Boolean(added) ? (
                    <Icon name={Icons.check} className="bm-Icon--white" solid size={iconSize} />
                  ) : (
                    <Icon name={Icons.plus} className="bm-Icon--white" size={iconSize} />
                  )}
                </div>
              )}
              <p className="m-0 p-0 text-bold" style={{ fontSize, color: Boolean(added) ? activeTextColor : inactiveTextColor }}>{Boolean(added) ? 'Joined' : inactiveText}</p>
            </div>
          </Button>
        );
      }}
    />
  );
};

const mapStateToProps = (state: ApplicationState, { event }: OwnProps) => {
  const eventId = get(event, 'id');

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, eventId) || event,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinEventButton);