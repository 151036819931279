/* External dependencies */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';

/* Internal dependencies */
import './PostPreview.scss';
import Sanitize from '../sanitize/Sanitize';
import PostShareButtons from './PostShareButtons';

type Props = {
  post: any;
  showShareButtons?: boolean;
};

class PostPreview extends React.Component<Props> {
  render() {
    const { post, showShareButtons = true } = this.props;
    const { title, description = '', date, images, slug, providerId } = post;
    const postImageUrl = get(post, 'images[0].url');
    const momentDate = moment(date);

    return (
      <div className="bm-PostPreview align-items-start mb-5">
        {Boolean(postImageUrl) && (
          <div className="bm-PostPreview_image" style={{ width: '100%', borderRadius: 20, overflow: 'hidden' }}>
            <Link to={`/magazine/${providerId}/${slug}`} className="rounded" style={{ width: '100%' }}>
              <img
                src={postImageUrl}
                width="100%"
                alt={slug}
                style={{
                  objectFit: 'cover',
                  aspectRatio: '1',
                  borderRadius: 20,
                }}
              />
            </Link>
          </div>
        )}
        <div className="bm-PostPreview_text">
          <Link className="text-white" to={`/magazine/${providerId}/${slug}`} style={{ textDecoration: 'none' }}>
            {title ? (
              <h2 className="text-bold mb-2">{title}</h2>
            ) : (
              <h2 className="text-bold mb-2">
                {(description as string)
                  .split(' ')
                  .slice(0, 5)
                  .filter((str) => !str.startsWith('#') && !str.startsWith('http'))
                  .join(' ')}
                ...
              </h2>
            )}
          </Link>
          <p className="bm-Text--secondaryText mt-3 mb-4">Posted {momentDate.fromNow()}</p>
          {Boolean(showShareButtons) && <PostShareButtons url={`https://${process.env.REACT_APP_DOMAIN_NAME}/magazine/${providerId}/${slug}`} />}
        </div>
      </div>
    );
  }
}

export default PostPreview;
