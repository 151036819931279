/* External dependencies */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import shuffle from 'lodash/shuffle';

/* Internal dependencies */
import FlatList from 'src/flatList/FlatList';
import { capitalizeFirstLetter, slugify } from 'src/store/helpers/users';
import Icon, { Icons } from 'src/icon/Icon';
import { TICKET_MASTER_DEFAULT_IMAGES } from 'src/events/Event';
import { providerToProviderCode } from 'src/events/Events';
import { Link } from 'react-router-dom';
import Colors from 'src/colors';
import { Image } from 'react-bootstrap';
import './Collection.scss';
import MediaObject from '../mediaObject/MediaObject';
import JoinEventButton from '../events/JoinEventButton';
import { CurrentUserState, getCurrentUser } from '../store/ducks/currentUser';
import { ApplicationState } from '../store';
import User from '../types/User';
import { formatNumber } from 'src/common/helpers/numbers';
import JoinCommunityButton from 'src/community/JoinCommunityButton';

export enum CollectionType {
  featured = 'featured',
  list = 'list',
  detail = 'detail',
  stories = 'stories',
  chain = 'chain',
  detailItems = 'detailItems',
  inviteFriends = 'inviteFriends',
}

type OwnProps = {
  collection: any;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = OwnProps & StateProps;

const Collection: React.FC<Props> = ({ currentUser, collection }) => {

  const renderEventHorizontal = ({ item }: any) => {
    if (!item) return null;
  
    const { id, name, date, createdAt, images = [], venue, artists, tracks, provider, providerId, timezone } = item;
    const slug = slugify(name);
    const momentDate = moment(date);
    const today = moment();
    const tomorrow = moment().add(1, 'day');
    const isToday = momentDate.isSame(today, 'day');
    const isTomorrow = momentDate.isSame(tomorrow, 'day');
    const oneWeekAgo = moment(Date.now()).subtract(1, 'week').toDate().getTime();
    const artistItems = get(artists, 'items', []);
    const eventImage = get(images, '[0]');
    const defaultEventImageUrl = get(eventImage, 'url', get(artistItems, '[0].images[0].url'));
    const finalEventImageUrl = TICKET_MASTER_DEFAULT_IMAGES.includes(defaultEventImageUrl)
      ? get(item, 'artists.items[0].images[0].url', get(item, 'artists[0].images[0].url', defaultEventImageUrl))
      : defaultEventImageUrl;
    const url = `/events/${providerToProviderCode[provider]}/${providerId}/${slug}`;
    const venueContent = (
      <h6
        className="bm-Collection__horizontalItemSubtext"
        style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
      >
        {(venue as any).name}
      </h6>
    );
  
    return (
      <div className="bm-Collection__event mr-4" style={{ width: `${(100 / 6)}vw` }}>
        <Link
          key={id}
          to={url}
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          {Boolean(finalEventImageUrl) && (
            <Image
              src={finalEventImageUrl}
              className="mb-3"
              style={{
                height: undefined,
                width: '100%',
                aspectRatio: '1',
                borderRadius: 20,
                objectFit: 'cover',
              }}
            />
          )}
          {!Boolean(finalEventImageUrl) && (
            <div
              className="bg-secondaryButton d-flex justify-content-center align-items-center mb-3"
              style={{
                height: undefined,
                width: '100%',
                aspectRatio: '1',
                borderRadius: 20,
                objectFit: 'cover',
              }}
            >
              <Icon name={Icons.ticket} className="bm-Icon--primary" size={125} />
            </div>
          )}
        </Link>
        <div style={{ width: '100%' }}>
          <div className="d-flex mb-1">
            {(isToday || isTomorrow) && (
              <>
                <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                  {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.tz(timezone).format('ddd').toUpperCase()}
                </h6>
                <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()}
                </h6>
              </>
            )}
            {!isToday && !isTomorrow && (
              <>
                <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                  {momentDate.tz(timezone).format('ddd').toUpperCase()}
                </h6>
                <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                  {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()} -{' '}
                  {momentDate.tz(timezone).format('h:mm A')}
                </h6> 
              </>
            )}
          </div>
          <Link
            to={url}
          >
            <h5 className="text-bold text-white mb-1" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</h5>
          </Link>
          {/* {Boolean(venue) && <h5 className="bm-Collection__horizontalItemSubtext" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{(venue as any).name}</h5>} */}
          {Boolean(venue) && (
            <>
              {Boolean(venue.id) ? (
                <Link
                  to={`/venues/${get(venue, 'id', '').replace('venue:', '')}/${slugify(get(venue, 'name', ''))}`}
                  className="bm-Collection__horizontalItemSubtext"
                >
                  {venueContent}
                </Link>
              ) : venueContent}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderNeighborhoodHorizontal = ({ item }: any) => {
    if (!item) return null;
  
    const { id = '', name, images = [], location } = item;
    const slug = slugify(name);
    const events = get(item, 'events.items', []);
    const eventsTotal = get(item, 'events.total', events.length);
    const city = get(item, 'city.name');
    const state = get(item, 'state.code');
    const url = `/neighborhoods/${id.replace('neighborhood:', '')}/${slug}`;
  
    return (
      <div className="bm-Collection__event mr-3" style={{ width: `${(100 / 5.5)}vw` }}>
        <Link
          key={id}
          to={url}
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <Image
            src={get(images, '[0].url')}
            className="mb-3"
            style={{
              height: undefined,
              width: '100%',
              aspectRatio: '1',
              borderRadius: 20,
              objectFit: 'cover',
            }}
          />
        </Link>
        <div style={{ width: '100%' }}>
          {/* <div className="d-flex mb-1">
            {(isToday || isTomorrow) && (
              <>
                <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                  {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.tz(timezone).format('ddd').toUpperCase()}
                </h6>
                <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()}
                </h6>
              </>
            )}
            {!isToday && !isTomorrow && (
              <>
                <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                  {momentDate.tz(timezone).format('ddd').toUpperCase()}
                </h6>
                <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                  {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()} -{' '}
                  {momentDate.tz(timezone).format('h:mm A')}
                </h6> 
              </>
            )}
          </div> */}
          <Link
            to={url}
          >
            <h5 className="text-bold text-white mb-1" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</h5>
          </Link>
          {Boolean(eventsTotal) ? (
            <h5
              className="bm-Collection__horizontalItemSubtext"
              style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {`${eventsTotal} events this week`}
            </h5>
          ) : (
            <h5
              className="bm-Collection__horizontalItemSubtext"
              style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              No events this week
            </h5>
          )}
        </div>
      </div>
    );
  };

  const renderVenueHorizontal = ({ item }: any) => {
    if (!item) return null;
  
    const { id = '', name, images = [], location } = item;
    const events = get(item, 'events.items', []);
    const eventsTotal = get(item, 'events.total', events.length);
    const slug = slugify(name);
    const city = get(item, 'city.name');
    const state = get(item, 'state.code');
    const url = `/venues/${id.replace('venue:', '')}/${slug}`;
    const imageUrl = get(images, '[0].url');
  
    return (
      <div className="bm-Collection__event mr-3" style={{ width: `${(100 / 6)}vw` }}>
        <Link
          key={id}
          to={url}
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          {Boolean(imageUrl) && (
            <Image
              src={imageUrl}
              className="mb-3"
              style={{
                height: undefined,
                width: '100%',
                aspectRatio: '1',
                borderRadius: 20,
                objectFit: 'cover',
              }}
            />
          )}
          {!Boolean(imageUrl) && (
            <div
              className="d-flex justify-content-center align-items-center bg-secondaryButton mb-3"
              style={{
                height: undefined,
                width: '100%',
                aspectRatio: '1',
                borderRadius: 20,
              }}
            >
              <Icon
                name={Icons.glassCocktail}
                className="bm-Icon--primary"
                size={75}
              />
            </div>
          )}
        </Link>
        <div style={{ width: '100%' }}>
          {/* <div className="d-flex mb-1">
            {(isToday || isTomorrow) && (
              <>
                <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                  {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.tz(timezone).format('ddd').toUpperCase()}
                </h6>
                <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()}
                </h6>
              </>
            )}
            {!isToday && !isTomorrow && (
              <>
                <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                  {momentDate.tz(timezone).format('ddd').toUpperCase()}
                </h6>
                <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                  {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()} -{' '}
                  {momentDate.tz(timezone).format('h:mm A')}
                </h6> 
              </>
            )}
          </div> */}
          <Link
            to={url}
          >
            <h5 className="text-bold text-white mb-1" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</h5>
          </Link>
          {Boolean(eventsTotal) ? (
            <h5
              className="bm-Collection__horizontalItemSubtext"
              style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {`${eventsTotal} events this week`}
            </h5>
          ) : (
            <h5
              className="bm-Collection__horizontalItemSubtext"
              style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              No events this week
            </h5>
          )}
          {/* {Boolean(location && city && state) && <h5 className="bm-Collection__horizontalItemSubtext" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{`${city}, ${state}`}</h5>} */}
        </div>
      </div>
    );
  };

  const renderUserHorizontal = (args: any) => null;

  const renderActivityHorizontal = (args: any) => null;

  const renderArtistHorizontal = (args: any) => null;

  const renderCommunityHorizontal = ({ item: community }: any) => {
    if (!community) return null;
  
    const { id, name } = community;
    const verified = get(community, 'verified', false);
    const genreItems = shuffle(get(community, 'genres.items', []));
    const members = get(community, 'members.items', []);
    const totalMembers = get(community, 'members.total', members.length);
    const url = `/communities/${id.replace('community:', '')}`;
  
    return (
      <div className="bm-Collection__community mr-4" style={{ width: `${(100 / 4)}vw` }}>
        <Link
          key={id}
          to={url}
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
            aspectRatio: '4/2',
            overflow: 'hidden',
          }}
        >
          <Image
            src={get(community, 'images[0].url')}
            className="mb-3"
            style={{
              height: '100%',
              width: '100%',
              aspectRatio: '4/2',
              borderRadius: 20,
              objectFit: 'cover',
            }}
          />
        </Link>
        <div style={{ width: '100%' }}>
          <Link className="d-flex align-items-center mb-1" to={url}>
            <h5 className="text-bold text-white m-0 p-0" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</h5>
            {Boolean(verified) && (
              <Icon
                name={Icons.verified}
                className="bm-Icon--primary ml-1"
                size={16}
                solid
              />
            )}
          </Link>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="d-flex align-items-center">
                {/* <div className="d-flex align-items-center">
                  <p className="text-secondary mr-1 m-0 p-0">{formatNumber(totalMembers)}</p>
                  <Icon name={Icons.users} className="bm-Icon--white" size={15} />
                </div>
                <div className="ml-3 mr-3" style={{ width: 4, aspectRatio: '1', borderRadius: 2, backgroundColor: Colors.secondaryText }} /> */}
                {Boolean(genreItems) && <h6 className="bm-Collection__horizontalItemSubtext m-0 p-0">{genreItems.slice(0,3).map(({ name }) => capitalizeFirstLetter(name)).join(', ')}</h6>}
              </div>
            </div>
            {/* <JoinCommunityButton community={community} showIcon={false} /> */}
          </div>
        </div>
      </div>
    );
  };

  const renderListItem = (args: any) => {
    const { item } = args;
    const { type } = item;

    if (type === 'event') {
      return renderEventHorizontal(args);
    } else if (type === 'user') {
      return renderUserHorizontal(args);
    } else if (type === 'activity') {
      return renderActivityHorizontal(args);
    } else if (type === 'artist') {
      return renderArtistHorizontal(args);
    } else if (type === 'community') {
      return renderCommunityHorizontal(args);
    } else if (type === 'neighborhood') {
      return renderNeighborhoodHorizontal(args);
    } else if (type === 'venue') {
      return renderVenueHorizontal(args);
    }

    return null;
  };

  const renderListCollection = ({ items, title, description }: any) => {
    if (!items.length) return null;

    return (
      <div className="bm-Collection__listCollection">
        <div className="bm-Collection__header mb-4">
          <h3 className="bm-Collection__heading text-bold text-white" style={{ marginBottom: Boolean(description) ? 4 : 0 }}>
            {title}
          </h3>
          {Boolean(description) && (
            <h5 className="bm-Collection__subheading" style={{ color: Colors.inactiveTab }}>
              {description}
            </h5>
          )}
        </div>
        <div style={{ marginLeft: -16, marginRight: -16 }}>
          <FlatList
            data={items}
            horizontal={true}
            renderItem={renderListItem}
            onEndReachedThreshold={0.7}
            contentContainerStyle={{ paddingLeft: 16 }}
          />
        </div>
      </div>
    );
  };

  const renderChainCollection = ({ items, title, description }: any) => {
    if (!items.length) return null;

    return (
      <div>
        <div className="mb-4">
          <h3 className="text-bold text-white">
            {title}
          </h3>
          {Boolean(description) && (
            <h5 className="mt-1" style={{ color: Colors.lightGrey }}>
              {description}
            </h5>
          )}
        </div>
        <div style={{ marginLeft: -16, marginRight: -16 }}>
          <FlatList
            data={items}
            horizontal={true}
            renderItem={({ item }) => {
              const { id, name, type, date, images = [], added, provider, providerId } = item;
              const momentDate = moment(date);
              const today = moment();
              const tomorrow = moment().add(1, 'day');
              const isToday = momentDate.isSame(today, 'day');
              const isTomorrow = momentDate.isSame(tomorrow, 'day');
              const users = get(item, 'users.items', []);
              let usersTotal = get(item, 'users.total', users.length);
              let displayedUsers = users.filter((user: any) => Boolean(user));

              if (added) {
                displayedUsers = uniqBy([
                  currentUser,
                  ...users,
                ], 'id').filter(user => Boolean(user));
              }

              if (displayedUsers.length && usersTotal === 0) {
                usersTotal = displayedUsers.length;
              }

              return (
                <div
                  className="btn d-flex mr-4"
                  style={{
                    width: 300,
                    maxHeight: 'none',
                    maxWidth: 'none',
                    aspectRatio: '1.7',
                    borderRadius: 20,
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    className="bg-overlay d-flex flex-column justify-content-between pl-4 pr-4 pt-3 pb-3"
                    style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <div className="pt-2 mb-3">
                      <MediaObject
                        text={(
                          <Link
                            to={type === 'event' ? `/events/${providerToProviderCode[provider]}/${providerId}` : `/plans/${id.replace('activity:', '')}`}
                          >
                            <h6 className="text-bold text-white">{name}</h6>
                          </Link>
                        )}
                        textColor="light"
                        subtext={(
                          <div className="d-flex mb-1">
                            <h6 className="text-bold text-primary mr-2">
                              {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.format('ddd').toUpperCase()}
                            </h6>
                            <h6 className="text-light text-white">
                              {isToday || isTomorrow ? `${momentDate.format('h:mm A')}` : `${momentDate.format('MMM').toUpperCase()} ${momentDate.format('D').toUpperCase()}`}
                            </h6>
                          </div>
                        )}
                        image={get(images, '[0].url')}
                        imageType="rounded"
                        imageSize={55}
                        wrap={false}
                      />
                    </div>
                    <div
                      className="d-flex justify-content-between align-items-center mr-2"
                    >
                      <div
                        className="d-flex align-items-center mr-2"
                      >
                        {displayedUsers.filter(({ images = [] }) => Boolean(images && images.length)).slice(0,3).map(({ images = [] }: User, i: number) => (
                          <Image
                            src={get(images, '[0].url')}
                            style={{
                              zIndex: (displayedUsers.length * 2) - i, // Multiplying by 2 here to ensure the image sits on top of the plus icon circle
                              height: undefined,
                              width: 34,
                              aspectRatio: '1',
                              borderRadius: 17,
                              marginLeft: i === 0 ? 0 : -8,
                              borderWidth: 2,
                              borderColor: Colors.secondaryButton,
                              objectFit: 'cover',
                            }}
                          />
                        ))}
                        {Boolean(usersTotal > 3) && (
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{
                              height: undefined,
                              width: 34,
                              aspectRatio: '1',
                              borderRadius: 17,
                              backgroundColor: 'rgba(0,0,0,0.75)',
                              borderWidth: 2,
                              borderColor: Colors.secondaryButton,
                              marginLeft: -8,
                            }}
                          >
                            <h6
                              className="text-bold text-white ml-1 m-0 p-0"
                              style={{ fontSize: 9 }}
                            >
                              {`+${(usersTotal || users.length) - 3}`}
                            </h6>
                          </div>
                        )}
                        {Boolean(usersTotal > 0 && usersTotal < 4) && (
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{
                              height: undefined,
                              width: 34,
                              aspectRatio: '1',
                              borderRadius: 17,
                              backgroundColor: 'rgba(0,0,0,0.75)',
                              borderWidth: 2,
                              borderColor: Colors.secondaryButton,
                              marginLeft: -6,
                            }}
                          >
                            <Icon name={Icons.plus} className="bm-Icon--white" size={16} />
                          </div>
                        )}
                      </div>
                      {Boolean(type === 'event') && (
                        <div>
                          <JoinEventButton
                            event={item}
                            fontSize="0.95rem"
                            width={75}
                            showIcon={false}
                            inactiveBackgroundColor={Colors.white}
                            inactiveTextColor={Colors.secondaryButton}
                            buyText="Buy"
                          />
                        </div>
                      )}
                      {/* {Boolean(type === 'activity') && (
                        <div>
                          <JoinActivityButton
                            event={item}
                            textSize="p"
                            showIcon={false}
                            paddingVertical={verticalScale(6)}
                          />
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              );
            }}
            onEndReachedThreshold={0.7}
            contentContainerStyle={{ paddingLeft: 16 }}
          />
        </div>
      </div>
    );
  };

  if (!collection) {
    return null;
  }

  const { id, type, items = [] } = collection;

  if (!items || !items.length) return null;

  let content;
    
  if (type === CollectionType.list) {
    content = renderListCollection(collection);
  } else if (type === CollectionType.chain) {
    content = renderChainCollection(collection);
  } else {
    return null;
  }

  return (
    <div className="bm-Collection">
      {content}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps)(Collection);