/* External dependencies */
import React from 'react';
import { Map as MapView, APIProvider, MapProps } from '@vis.gl/react-google-maps';


const Map: React.FC<MapProps> = (props) => {

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}>
      <MapView {...props} />
    </APIProvider>
  );
};

export default Map;