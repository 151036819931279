/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions } from 'src/types/Pagination';
import API from '.';

export const listPosts = async (options?: PaginationOptions & { tags?: string[] }) => {
  const limit = get(options, 'limit', 20);
  const offset = get(options, 'offset', 0);
  const tags = get(options, 'tags', []).filter((tag) => Boolean(tag));

  return await API.get({
    path: `/posts`,
    options: {
      queryParams: cleanObject({
        limit,
        offset,
        tags: Boolean(tags && tags.length) ? tags.join(',') : undefined,
      }),
    },
  });
};

export const getPost = async (id: string) =>
  await API.get({
    path: `/posts/${id}`,
  });
