/* External dependencies */
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import get from 'lodash/get';
import moment from 'moment';
import numbro from 'numbro';
// import { LinearGradient } from 'expo-linear-gradient';
// import ViewMoreText from 'react-native-view-more-text';

/* Internal dependencies */
import { addLikeForPost, toggleLikeForPost, addLikeForComment, toggleLikeForComment } from 'src/store/stories/likes';
import { fetchAll } from 'src/common/helpers/fetchAll';
import DoubleTap from 'src/doubleTap/DoubleTap';
import MediaObject from '../mediaObject/MediaObject';
// import { Screens } from '../../screens';
import Icon, { Icons } from '../icon/Icon';
// import BottomDrawerButton from '../bottomDrawer/BottomDrawerButton';
// import FollowArtistButton from '../artist/FollowArtistButton';
// import UserProfileImage from '../../viewProfile/UserProfileImage';
// import ItemMoreButton from '../moreButton/ItemMoreButton';
import Track from '../track/Track';
import User from 'src/types/User';
// import JoinActivityButton from 'src/activity/JoinActivityButton';
import { ApplicationState } from 'src/store';
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';
import Button from '../button/Button';
// import SendItemButton from '../item/SendItemButton';
// import FeedPostCommentInput from './FeedPostCommentInput';
import { getPost } from 'src/store/ducks/posts';
// import FeedPostCommentFeed from './FeedPostCommentFeed';
// import Poll from '../poll/Poll';
import { abbreviateFromNow } from 'src/store/helpers/time';
import Event from 'src/event/Event';
import { Image } from 'react-bootstrap';
import { withRouter } from 'react-router';
import FlatList from 'src/flatList/FlatList';
import Colors from 'src/colors';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { capitalizeFirstLetter } from 'src/store/helpers/users';
import { Link } from 'react-router-dom';
import { providerToProviderCode } from 'src/events/Events';
import Gif from 'src/gif/Gif';
import JoinEventButton from 'src/events/JoinEventButton';
import SubscribeButton from 'src/subscribe/SubscribeButton';

type InjectedProps = any & {
  history: any;
};

type OwnProps = {
  post: any;
  variant?: 'primary' | 'secondary';
  friendsOnly?: boolean;
  showActions?: boolean;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = InjectedProps & OwnProps & StateProps;

type State = {
  creatorsDrawerOpen: boolean;
  commentsDrawerOpen: boolean;
  selectedThread: any;
  replyingTo: any;
  replying: boolean;
};

class FeedPost extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { post } = props;

    this.state = {
      creatorsDrawerOpen: false,
      commentsDrawerOpen: false,
      selectedThread: post,
      replyingTo: undefined,
      replying: false,
    };
  }

  createHandleToggleLikePost = (post: any) => async () => {
    const promises = [
      toggleLikeForPost(post),
    ];

    await fetchAll(promises);
  };

  createHandleToggleLikeComment = (comment: any) => async () => {
    const { post } = this.props;
    const promises = [
      toggleLikeForComment(post, comment),
    ];

    await Promise.all(promises);
  };

  createHandleLikePost = (post: any) => async () => {
    const promises = [
      addLikeForPost(post),
    ];

    await fetchAll(promises);
  };

  createHandleLikeComment = (comment: any) => async () => {
    const { post } = this.props;
    const promises = [
      addLikeForComment(post, comment),
    ];

    await Promise.all(promises);
  };

  openCreatorsDrawer = () => { this.setState({ creatorsDrawerOpen: true }); };

  closeCreatorsDrawer = () => { this.setState({ creatorsDrawerOpen: false }); };

  openCommentsDrawer = () => { this.setState({ commentsDrawerOpen: true }); };

  closeCommentsDrawer = () => { this.setState({ commentsDrawerOpen: false }); };

  renderActivity = ({ post, item }: any, fromContacts = false) => {
    if (!post || !item) return null;

    const { currentUser } = this.props;
    const activity = item;
    const { id, name, images = [], location, participantStatus } = activity;
    const event = get(activity, 'events.items[0]');
    const date = get(event, 'date');
    const locationName = get(location, 'name');
    const imageUrl = get(images, '[0].url');
    const participants = get(activity, 'participants.items', []);
    const participantsLength = get(activity, 'participants.total', participants.length);
    const requests = get(activity, 'requests.items', []);
    const requestsLength = get(activity, 'requests.total', requests.length);
    const tracks = get(activity, 'tracks.items', get(event, 'tracks.items', []));
    const momentDate = moment(date);
    const totalUsers = [...participants, ...requests];
    const totalUsersLength = participantsLength + requestsLength;
    const isConfirmed = participantStatus === 'confirmed';
    const city = get(location, 'city', get(event, 'venue.city.name'));
    const state = get(location, 'state', get(event, 'venue.state.code'));

    return (
      <DoubleTap
        onDoubleTap={this.createHandleLikePost(post)}
        onSingleTap={() => {
          // navigation.navigate(Screens.Activity, { activityId: id });
        }}
      >
        <div
          key={id}
          style={{
            aspectRatio: '3/3.5', width: '100%',
            overflow: 'hidden', borderRadius: 20,
            // shadowOpacity: 0.7,
            // shadowRadius: 5,
            // shadowColor: Colors.mediumGrey,
            // shadowOffset: { height: 4, width: 4 },
            // elevation: 3,
          }}
        >
          <Image
            src={imageUrl}
            style={{
              height: '100%', width: '100%',
              // shadowOpacity: 0.7,
              // shadowRadius: 5,
              // shadowColor: Colors.mediumGrey,
              // shadowOffset: { height: 4, width: 4 },
              // elevation: 3,
            }}
          />
          <div
            style={{
              position: 'absolute', top: 0, left: 0,
              height: '100%', width: '100%',
              justifyContent: 'center',
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 24,
              paddingBottom: 24,
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0,0,0,0.75), rgba(30,30,30,0.95), rgba(30,30,30,1))`,
            }} 
          >
            <div style={{ alignItems: 'flex-end', width: '100%' }}>
              {Boolean(fromContacts) && (
                <div
                  style={{
                    alignItems: 'center', borderRadius: 5,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 4,
                    paddingBottom: 4,
                    backgroundColor: 'rgba(255,255,255,0.9)', maxWidth: '45%',
                    marginBottom: 4,
                  }}
                >
                  <h6 className="text-bold text-dark">From your contacts</h6>
                </div>
              )}
            </div>
            <div style={{ flex: 1, justifyContent: 'flex-end' }}>
              <div style={{ flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                  {Boolean(event) && (
                    <MediaObject
                      text={name}
                      subtext={!isConfirmed ? `${city || ''}${state ? `, ${state}` : ''}` : locationName}
                      textSize="1.25rem"
                      subtextSize="1rem"
                      imageSize={55}
                      image={(
                        <div
                          style={{
                            borderRadius: 6,
                            overflow: 'hidden',
                            width: 60,
                            aspectRatio: '1',
                          }}
                        >
                          <div style={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 8, paddingRight: 8, backgroundColor: Colors.primary }}>
                            <h6 className="text-bold text-white" style={{ textAlign: 'center' }}>
                              {momentDate.format('MMM').toUpperCase()}
                            </h6>
                          </div>
                          <div
                            style={{
                              backgroundColor: Colors.background,
                              height: '100%',
                              width: '100%',
                            }}
                          >
                            <h4 className="text-bold text-white" style={{ paddingTop: 4, textAlign: 'center' }}>
                              {momentDate.format('D').toUpperCase()}
                            </h4>
                          </div>
                        </div>
                      )}
                    />
                  )}
                  <div style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16 }}>
                    {Boolean(totalUsers && totalUsers.length) ? (
                      <div style={{ flexDirection: 'row', alignItems: 'center', marginRight: 32 }}>
                        {totalUsers.slice(0,3).map(({ images }: User, i: number) => (
                          <Image
                            src={get(images, '[0].url', 'https://i.stack.imgur.com/l60Hf.png')}
                            style={{
                              zIndex: totalUsers.length - i,
                              height: undefined,
                              width: 40,
                              aspectRatio: '1',
                              borderRadius: 20,
                              marginLeft: i === 0 ? 0 : -16,
                              marginRight: i !== totalUsers.slice(0,3).length - 1 ? 0 : 8,
                              borderWidth: 3,
                              borderColor: Colors.secondaryButton,
                            }}
                          />
                        ))}
                        <p className="text-white">{`${numbro(totalUsersLength).format({ thousandSeparated: true })} joined`}</p>
                      </div>
                    ) : (
                      <div style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                        <Image
                          src={get(currentUser, 'images[0].url', 'https://i.stack.imgur.com/l60Hf.png')}
                          style={{
                            zIndex: 100,
                            height: undefined,
                            width: 40,
                            aspectRatio: '1',
                            borderRadius: 20,
                            borderWidth: 3,
                            borderColor: Colors.secondaryButton,
                          }}
                        />
                        <div
                          style={{
                            height: undefined,
                            width: 40,
                            aspectRatio: '1',
                            borderRadius: 20,
                            backgroundColor: 'rgba(0,0,0,0.75)',
                            borderWidth: 3,
                            borderColor: Colors.secondaryButton,
                            marginLeft: -12,
                            marginRight: 8,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Icon name={Icons.plus} size={20} />
                        </div>
                        <p className="text-white">Be the first to join</p>
                      </div>
                    )}
                    <div style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                      {/* <JoinActivityButton activity={activity} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DoubleTap>
    );
  };

  renderEvent = ({ post, item }: any, fromContacts = false) => {
    if (!post || !item) return null;

    const { history } = this.props;
    const event = item;
    const { provider, providerId } = event;

    return (
      <DoubleTap
        onDoubleTap={this.createHandleLikePost(post)}
        onSingleTap={() => {
          history.push(`/events/${providerToProviderCode[provider]}/${providerId}`);
        }}
        style={{ margin: 0, padding: 0, width: '100%' }}
      >
        <Event
          event={event}
          imageSize={150}
          right={(
            <JoinEventButton
              event={event}
              fontSize="1rem"
              width={90}
              showIcon={false}
              inactiveBackgroundColor={Colors.white}
              inactiveTextColor={Colors.background}
            />
          )}
          // horizontal={true}
          // featured={true}
          // showAddButton={true}
          // showDate={true}
          // showTime={true}
          // showVenue={true}
        />
      </DoubleTap>
    );
  };

  renderImage = ({ post, item }: any, fromContacts = false) => {
    if (!post || !item) return null;

    return (
      <div
        style={{
          borderRadius: 20,
          aspectRatio: '4/5',
          width: '100%',
        }}
      >
        <Image
          src={get(item, 'url')}
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
        />
      </div>
    );
  };

  renderTrack = ({ post, item }: any, fromContacts = false) => {
    if (!post || !item) return null;

    return (
      <Track
        track={item}
        // showPlayButton={true}
        // showMoreButton={false}
        // showShareButton={false}
        // showSaveButton={true}
        // featured={true}
        // showShadow={false}
        // shadow={false}
        // preload={true}
      />
    );
  };

  renderGif = ({ post, item }: any, fromContacts = false) => {
    if (!post || !item) return null;

    return (
      <Gif gif={item}  />
    );
  };

  // renderPoll = ({ post, item }: any, fromContacts = false) => {
  //   if (!post || !item) return null;

  //   return (
  //     <Poll poll={item}  />
  //   );
  // };

  handleResetSelectedThread = () => {
    const { post } = this.props;

    this.setState({ selectedThread: post });
  };

  handleUpdateSelectedThread = (selectedThread: any) => {
    this.setState({ selectedThread });
  };

  handleReplyingTo = (replyingTo: any) => {
    this.setState({ replyingTo });
  };

  handleReply = (replying: boolean) => {
    this.setState({ replying });
  };

  render() {
    const {
      currentUser,
      post,
      // variant = 'primary',
      friendsOnly = false,
      showActions = true,
      history,
    } = this.props;

    if (!post) return null;

    const { creatorsDrawerOpen, commentsDrawerOpen, selectedThread, replyingTo, replying } = this.state;
    const { id: postId, items: attachments = {}, description, feed, createdAt, isThreadCreator } = post;
    const creators = get(post, 'creators.items', []);
    const creatorsTotal = get(post, 'creators.total', creators.length);
    const [creator] = creators;
    const creatorName = get(creator, 'name');
    const { items = [] } = attachments;
    const [item] = items;
    const createdAtMomentDate = moment(createdAt);
    const likes = get(post, 'likes.items', []);
    const likesTotal = get(post, 'likes.total', likes.length);
    const comments = get(post, 'comments.items', []);
    const commentsTotal = get(post, 'comments.total', comments.length);
    const shares = get(post, 'shares.items', []);
    const sharesTotal = get(post, 'shares.total', shares.length);
    const likedAlready = get(post, 'liked', false);
    const sharedAlready = get(post, 'shared', false);
    const location = get(post, 'location', get(currentUser, 'location'));
    let actionSheetButtons: any[] = [];
    let city = get(location, 'city');
    let state = get(location, 'state');

    return (
      <>
        <DoubleTap
          onDoubleTap={this.createHandleLikePost(post)}
          onSingleTap={() => {
            // navigation.navigate(Screens.Post, { postId })
          }}
          style={{ width: '100%' }}
        >
          <div className="bg-overlay pt-4 pb-4" style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem', borderRadius: 20, width: '100%' }}>
            <div style={{ marginBottom: 16, width: '100%' }}>
              {Boolean(creators.length) && (
                <MediaObject
                  wrap={false}
                  text={(
                    <div className="d-flex align-items-center" style={{ maxWidth: '90%' }}>
                      {/* <NavigationButton
                        screen={creator.type === 'artist' ? Screens.Artist : Screens.divUser}
                        screenProps={creator.type === 'artist' ? { artistId: creator.id } : { user: creator, isPreview: false }}
                        render={(onClick) => ( */}
                          <button
                            // key={index}
                            // activeOpacity={0.9}
                            // onClick={onClick}
                            className="btn m-0 p-0 d-flex align-items-center mr-1"
                          >
                            <h5 className="m-0 p-0 text-white text-bold">{capitalizeFirstLetter(creatorName)}</h5>
                            {Boolean((creator.verified || creator.type === 'artist') && creators.length !== 2) && (
                              <Icon
                                className="bm-Icon--primary"
                                name={Icons.verified}
                                size={16}
                                solid
                                style={{ marginLeft: 4, marginRight: 2 }}
                              />
                            )}
                            {Boolean(isThreadCreator && creators.length !== 2) && (
                              <div
                                className="bg-primary d-flex justify-content-center align-items-center ml-1 pl-2 pr-2 pt-1 pb-1"
                                style={{ borderRadius: 5 }}
                              >
                                <h6 className="text-white text-bold m-0 p-0" style={{ fontSize: 12 }}>{Boolean(feed.type === 'event') ? 'Host' : 'Team'}</h6>
                              </div>
                            )}
                          </button>
                        {/* )}
                      /> */}
                      {Boolean(creators.length > 1) && <h5 className="m-0 p-0 text-white">and </h5>}
                      {Boolean(creators.length === 2) && (
                        // <NavigationButton
                        //   screen={creator.type === 'artist' ? Screens.Artist : Screens.divUser}
                        //   screenProps={get(creators, '[1].type') === 'artist' ? { artistId: get(creators, '[1].id') } : { user: get(creators, '[1]'), isPreview: false }}
                        //   render={(onClick) => (
                            <Link
                              // key={index}
                              // activeOpacity={0.9}
                              // onClick={onClick}
                              to="#"
                              className="d-flex align-items-center mr-1"
                            >
                              <h5 className="m-0 p-0 text-bold text-white">{capitalizeFirstLetter(get(creators, '[1].name', ''))}</h5>
                            </Link>
                        //   )}
                        // />
                      )}
                      {Boolean(creators.length > 2 && creatorsTotal > 2) && (
                        // <BottomDrawerButton
                        //   open={creatorsDrawerOpen}
                        //   openDrawer={this.openCreatorsDrawer}
                        //   closeDrawer={this.closeCreatorsDrawer}
                        //   snapPoints={['50%', '50%']}
                        //   content={(
                        //     <div>
                        //       <AppText type="bold" size="h3" style={{ textAlign: 'center', marginBottom: 32 }}>Creators</AppText>
                        //       <FlatList
                        //         data={creators}
                        //         keyboardShouldPersistTaps="handled"
                        //         contentContainerStyle={{ minHeight: creators.length * 110 }}
                        //         renderItem={({ item: creator, index }) => {
                        //           return (
                        //             // <NavigationButton
                        //             //   screen={get(creator, 'type') === 'artist' ? Screens.Artist : Screens.divUser}
                        //             //   screenProps={get(creator, 'type') === 'artist' ? {
                        //             //       artistId: get(creator, 'id')
                        //             //     } : {
                        //             //       user: creator,
                        //             //       isPreview: true
                        //             //     }
                        //             //   }
                        //             //   render={(onClick) => (
                        //                 <button
                                            // className="btn"
                        //                   // activeOpacity={0.9}
                        //                   // onClick={() => {
                        //                   //   onClick();
                        //                   //   this.closeCreatorsDrawer();
                        //                   // }}
                        //                   style={{
                        //                     marginBottom: index !== creators.length - 1 ? 24 : 0
                        //                   }}
                        //                 >
                        //                   <MediaObject
                        //                     text={get(creator, 'name')}
                        //                     textSize="h4"
                        //                     subtext={get(creator, 'type') === 'artist' ? 'Artist' : getUserSubtext(creator, currentUser)}
                        //                     subtextSize="h5"
                        //                     image={get(creator, 'images[0].url')}
                        //                     // imageSize="15%"
                        //                     imageType="circle"
                        //                     noPadding={true}
                        //                     right={get(creator, 'type') === 'artist' ? <FollowArtistButton artist={creator} /> : undefined}
                        //                   />
                        //                 </button>
                        //             //   )}
                        //             // />
                        //           );
                        //         }}
                        //       />
                        //     </div>
                        //   )}
                        //   render={(onClick) => (
                            <button
                              // activeOpacity={0.9}
                              // onClick={onClick}
                              className="btn m-0 p-0"
                            >
                              <h5 className="m-0 p-0 text-bold">{numbro(creatorsTotal - 1).format({ thousandSeparated: true })} others</h5>
                            </button>
                        //   )}
                        // />
                      )}
                      {Boolean(createdAtMomentDate) && (
                        <>
                          <p className="m-0 p-0 text-secondary" style={{ marginLeft: 4 }}>
                            {abbreviateFromNow(createdAtMomentDate.fromNow(true))}
                          </p>
                          {/* <div style={{ width: 3, aspectRatio: '1', borderRadius: 1.5, backgroundColor: Colors.secondaryIcon, marginRight: 8 }} /> */}
                        </>
                      )}
                    </div>
                  )}
                  subtext={(
                    <div style={{ flexDirection: 'row', alignItems: 'center', maxWidth: '100%', paddingTop: 2 }}>
                      {Boolean(feed && feed.name) && (
                        // <NavigationButton
                        //   screen={feed.type === 'event' ? Screens.Event : Screens.Community}
                        //   screenProps={feed.type === 'event' ? { eventId: feed.id } : { communityId: feed.id }}
                        //   render={(onClick) => (
                            <Link
                              // activeOpacity={0.9}
                              // onClick={onClick}
                              to={Boolean(feed.type === 'event') ? `/events/${providerToProviderCode[get(feed, 'provider')]}/${get(feed, 'providerId')}` : `/communities/${get(feed, 'id', '').replace('community:', '')}`}
                              className="d-flex align-items-center"
                              style={{ flex: 1 }}
                            >
                              <Icon name={feed.type === 'event' ? Icons.ticket : Icons.users} className="bm-Icon--white mr-1" solid size={14} />
                              <p className="m-0 p-0 text-bold text-secondary">{feed.name}</p>
                            </Link>
                        //   )}
                        // />
                      )}
                      {Boolean(!feed && city && state) && (
                        <div className="d-flex align-items-center" style={{ flex: 1 }}>
                          <Icon name={Icons.pin} className="bm-Icon--white mr-1" solid size={12}/>
                          <p className="m-0 p-0 text-secondary">{city}, {state}</p>
                        </div>
                      )}
                    </div>
                  )}
                  textSize="h5"
                  imageSize={40}
                  image={creators.length === 1 ? (
                    <Image
                      src={get(creator, 'images[0].url')}
                      height={45}
                      width={45}
                      // showAddFriend={true}
                      style={{ borderRadius: 22.5, objectFit: 'cover' }}
                    />
                  ) : (
                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', aspectRatio: '1', width: 45 }}>
                      <div style={{ justifyContent: 'flex-end', height: '100%', zIndex: 100 }}>
                        <Image
                          src={get(creators, '[0].images[0].url')}
                          style={{ width: 30, aspectRatio: '1', borderWidth: 2, borderColor: Colors.background, borderRadius: 15 }}
                        />
                      </div>
                      <div style={{ justifyContent: 'flex-start',  height: '100%', marginLeft: -16 }}>
                        <Image
                          src={get(creators, '[1].images[0].url')}
                          style={{ width: 30, aspectRatio: '1', borderWidth: 2, borderColor: Colors.background, borderRadius: 15 }}
                        />
                      </div>
                    </div>
                  )}
                  // right={(
                  //   <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                  //     <div style={{ marginRight: -horizontalScale(12) }}>
                  //       <ItemMoreButton
                  //         item={post}
                  //         deleteIndex={creator && currentUser.id === creator.id ? actionSheetButtons.length - 1 : undefined}
                  //         actionSheetButtons={actionSheetButtons}
                  //         previousScreen={Screens.Home}
                  //       />
                  //     </div>
                  //   </div>
                  // )}
                />
              )}
              {Boolean(description) && (
                <div className="text-left text-wrap mt-4 mb-4">
                  {/* <ViewMoreText
                    numberOfLines={5}
                    renderdivMore={(onClick) => (
                      <button
                        onClick={onClick}
                        activeOpacity={0.9}
                        className="btn"
                      >
                        <AppText
                          size="p"
                          type="bold"
                          color={Colors.inactiveTab}
                          style={{ marginTop: 8 }}
                        >
                          See more
                        </AppText>
                      </button>
                    )}
                    renderdivLess={(onClick) => (
                      <button
                        activeOpacity={0.9}
                        onClick={onClick}
                      >
                        <AppText
                          size="p"
                          type="bold"
                          color={Colors.inactiveTab}
                          style={{ marginTop: 8 }}
                        >
                          See less
                        </AppText>
                      </button>
                    )}
                  > */}
                    <p className="m-0 p-0 text-secondary">
                      {description}
                    </p>
                  {/* </ViewMoreText> */}
                </div>
              )}
            </div>
            {Boolean(item) && (
              <div className="mb-4">
                <div>
                  {Boolean(item.type === 'activity') && this.renderActivity({ post, item }, friendsOnly)}
                  {Boolean(item.type === 'event') && this.renderEvent({ post, item }, friendsOnly)}
                  {Boolean(item.type === 'image') && this.renderImage({ post, item }, friendsOnly)}
                  {Boolean(item.type === 'track') && this.renderTrack({ post, item }, friendsOnly)}
                  {Boolean(item.type === 'gif') && this.renderGif({ post, item }, friendsOnly)}
                  {/* {Boolean(item.type === 'poll') && this.renderPoll({ post, item }, friendsOnly)} */}
                </div>
              </div>
            )}
            {Boolean(showActions) && (
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center mr-4">
                  <button
                    type="button"
                    className="btn m-0 p-0"
                    onClick={this.createHandleToggleLikePost(post)}
                    style={{ minWidth: 40 }}
                  >
                    <Icon name={Icons.heart} size={32} solid={likedAlready} className={likedAlready ? 'bm-Icon--primary' : 'bm-Icon--white'} />
                  </button>
                  {Boolean(likesTotal) && (
                    <p
                      className="m-0 p-0 text-white ml-1"
                    >
                      {likesTotal}
                    </p>
                  )}
                </div>
                {/* <BottomDrawerButton
                  open={commentsDrawerOpen}
                  openDrawer={this.openCommentsDrawer}
                  closeDrawer={this.closeCommentsDrawer}
                  snapPoints={['75%', '94%']}
                  content={(
                    <FeedPostCommentFeed
                      postId={postId}
                      onUpdateSelectedThread={this.handleUpdateSelectedThread}
                      onReplyingTo={this.handleReplyingTo}
                      onReply={this.handleReply}
                    />
                  )}
                  footer={(
                    <div style={{ backgroundColor: Colors.background, paddingBottom: 50, marginBottom: -50 }}>
                      <FeedPostCommentInput
                        postId={postId}
                        thread={selectedThread}
                        commentsDrawerOpen={commentsDrawerOpen}
                        onAttachmentsOptionOpen={this.closeCommentsDrawer}
                        onAttachmentsOptionClose={this.openCommentsDrawer}
                        onResetThread={this.handleResetSelectedThread}
                        onReply={this.handleReply}
                        replyingTo={replyingTo}
                        replying={replying}
                      />
                    </div>
                  )}
                  render={(onClick) => {
                    return ( */}
                      <div className="d-flex align-items-center mr-4">
                        <SubscribeButton
                          header="Get the Beatmatch app to comment"
                          render={({ onClick }) => (
                            <button
                              type="button"
                              onClick={onClick as any}
                              className="btn m-0 p-0"
                              style={{ minWidth: 40 }}
                            >
                              <Icon name={Icons.comment2} className="bm-Icon--white" size={28} solid={false} />
                            </button>
                          )}
                        />
                        {Boolean(commentsTotal) && (
                          <p
                            className="m-0 p-0 text-white ml-1" 
                          >
                            {commentsTotal}
                          </p>
                        )}
                      </div>
                    {/* );
                  }}
                /> */}
                {/* <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <SendItemButton
                    item={post}
                    render={(onClick: any) => (
                      <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Button
                          type="button"
                          onClick={onClick}
                          style={{ marginLeft: -16, marginRight: -16, minWidth: 40 }}
                        >
                          <Icon name={Icons.share} size={24} solid={sharedAlready} color={sharedAlready ? Colors.primary : Colors.primaryIcon} />
                        </Button>
                      </div>
                    )}
                  />
                  {Boolean(sharesTotal) && (
                    <p
                      className="text-white m-0 p-0 ml-2"
                    >
                      {sharesTotal}
                    </p>
                  )}
                </div> */}
              </div>
            )}
          </div>
        </DoubleTap>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState, { post }: InjectedProps & OwnProps) => {
  const postId = get(post, 'id');

  return {
    currentUser: getCurrentUser(state),
    post: getPost(state, postId) || post,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedPost));