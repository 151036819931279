/* External dependencies */
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

/* Internal dependencies */
import { addNotification } from '../store/ducks/notifications';
import { Notification } from '../types/Notification';
import { CurrentUserState, getCurrentUser } from '../store/ducks/currentUser';
import { ApplicationState } from '../store';
import FeedPost from '../feedPost/FeedPost';
// import Action from '../common/action/Action';
import Collection from '../collection/Collection';

export enum FeedItemType {
  feedPost = 'feedPost',
  collection = 'collection',
  action = 'action',
}

type FeedItemShape = {
  type: FeedItemType;
  payload: Object; 
};


type OwnProps = {
  item: FeedItemShape;
  index: number;
  variant?: 'primary' | 'secondary';
  friendsOnly?: boolean;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = OwnProps & StateProps;

class FeedItem extends React.Component<Props> {

  render() {
    const { item } = this.props;
    const { type, payload } = item;
    const shouldRender = Boolean((FeedItemType as any)[type as string]);

    if (!shouldRender) return null;

    return (
      <>
        {Boolean(type === FeedItemType.feedPost) && <FeedPost post={payload} />}
        {/* {Boolean(type === FeedItemType.action) && <Action action={payload} />} */}
        {Boolean(type === FeedItemType.collection) && <Collection collection={payload} />}
      </>
    );
  }
};

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedItem);