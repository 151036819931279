/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import Paginator, { ActionType, ItemsState } from './paginator';

export const INITIAL_STATE = {
  items: [],
};

export const PAGINATOR_ID = 'postsFeedItems';
const { reducer, setItems, getItems, removeItem, prependItem } = new Paginator(PAGINATOR_ID, {
  reducers: {
    items: (
      state: ItemsState['items'] = INITIAL_STATE['items'],
      action: {
        type: ActionType;
        payload: any;
      }
    ) => {
      const { payload } = action;
      if (action.type === Paginator.getActionType(PAGINATOR_ID, ActionType.SET_ITEMS)) {
        return [...payload];
      } else if (action.type === Paginator.getActionType(PAGINATOR_ID, ActionType.REMOVE_ITEM)) {
        const { id: itemId } = payload;
        const itemIndex = state.findIndex(({ id }) => id === itemId);
    
        if (itemIndex === -1) {
          return state;
        }
    
        return [
          ...state.slice(0, itemIndex),
          ...state.slice(itemIndex + 1),
        ];
      } else if (action.type === Paginator.getActionType(PAGINATOR_ID, 'reducer/{{paginatorId}}/UPDATE_ITEM_BY_POST' as any)) {
        const { id: itemId } = payload;
        const itemIndex = state.findIndex(({ payload: feedItemPayload }: any) => feedItemPayload && feedItemPayload.id === itemId);
        
        if (itemIndex === -1) {
          return state;
        }

        const existingItem = state[itemIndex];
    
        return [
          ...state.slice(0, itemIndex),
          {
            ...existingItem,
            payload,
          },
          ...state.slice(itemIndex + 1),
        ];
      } else if (action.type === Paginator.getActionType(PAGINATOR_ID, 'reducer/{{paginatorId}}/REMOVE_ITEM_BY_POST' as any)) {
        const { id: itemId } = payload;
        const itemIndex = state.findIndex(({ payload: feedItemPayload }: any) => feedItemPayload && feedItemPayload.id === itemId);
    
        if (itemIndex === -1) {
          return state;
        }
    
        return [
          ...state.slice(0, itemIndex),
          ...state.slice(itemIndex + 1),
        ];
      } else if (action.type === Paginator.getActionType(PAGINATOR_ID, ActionType.PREPEND_ITEM)) {
        return [
          payload,
          ...state,
        ];
      } else if (action.type === Paginator.getActionType(PAGINATOR_ID, ActionType.APPEND_ITEM)) {
        return [
          ...state,
          payload,
        ];
      } else if (action.type === Paginator.getActionType(PAGINATOR_ID, 'reducer/{{paginatorId}}/REMOVE_ITEMS_BY_CREATOR' as any)) {
        const { id: creatorId } = payload;
        const newState = state.filter(({ payload: feedPost }: any) => {
          const creators = get(feedPost, 'creators.items', []);
          const foundCreatorPost = Boolean(creators.find(({ id }: any) => id === creatorId));
          return !foundCreatorPost;
        });

        return [...newState];
      }

      return state;
    },
  }
});

/* Selectors */
export const getPostsFeedItems = getItems;

/* Action Creator */
export const setPostsFeedItems = setItems;
export const updatePostsFeedItemByPost = (item: any) => ({
  type: `reducer/${PAGINATOR_ID}/UPDATE_ITEM_BY_POST`,
  payload: item,
});
export const removePostsFeedItem = removeItem;
export const removePostsFeedItemByPost = (item: any) => ({
  type: `reducer/${PAGINATOR_ID}/REMOVE_ITEM_BY_POST`,
  payload: item,
});
export const removePostsFeedItemsByCreator = (item: any) => ({
  type: `reducer/${PAGINATOR_ID}/REMOVE_ITEMS_BY_CREATOR`,
  payload: item,
});
export const prependPostsFeedItem = prependItem;

/* Reducers */
export default reducer;
