/* External dependencies */
import React from 'react';
import get from 'lodash/get';
import { Gif as GifExternal } from '@giphy/react-components';

type OwnProps = {
  gif: any;
};

type Props = OwnProps;

const Gif: React.FC<Props> = ({ gif }) => {
  if (!gif) return null;

  const gifData = get(gif, 'media.data');

  if (!gifData) return null;

  try {
    return (
      <div style={{ overflow: 'hidden', borderRadius: 20 }}>
        <GifExternal
          gif={gifData}
          noLink={true}
          borderRadius={20}
          hideAttribution={true}
          width={300}
          percentWidth="100%"
          style={{ aspectRatio: get(gif, 'media.aspectRatio') }}
        />
      </div>
    );
  } catch (e) {
    console.log('Gif rendering error: ', e);
    return null;
  }
};

export default Gif;