/* External dependencies */
import React from 'react';
import { Link, match, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import moment from 'moment-timezone';
import { Image } from 'react-bootstrap';
import get from 'lodash/get';
import shuffle from 'lodash/shuffle';

/* Internal dependencies */
import './ArtistDetail.scss';
import Spinner from 'src/spinner/Spinner';
import Footer from 'src/footer/Footer';
import Navbar from 'src/navbar/Navbar';
import LogoAsURL from '../assets/images/beatmatch-horizontal.svg';
import Button from 'src/button/Button';
import { User } from 'src/types/User';
import SubscribeButton from 'src/subscribe/SubscribeButton';
import PostShareButtons from 'src/blog/PostShareButtons';
import { ReactComponent as Pin } from '../assets/icons/outline/Pin.svg';
import ScrollToTop from 'src/scrollToTop/ScrollToTop';
import WaitlistMember1 from '../assets/images/waitlist-member-1.jpeg';
import WaitlistMember2 from '../assets/images/waitlist-member-2.jpeg';
import WaitlistMember3 from '../assets/images/waitlist-member-3.jpeg';
import WaitlistMember4 from '../assets/images/waitlist-member-4.jpeg';
import WaitlistMember5 from '../assets/images/waitlist-member-5.jpeg';
import WaitlistMember6 from '../assets/images/waitlist-member-6.jpeg';
import WaitlistMember7 from '../assets/images/waitlist-member-7.jpeg';
import WaitlistMember8 from '../assets/images/waitlist-member-8.jpeg';
import WaitlistMember9 from '../assets/images/waitlist-member-9.jpeg';
import WaitlistMember10 from '../assets/images/waitlist-member-10.jpeg';
import Track from 'src/track/Track';
import { providerToProviderCode as eventProviderToProviderCode } from '../events/Events';
import { capitalizeFirstLetter, slugify } from 'src/store/helpers/users';
import { cleanObjectDeep } from 'src/common/cleanObject';
import { APP_PROMO_DESCRIPTION } from 'src/common/constants/copy';
import FollowArtistButton from './FollowArtistButton';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { ApplicationState } from 'src/store';
import { connect } from 'react-redux';
import { getArtist as getArtistApi } from 'src/store/stories/artists';
import { getArtist } from 'src/store/ducks/artists';
import ViewTracker from 'src/analytics/ViewTracker';
import Icon, { Icons } from 'src/icon/Icon';
import Colors from 'src/colors';

type InjectedProps = {
  match: match<{
    providerCode: string;
    artistId: string; // artist provider ID
    slug?: string;
  }>;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
  artist: any | undefined;
};

type Props = InjectedProps & StateProps;

type State = {
  artist: any | undefined;
  loading: boolean;
};

export const providerToProviderCode: { [provider: string]: string } = {
  spotify: 's',
  appleMusic: 'a',
  beatmatch: 'b',
};
export const providerCodeToProvider: { [code: string]: string }  = Object.keys(providerToProviderCode).reduce((acc, curr) => ({
  ...acc,
  [providerToProviderCode[curr]]: curr,
}), {});

class ArtistDetail extends React.Component<Props, State> {
  images: string[] = shuffle([
    WaitlistMember1,
    WaitlistMember2,
    WaitlistMember3,
    WaitlistMember4,
    WaitlistMember5,
    WaitlistMember6,
    WaitlistMember7,
    WaitlistMember8,
    WaitlistMember9,
    WaitlistMember10,
  ]);

  state = { artist: undefined, loading: true };

  async componentDidMount() {
    await this.loadArtist();
  }

  async componentDidUpdate({ match }: Props) {
    if (match.params.artistId !== this.props.match.params.artistId) {
      await this.loadArtist();
    }
  }

  loadArtist = async () => {
    const {
      match: { params },
    } = this.props;
    const { artistId, providerCode } = params;
    const provider = providerCodeToProvider[providerCode];

    if (!provider) {
      return;
    }

    try {
      this.setState({ loading: true });
      await getArtistApi(`artist:${provider}:${artistId}`, { cache: true });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { artist, match } = this.props;
    const { params } = match;
    let { providerCode = 's', artistId, slug = '' } = params;
    const { loading } = this.state;

    const authorized = get(artist, 'authorized', false);
    const fullArtistId = get(artist, 'id');
    const name = get(artist, 'name');
    const images = get(artist, 'images', []);
    const events = get(artist, 'events');
    const pastEvents = get(artist, 'pastEvents');
    const users = get(artist, 'users');
    const tracks = get(artist, 'tracks');
    const externalUrl = get(artist, 'externalUrl', '');
    const followers = get(artist, 'followers');
    const genres = get(artist, 'genres', []);
    const genreNames = genres.map(({ name }: any) => name);
    const provider = get(artist, 'provider');

    if (Boolean(provider) && providerToProviderCode[provider]) {
      providerCode = providerToProviderCode[provider];
    }

    let externalUrlIcon = 'fa fa-globe';
    let externalUrlPlatform = 'Website';

    if (Boolean(externalUrl)) {
      if (externalUrl.includes('spotify')) {
        externalUrlIcon = 'fa fa-spotify';
        externalUrlPlatform = 'Spotify';
      } else if (externalUrl.includes('instagram')) {
        externalUrlIcon = 'fa fa-instagram';
        externalUrlPlatform = 'Instagram';
      } else if (externalUrl.includes('tiktok')) {
        externalUrlIcon = 'fab fa-tiktok';
        externalUrlPlatform = 'TikTok';
      }
    }
    
    const userItems = get(users, 'items', []);
    const eventItems = get(events, 'items', []);
    const pastEventItems = get(pastEvents, 'items', []);
    const trackItems = get(tracks, 'items', []).filter(({ previewUrl }: any) => Boolean(previewUrl));
    const artistImageUrl = get(images, '[0].url');
    const slugName = slug.split('-').map(capitalizeFirstLetter).join(' ');
    const metaTitle = `${name || slugName || 'Artist'} Events, Fans & More | Beatmatch`;
    const metaDescription = `Follow ${name || slugName || 'Artist'} to hear about event drops, exclusives, and more announcements!`;
    const metaUrl = `https://${process.env.REACT_APP_DOMAIN_NAME}/artists/${providerCode}/${artistId}/${slug}`;
    const metaImage = artistImageUrl || `https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`;
    const metaAudio = get(trackItems, '[0].previewUrl');
    const divider = <div className="d-flex mt-5 mb-5" style={{ width: '100%', borderBottom: '0.5px solid rgba(255,255,255,0.4)' }} />
    const potentialActions = [
      ...trackItems.map(({ name: trackName, previewUrl, album }: any) => ({
        "@type": 'ListenAction',
        target: {
          "@type": 'EntryPoint',
          urlTemplate: `https://www.beatmatch.app/artists/${providerCode}/${artistId}/${slug}`,
          actionPlatform: [
            'http://schema.org/DesktopWebPlatform',
            'http://schema.org/IOSPlatform',
            'http://schema.org/AndroidPlatform',
          ]
        },
        expectsAcceptanceOf: {
          "@type": 'Offer',
          eligibleRegion: {
            "@type": 'Country',
            name: 'US',
          }
        },
        object: {
          "@type": 'MusicRecording',
          name: trackName,
          byArtist: {
            "@type": 'Person',
            name,
          },
          url: previewUrl,
          duration: "PT30S", // ISO 8601 duration format, e.g., 3 minutes and 45 seconds
          inAlbum: {
            "@type": 'MusicAlbum',
            name: get(album, 'name'),
            url: get(album, 'externalUrl'),
          },
        },
      })),
      ...eventItems.map(({ name, date, startDate, endDate, venue }: any) => (cleanObjectDeep({
        "@type": 'BuyAction',
        target: {
          "@type": 'EntryPoint',
          urlTemplate: `https://www.beatmatch.app/artists/${providerCode}/${artistId}/${slug}`,
          actionPlatform: [
            'http://schema.org/DesktopWebPlatform',
            'http://schema.org/IOSPlatform',
            'http://schema.org/AndroidPlatform',
          ],
        },
        object: {
          "@type": "Event",
          name,
          startDate: startDate || date,
          endDate,
          location: Boolean(venue) ? {
            "@type": 'Place',
            name: Boolean(venue) ? venue.name : undefined,
            address: {
              "@type": 'PostalAddress',
              streetAddress: get(venue, 'addressFull', `${get(venue, 'address.line1', '')} ${get(venue, 'address.line2', '')}`),
              addressLocality: get(venue, 'city.name'),
              addressRegion: get(venue, 'state.code'),
              postalCode: get(venue, 'zipCode'),
              addressCountry: get(venue, 'country.name'),
            }
          } : undefined,
        },
      }))),
    ];

    return (
      <div className="bg-black" style={{ height: '100%' }}>
        {Boolean(fullArtistId) && <ViewTracker itemId={fullArtistId} />}
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={[name, ...genreNames].join(',')} />
          <meta property="og:description" content={name} />
          <meta property="og:url" content={metaUrl} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:determiner" content="a" />
          <meta property="og:type" content="profile" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:alt" content={name} />
          {Boolean(metaAudio) && <meta property="og:audio" content={metaAudio} />}
          {Boolean(metaAudio) && <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />}
          <meta property="music:musician" content={name} />
          <meta property="og:site_name" content="Beatmatch" />
          <meta property="twitter:description" content={metaDescription} />
          <meta name="twitter:site" content="@joinbeatmatch" />
          <meta name="twitter:creator" content="@joinbeatmatch" />
          <meta property="twitter:url" content={metaUrl} />
          <meta property="twitter:image" content={metaImage} />
          <meta property="twitter:image:alt" content={name} />
          <meta property="twitter:title" content={metaTitle} />
          <meta property="twitter:card" content="summary" />
          {Boolean(provider && artistId) && <meta property="al:ios:url" content={`beatmatch://artists/artist:${provider}:${artistId}`} />}
          {Boolean(provider && artistId) && <meta name="apple-itunes-app" content={`app-id=1540216913, app-argument=beatmatch://artists/artist:${provider}:${artistId}`} />}
          {Boolean(provider && artistId) && <meta name="branch:deeplink:artistId" content={`artist:${provider}:${artistId}`} />}
          {Boolean(provider && artistId) && <meta name="branch:deeplink:artistName" content={name} />}
          {Boolean(provider && artistId) && <meta name="branch:deeplink:$deeplink_path" content={`artists/artist:${provider}:${artistId}`} />}

          <link rel="canonical" href={metaUrl} />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Person",
                "name": "${name}",
                "url": "https://www.beatmatch.app/artists/${providerCode}/${artistId}/${slug}",
                "description": "Meet ${name} fans near you. Discover upcoming ${name} shows and find people to go to with you.",
                "genre": [${genres.map(({ name }: any) => `"${name}"` )}],
                "sameAs": ["${externalUrl}"],
                ${Boolean(images && images.length) ? `"image": [${images.map(({ url }: any) => `"${url}"`)}],` : ''}
                "potentialAction": ${JSON.stringify(potentialActions)}
              }
            `}
          </script>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "MusicGroup",
                "name": "${name}",
                "url": "https://www.beatmatch.app/artists/${providerCode}/${artistId}/${slug}",
                "description": "Meet ${name} fans near you. Discover upcoming ${name} shows and find people to go to with you.",
                "genre": [${genres.map(({ name }: any) => `"${name}"` )}],
                "sameAs": ["${externalUrl}"],
                ${Boolean(images && images.length) ? `"image": [${images.map(({ url }: any) => `"${url}"`)}],` : ''}
                "potentialAction": ${JSON.stringify(potentialActions)}
              }
            `}
          </script>
        </Helmet>
        <ScrollToTop />
        <Navbar showLogo={true} alwaysFillNavbar={false} />
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ marginTop: -75, paddingTop: 75, minHeight: '100vh', backgroundColor: '#060710' }}>
            <Spinner />
          </div>
        ) : (
          <div className="bm-Artist" style={{ marginTop: -75, position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                overflow: 'hidden',
                maxWidth: '100%',
                minHeight: '100vh',
                height: '100%',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  transform: 'scale(1.1)',
                  objectFit: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(6, 7, 16, 1), rgba(6, 7, 16, 1)), url(${artistImageUrl})`,
                  filter: 'blur(15px)',
                  position: 'fixed',
                }}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                top: -75,
                left: 0,
                width: '100%',
                background: 'linear-gradient(rgba(6, 7, 16, 0.85), rgba(6, 7, 16, 1), rgba(6, 7, 16, 1))',
                paddingTop: 100,
              }}
            >
              <section
                className="bm-Artist_wrapper d-flex justify-content-center container-fluid"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="container d-flex justify-content-center" style={{ marginTop: 75 }}>
                  <div className="" style={{ borderRadius: 20, overflow: 'hidden' }}>
                    <div className="bm-Artist__container d-flex align-items-start mb-5" style={{ position: 'relative' }}>
                      <section className="bm-Artist__imageContainer mb-3">
                        {Boolean(artistImageUrl) && (
                          <Image
                            className="bm-Artist__image"
                            src={artistImageUrl}
                            width="30%"
                            height={undefined}
                            style={{ aspectRatio: '1', objectFit: 'cover' }}
                          />
                        )}
                        {!Boolean(artistImageUrl) && (
                          <div
                            className="bm-Artist__image d-flex justify-content-center align-items-center bg-secondaryButton"
                            style={{ aspectRatio: '1', minWidth: 200, borderRadius: 1000 }}
                          >
                            <Icon
                              name={Icons.microphone}
                              className="bm-Icon--primary"
                              size={125}
                            />
                          </div>
                        )}
                        <div className="d-none d-lg-block">
                          <PostShareButtons text={`Have you listened to ${name}? Find ${name} fans on Beatmatch!`} imageUrl={artistImageUrl} />
                        </div>
                      </section>
                      <section
                        className="bm-Artist__rightSection container d-flex flex-column justify-content-center"
                        style={{ textOverflow: 'ellipsis' }}
                      >
                        <div className="d-flex align-items-center mb-2">
                          <h1 className="bm-Artist_title text-bold text-white mr-3">{name}</h1>
                          <a
                            className="bm-Artist__openExternalButton text-white"
                            aria-label={`Open in ${externalUrlPlatform}`}
                            href={Boolean(externalUrl.startsWith('http')) ? externalUrl : `https://${externalUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className={externalUrlIcon} style={{ fontSize: 28 }} />
                          </a>
                        </div>
                        {Boolean(userItems.length) && (
                          <div className="d-flex mb-5 align-items-center">
                            <div className="d-flex align-items-center mr-3">
                              {shuffle(userItems)
                                .filter(({ images = [] }) => Boolean(images.length) && get(images, '[0].url') !== 'https://i.stack.imgur.com/l60Hf.png')
                                .slice(0, 3).map(({ images }: User, i: number) => (
                                <Image
                                  src={get(images, '[0].url', 'https://i.stack.imgur.com/l60Hf.png')}
                                  style={{
                                    zIndex: userItems.slice(0,3).length - i,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 22.5,
                                    marginLeft: i === 0 ? 0 : -8,
                                    borderWidth: 2,
                                    borderStyle: 'solid',
                                    borderColor: '#000',
                                    objectFit: 'cover',
                                  }}
                                />
                              ))}
                            </div>
                            <h6 className="text-white" style={{ marginLeft: -6, margin: 0 }}>
                              {Boolean(followers) && <span className="text-bold" style={{ marginLeft: -5 }}>Followed by <span className="text-capitalize text-bold">{get(userItems, '[0].name', '')}</span> and {followers.toLocaleString()} others!</span>}
                              {!Boolean(followers) && <span className="text-bold" style={{ marginLeft: -5 }}>Followed by <span className="text-capitalize text-bold">{get(userItems, '[0].name', '')}</span> and others!</span>}
                            </h6>
                          </div>
                        )}
                        <div className="d-flex flex-wrap">
                          <div className="bm-Artist__followButton">
                            <FollowArtistButton
                              artist={artist}
                              showIcon={Boolean(artist && artist.added)}
                              fontSize="1.1rem"
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center d-lg-none mt-5">
                          <PostShareButtons text={`Have you listened to ${name}? Find ${name} fans on Beatmatch!`} imageUrl={artistImageUrl} />
                        </div>
                        {/* {Boolean(description) && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="text-white mb-3 text-bold">About</h3>
                              <p className="text-white">{description}</p>
                            </div>
                          </>
                        )} */}
                        {Boolean(trackItems && trackItems.length) && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="bm-Artist__sectionHeader text-bold mb-4 text-white">Listen to {name}</h3>
                              {trackItems.slice(0,4).map((track: any, i: number) => {
                                if (i === 0) {
                                  return (
                                    <div className="mb-4 p-4" style={{ backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: 20 }}>
                                      <Track track={track} imageSize={150} textColor="light" wrap={false} />
                                    </div>
                                  );
                                }

                                return (
                                  <div className={i === trackItems.length - 1 ? '' : 'mb-3'}>
                                    <Track track={track} textColor="light" wrap={false} />
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                        {Boolean(eventItems.length) && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="bm-Artist__sectionHeader text-white mb-4 text-bold">Upcoming events</h3>
                              <div className="bm-Artist__linupArtistsContainer d-flex" style={{ width: '100%' }}>
                                {eventItems.map((event: any, i: number) => {
                                  const { name, images = [], date, venue, provider, providerId } = event;
                                  const momentDate = moment(date);
                                  const timezone = get(event, 'timezone', 'America/Los_Angeles');
                                  const today = moment();
                                  const tomorrow = moment().add(1, 'day');
                                  const isToday = momentDate.isSame(today, 'day');
                                  const isTomorrow = momentDate.isSame(tomorrow, 'day');
                                  const slug = slugify(name);
                                  const venueContent = (
                                    <h6
                                      className="bm-Artist__horizontalItemSubtext"
                                      style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                    >
                                      {(venue as any).name}
                                    </h6>
                                  );

                                  return (
                                    <div
                                      className={`d-flex flex-column ${
                                        i < eventItems.length - 1 ? ' mr-4' : ''
                                      }`}
                                      style={{ width: '20%', minWidth: 200 }}
                                    >
                                      <Link
                                        to={`/events/${eventProviderToProviderCode[provider]}/${providerId}/${slug}`}
                                      >
                                        <Image
                                          key={i}
                                          src={get(images, '[0].url')}
                                          className="mb-3"
                                          style={{
                                            height: undefined,
                                            width: '100%',
                                            aspectRatio: '1/1',
                                            borderRadius: 20,
                                            objectFit: 'cover',
                                          }}
                                        />
                                      </Link>
                                      <div style={{ width: '100%' }}>
                                        <div className="d-flex mb-1">
                                          {(isToday || isTomorrow) && (
                                            <>
                                              <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                                                {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.tz(timezone).format('ddd').toUpperCase()}
                                              </h6>
                                              <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                                              {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()}
                                              </h6>
                                            </>
                                          )}
                                          {!isToday && !isTomorrow && (
                                            <>
                                              <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                                                {momentDate.tz(timezone).format('ddd').toUpperCase()}
                                              </h6>
                                              <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                                                {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()} -{' '}
                                                {momentDate.tz(timezone).format('h:mm A')}
                                              </h6> 
                                            </>
                                          )}
                                        </div>
                                        <Link
                                          to={`/events/${eventProviderToProviderCode[provider]}/${providerId}/${slug}`}
                                        >
                                          <h6 className="text-bold text-white mb-1" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</h6>
                                        </Link>
                                        {Boolean(venue) && (
                                          <>
                                            {Boolean(venue.id) ? (
                                              <Link
                                                to={`/venues/${get(venue, 'id', '').replace('venue:', '')}/${slugify(get(venue, 'name', ''))}`}
                                                className="bm-Artist__horizontalItemSubtext"
                                              >
                                                {venueContent}
                                              </Link>
                                            ) : venueContent}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                        {Boolean(pastEventItems.length) && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="bm-Artist__sectionHeader text-white mb-4 text-bold">Past events</h3>
                              <div className="bm-Artist__linupArtistsContainer d-flex" style={{ width: '100%' }}>
                                {pastEventItems.filter(({ images = [] }) => Boolean(images.length)).map((event: any, i: number) => {
                                  const { name, images = [], date, venue, provider, providerId } = event;
                                  const momentDate = moment(date);
                                  const timezone = get(event, 'timezone', 'America/Los_Angeles');
                                  const today = moment();
                                  const tomorrow = moment().add(1, 'day');
                                  const isToday = momentDate.isSame(today, 'day');
                                  const isTomorrow = momentDate.isSame(tomorrow, 'day');
                                  const slug = slugify(name);
                                  const venueContent = (
                                    <h6
                                      className="bm-Artist__horizontalItemSubtext"
                                      style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                    >
                                      {(venue as any).name}
                                    </h6>
                                  );

                                  return (
                                    <div
                                      className={`d-flex flex-column ${
                                        i < pastEventItems.length - 1 ? ' mr-4' : ''
                                      }`}
                                      style={{ width: '20%', minWidth: 200 }}
                                    >
                                      <Link
                                        to={`/events/${eventProviderToProviderCode[provider]}/${providerId}/${slug}`}
                                      >
                                        <Image
                                          key={i}
                                          src={get(images, '[0].url')}
                                          className="mb-3"
                                          style={{
                                            height: undefined,
                                            width: '100%',
                                            aspectRatio: '1/1',
                                            borderRadius: 20,
                                            objectFit: 'cover',
                                          }}
                                        />
                                      </Link>
                                      <div style={{ width: '100%' }}>
                                        <div className="d-flex mb-1">
                                          {(isToday || isTomorrow) && (
                                            <>
                                              <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                                                {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.tz(timezone).format('ddd').toUpperCase()}
                                              </h6>
                                              <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                                              {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()}
                                              </h6>
                                            </>
                                          )}
                                          {!isToday && !isTomorrow && (
                                            <>
                                              <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                                                {momentDate.tz(timezone).format('ddd').toUpperCase()}
                                              </h6>
                                              <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                                                {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()} -{' '}
                                                {momentDate.tz(timezone).format('h:mm A')}
                                              </h6> 
                                            </>
                                          )}
                                        </div>
                                        <Link
                                          to={`/events/${eventProviderToProviderCode[provider]}/${providerId}/${slug}`}
                                        >
                                          <h6 className="text-bold text-white mb-1" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</h6>
                                        </Link>
                                        {Boolean(venue) && (
                                          <>
                                            {Boolean(venue.id) ? (
                                              <Link
                                                to={`/venues/${get(venue, 'id', '').replace('venue:', '')}/${slugify(get(venue, 'name', ''))}`}
                                                className="bm-Artist__horizontalItemSubtext"
                                              >
                                                {venueContent}
                                              </Link>
                                            ) : venueContent}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                        {divider}
                        <div className="container">
                          <h3 className="bm-Artist__sectionHeader text-white text-bold mb-4">Download the Beatmatch app</h3>
                          <p className="bm-Text--secondaryText mb-4 d-flex flex-wrap">
                            {APP_PROMO_DESCRIPTION}
                          </p>
                          <div className="bm-Artist__downloadButtonsContainer mb-4 flex-wrap" style={{ width: '100%' }}>
                            {/* <Button
                              className="text-black mr-3 align-items-center mb-3"
                              variant="secondary"
                              href="https://apps.apple.com/us/app/beatmatch-music-dating-app/id1540216913"
                              target="_blank"
                              style={{ borderRadius: 30, fontWeight: 700, height: 45, flex: 1 }}
                            >
                              <i className="fa fa-apple text-black" style={{ marginRight: 10 }} />
                              <span>Download on iOS</span>
                            </Button> */}
                            <div style={{ flex: 1 }}>
                              <SubscribeButton variant="secondary" color="#000" text="Get the app">
                                <h6 className="text-black pt-1">Get the app</h6>
                              </SubscribeButton>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
              {Boolean(authorized) && (
                <nav
                  className="fixed-bottom pt-4 pb-4"
                  style={{ backgroundColor: 'rgba(0,0,0,1)', borderTop: '0.25px solid rgba(255,255,255,0.25)' }}
                >
                  <div className="container d-flex justify-content-center" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                      <Link
                        to={`/artist/${providerCode}/${artistId}/manage/insights`}
                        className="mr-3"
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          variant="secondary"
                          style={{
                            width: '100%',
                            borderRadius: 30,
                            fontWeight: 700,
                            height: 125,
                            backgroundColor: Colors.transparent,
                            borderColor: Colors.white,
                            borderWidth: 0.25,
                            minWidth: 175,
                          }}
                        >
                          <h6 className="m-0 p-0 text-bold text-white">View insights</h6>
                        </Button>
                      </Link>
                      <Link
                        to={`/artist/${providerCode}/${artistId}/manage`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          className="bm-CreateCommunity__submitButton"
                          variant="secondary"
                          style={{
                            borderRadius: 30,
                            fontWeight: 700,
                            height: 125,
                            color: Colors.background,
                            minWidth: 175,
                          }}
                        >
                          <h6 className="m-0 p-0 text-bold">Manage artist</h6>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </nav>
              )}
              <Footer />
            </div>
          </div>
        )}
        {/* {!loading && !artist && <Redirect to="/not-found" />} */}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState, { match: { params } }: InjectedProps) => {
  const { artistId, providerCode } = params;
  const provider = providerCodeToProvider[providerCode];

  return {
    currentUser: getCurrentUser(state),
    artist: getArtist(state, `artist:${provider}:${artistId}`),
  };
};

export default connect(mapStateToProps)(ArtistDetail);
