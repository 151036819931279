/* External dependencies */
import React from 'react';
import { Link, match, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import moment from 'moment-timezone';
import { Image } from 'react-bootstrap';
import get from 'lodash/get';
import shuffle from 'lodash/shuffle';
import uniqBy from 'lodash/uniqBy';
import { connect } from 'react-redux';
import { AdvancedMarker, Pin as PinMarker } from '@vis.gl/react-google-maps';

/* Internal dependencies */
import './Event.scss';
import Spinner from 'src/spinner/Spinner';
import Footer from 'src/footer/Footer';
import Navbar from 'src/navbar/Navbar';
import LogoAsURL from '../assets/images/beatmatch-horizontal.svg';
import Button from 'src/button/Button';
import { User } from 'src/types/User';
import SubscribeButton from 'src/subscribe/SubscribeButton';
import PostShareButtons from 'src/blog/PostShareButtons';
import { ReactComponent as Pin } from '../assets/icons/outline/Pin.svg';
import ScrollToTop from 'src/scrollToTop/ScrollToTop';
import { providerCodeToProvider } from './Events';
import WaitlistMember3 from '../assets/images/waitlist-member-3.jpeg';
import WaitlistMember4 from '../assets/images/waitlist-member-4.jpeg';
import WaitlistMember5 from '../assets/images/waitlist-member-5.jpeg';
import WaitlistMember6 from '../assets/images/waitlist-member-6.jpeg';
import WaitlistMember7 from '../assets/images/waitlist-member-7.jpeg';
import WaitlistMember9 from '../assets/images/waitlist-member-9.jpeg';
import WaitlistMember10 from '../assets/images/waitlist-member-10.jpeg';
import Track from 'src/track/Track';
import { providerToProviderCode } from 'src/artist/ArtistDetail';
import Icon, { Icons } from 'src/icon/Icon';
import { providerToProviderCode as eventProviderToProviderCode } from '../events/Events';
import { capitalizeFirstLetter, getUserSubtext, modelUser, slugify } from 'src/store/helpers/users';
import { cleanObjectDeep } from 'src/common/cleanObject';
import { listLocations } from 'src/api/locations';
import { APP_PROMO_DESCRIPTION } from 'src/common/constants/copy';
import MediaObject from '../mediaObject/MediaObject';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { ApplicationState } from 'src/store';
import JoinEventButton from './JoinEventButton';
import { getEvent as getEventApi } from 'src/store/stories/events';
import { getEvent } from 'src/store/ducks/events';
import { fetchAll } from 'src/common/helpers/fetchAll';
import Colors from 'src/colors';
import FollowArtistButton from 'src/artist/FollowArtistButton';
import BuyButton from './BuyButton';
import Item from 'src/item/Item';
import JoinCommunityButton from 'src/community/JoinCommunityButton';
import { generateShareUrlFromItem } from 'src/item/ShareItem';
import Divider from 'src/divider/Divider';
import { DEFAULT_FEES, getFinalItemAmount } from 'src/store/helpers/tickets';
import UserDefaultAvatar from 'src/user/UserDefaultAvatar';
import ViewTracker from 'src/analytics/ViewTracker';
import { isTicketAvailable } from 'src/store/helpers/events';
import Map from 'src/map/Map';

type InjectedProps = {
  match: match<{
    providerCode: string;
    eventId: string; // event provider ID
    slug?: string;
  }>;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
  event: any | undefined;
};

type Props = InjectedProps & StateProps;

type State = {
  event: any | undefined;
  locations: any[];
  loading: boolean;
  shareUrl?: string;
};

export const TICKET_MASTER_DEFAULT_IMAGES = [
  'https://s1.ticketm.net/dam/c/80b/f3cd8d24-c3ae-4fa0-b4bc-1ba99f9b380b_106091_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/060/c5c08e7a-9912-456c-a060-2758be94e060_105881_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/ab4/6367448e-7474-4650-bd2d-02a8f7166ab4_106161_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/48b/2352e3b5-8496-496b-97a3-e605177e848b_105851_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/bea/03d47f66-d37b-4aca-aa17-0135be64dbea_105801_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/fbc/b293c0ad-c904-4215-bc59-8d7f2414dfbc_106141_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/fbc/b293c0ad-c904-4215-bc59-8d7f2414dfbc_106141_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/ab4/6367448e-7474-4650-bd2d-02a8f7166ab4_106161_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/fbc/b293c0ad-c904-4215-bc59-8d7f2414dfbc_106141_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/060/c5c08e7a-9912-456c-a060-2758be94e060_105881_RETINA_LANDSCAPE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/df8/81eadad8-4449-412e-a2b1-3d8bbb78edf8_106181_TABLET_LANDSCAPE_LARGE_16_9.jpg',
  'https://s1.ticketm.net/dam/c/f50/96fa13be-e395-429b-8558-a51bb9054f50_105951_TABLET_LANDSCAPE_LARGE_16_9.jpg',
];

class Event extends React.Component<Props, State> {
  images: string[] = shuffle([
    WaitlistMember3,
    WaitlistMember4,
    WaitlistMember5,
    WaitlistMember6,
    WaitlistMember7,
    WaitlistMember9,
    WaitlistMember10,
  ]);

  state = { event: undefined, locations: [],loading: true, shareUrl: undefined };

  async componentDidMount() {
    await this.load();
  }

  async componentDidUpdate(prevProps: Props) {
    const prevEventId = get(prevProps, 'match.params.eventId');
    const eventId = get(this.props, 'match.params.eventId');

    if (Boolean(eventId) && prevEventId !== eventId) {
      await this.load();
    }
  }

  load = async () => {
    return await Promise.all([
      this.loadEvent(),
    ]);
  };

  loadEvent = async () => {
    const {
      match: { params },
    } = this.props;
    const { eventId, providerCode } = params;
    const provider = providerCodeToProvider[providerCode];

    if (!provider) {
      return;
    }

    try {
      this.setState({ loading: true });
      const event = await getEventApi(`event:${provider}:${eventId}`, { cache: true });
      const shareUrl = await generateShareUrlFromItem(event);
      this.setState({ shareUrl });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  loadLocations = async () => {
    try {
      const { items: locations = [] } = await listLocations();
      this.setState({ locations });
      return locations;
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { currentUser, match, event } = this.props;
    const { params } = match;
    let { providerCode = 't', eventId, slug = '' } = params;
    const { loading, shareUrl } = this.state;

    const authorized = get(event, 'authorized', false);
    const fullEventId = get(event, 'id');
    const name = get(event, 'name');
    const description = get(event, 'description');
    const date = get(event, 'date');
    const startDate = get(event, 'startDate', date);
    const endDate = get(event, 'endDate');
    const images = get(event, 'images', []);
    const venue = get(event, 'venue');
    const venues = get(event, 'venues');
    const status = get(event, 'status');
    const hasEventPassed = status === 'passed';
    const hasEventCancelled = status === 'cancelled';
    const hasEventInactive = status === 'inactive';
    const artists = get(event, 'artists');
    const activities = get(event, 'activities');
    const tickets = get(event, 'tickets');
    const relatedEvents = get(event, 'relatedEvents');
    const users = get(event, 'users');
    const creators = get(event, 'creators');
    const creator = get(event, 'creator', get(creators, 'items[0]'));
    const creatorId = get(event, 'creatorId', get(creator, 'id'));
    const tracks = get(event, 'tracks');
    const externalUrl = get(event, 'externalUrl');
    const provider = get(event, 'provider');
    const preferences = get(event, 'preferences', {});
    const showGuestlist = get(preferences, 'showGuestlist', true);

    if (Boolean(provider) && eventProviderToProviderCode[provider]) {
      providerCode = eventProviderToProviderCode[provider];
    }

    const venueItems = get(venues, 'items', []);
    const venueItem = get(venueItems, '[0]', venue);
    const venueLocation = get(venueItem, 'location');
    let venueLatitude = get(venueLocation, 'latitude.value', get(venueLocation, 'latitude'));
    let venueLongitude = get(venueLocation, 'longitude.value', get(venueLocation, 'longitude'));
    venueLatitude = Boolean(venueLatitude) ? parseFloat(venueLatitude) : undefined;
    venueLongitude = Boolean(venueLongitude) ? parseFloat(venueLongitude) : undefined;

    const addressFull = get(venueItem, 'addressFull');
    const line1 = get(venueItem, 'address.line1');
    const line2 = get(venueItem, 'address.line2');
    const city = get(venueItem, 'city.name');
    const state = get(venueItem, 'state.code');
    const country = get(venueItem, 'country.code');
    const zipCode = get(venueItem, 'zipCode');
    const creatorItems = get(creators, 'items', []);
    const creatorTotal = get(creators, 'total', creatorItems.length);
    const userItems = get(users, 'items', []).map(modelUser);
    const userTotal = get(users, 'total', userItems.length);
    const artistItems = get(artists, 'items', []);
    const artistNames = artistItems.map(({ name }: any) => name);
    const genres = get(event, 'genres.items', []);
    const genreNames = genres.map(({ name }: any) => name);
    const hashtags = get(event, 'hashtags.items', []);
    const hashtagNames = hashtags.map(({ name }: any) => name);
    const keywords = [...artistNames, ...genreNames, ...hashtagNames];
    const activityItems = get(activities, 'items', []);
    const activityTotal = get(activities, 'total', activityItems.length);
    const ticketItems = get(tickets, 'items', []);
    const ticketTotal = get(tickets, 'total', ticketItems.length);
    const [cheapestAvailableTicket] = ticketItems.filter(isTicketAvailable);
    const cheapestAvailableTicketPrice = get(cheapestAvailableTicket, 'priceValue');
    const hasTicketOptions = Boolean(ticketItems && ticketItems.length);
    const ordered = get(event, 'ordered');
    const orders = get(ordered, 'items', []); // Order from current user
    const [order] = orders;
    const orderedItems = get(order, 'orderItems.items', []);
    const [orderedItem] = orderedItems;
    const orderedTicketName = get(orderedItem, 'item.name');
    const orderedQuantity: number = orderedItems.reduce((acc: any, { quantity }: any) => {
      return acc + quantity;
    }, 0);
    // const orderedSum: number = orderedItems.reduce((acc: any, item: any) => {
    //   const { quantity } = item;

    //   return acc + getFinalItemAmount(item, quantity, DEFAULT_FEES);
    // }, 0);
    const relatedEventItems = get(relatedEvents, 'items', []);
    const trackItems = get(tracks, 'items', []).filter(({ previewUrl }: any) => Boolean(previewUrl));
    const momentDate = startDate && moment(startDate);
    const momentEndDate = endDate && moment(endDate);
    const timezone = get(event, 'timezone', 'America/Los_Angeles');
    const eventImage = get(images, '[0]');
    const eventImageUrl = get(eventImage, 'url', get(artistItems, '[0].images[0].url'));
    const finalEventImageUrl = TICKET_MASTER_DEFAULT_IMAGES.includes(eventImageUrl)
      ? get(event, 'artists.items[0].images[0].url', get(event, 'artists[0].images[0].url', eventImageUrl))
      : eventImageUrl;
    const eventImageHeight = Number.parseInt(get(eventImage, 'height.value', get(eventImage, 'height', 1080)));
    const eventImageWidth = Number.parseInt(get(eventImage, 'width.value', get(eventImage, 'width', 1080)));
    const eventImageAspectRatio = eventImageWidth > eventImageHeight ? 1 : eventImageWidth / eventImageHeight;
    const slugName = slug.split('-').map(capitalizeFirstLetter).join(' ');
    const metaTitle = `${name || slugName || 'Event'} Tickets, Fans | Beatmatch`;
    const metaDescription = `Get tickets to ${name || slugName || 'this event'}, stay updated, and meet people going!`;
    const metaUrl = `https://${process.env.REACT_APP_DOMAIN_NAME}/events/${providerCode}/${eventId}/${slug}`;
    const metaImage = finalEventImageUrl || `https://${process.env.REACT_APP_DOMAIN_NAME}${LogoAsURL}`;
    const metaAudio = get(trackItems, '[0].previewUrl');
    const divider = <div className="d-flex mt-5 mb-5" style={{ width: '100%', borderBottom: '0.5px solid rgba(255,255,255,0.4)' }} />;
    const venueContent = (
      <h5
        className="bm-Event__horizontalItemSubtext d-flex flex-wrap text-light"
      >
        {get(venueItem, 'name')}
      </h5>
    );
    const potentialActions = [
      ...trackItems.map(({ name: trackName, previewUrl, album }: any) => ({
        "@type": 'ListenAction',
        target: {
          "@type": 'EntryPoint',
          urlTemplate: `https://www.beatmatch.app/events/${providerCode}/${eventId}/${slug}`,
          actionPlatform: [
            'http://schema.org/DesktopWebPlatform',
            'http://schema.org/IOSPlatform',
            'http://schema.org/AndroidPlatform',
          ]
        },
        expectsAcceptanceOf: {
          "@type": 'Offer',
          eligibleRegion: {
            "@type": 'Country',
            name: 'US',
          }
        },
        object: {
          "@type": 'MusicRecording',
          name: trackName,
          byArtist: {
            "@type": 'Person',
            name,
          },
          url: previewUrl,
          duration: "PT30S", // ISO 8601 duration format, e.g., 3 minutes and 45 seconds
          inAlbum: {
            "@type": 'MusicAlbum',
            name: get(album, 'name'),
            url: get(album, 'externalUrl'),
          },
        },
      })),
      cleanObjectDeep({
        "@type": 'BuyAction',
        target: {
          "@type": 'EntryPoint',
          urlTemplate: `https://www.beatmatch.app/events/${providerCode}/${eventId}/${slug}`,
          actionPlatform: [
            'http://schema.org/DesktopWebPlatform',
            'http://schema.org/IOSPlatform',
            'http://schema.org/AndroidPlatform',
          ],
        },
        object: {
          "@type": "Event",
          name,
          startDate: startDate || date,
          endDate,
          location: Boolean(venue) ? {
            "@type": 'Place',
            name: Boolean(venue) ? venue.name : undefined,
            address: {
              "@type": 'PostalAddress',
              streetAddress: get(venue, 'addressFull', `${get(venue, 'address.line1', '')} ${get(venue, 'address.line2', '')}`),
              addressLocality: get(venue, 'city.name'),
              addressRegion: get(venue, 'state.code'),
              postalCode: get(venue, 'zipCode'),
              addressCountry: get(venue, 'country.name'),
            }
          } : undefined,
        },
      }),
    ];

    return (
      <div className="bg-black" style={{ height: '100%' }}>
        {Boolean(fullEventId) && <ViewTracker itemId={fullEventId} />}
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          {(keywords.length) && <meta name="keywords" content={keywords.join(',')} />}
          <meta property="og:description" content={metaDescription} />
          <meta property="og:url" content={metaUrl} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:determiner" content="a" />
          <meta property="og:type" content="event" />
          {Boolean(startDate || date) && <meta property="og:event:start_time" content={startDate || date} />}
          {Boolean(endDate) && <meta property="og:event:end_time" content={endDate} />}
          {artistNames.map((artistName: string, i: number) => (
            <meta key={i} property="music:musician" content={artistName} />
          ))}
          <meta property="og:title" content={metaTitle} />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:alt" content={name} />
          {Boolean(metaAudio) && <meta property="og:audio" content={metaAudio} />}
          {Boolean(metaAudio) && <meta property="og:audio:type" content="audio/vnd.facebook.bridge" />}
          <meta property="og:site_name" content="Beatmatch" />
          <meta property="twitter:description" content={metaDescription} />
          <meta name="twitter:site" content="@joinbeatmatch" />
          <meta name="twitter:creator" content="@joinbeatmatch" />
          <meta property="twitter:url" content={metaUrl} />
          <meta property="twitter:image" content={metaImage} />
          <meta property="twitter:image:alt" content={name} />
          <meta property="twitter:title" content={metaTitle} />
          <meta property="twitter:card" content="summary" />
          {Boolean(provider && eventId) && <meta property="al:ios:url" content={`beatmatch://events/event:${provider}:${eventId}`} />}
          {Boolean(provider && eventId) && <meta name="apple-itunes-app" content={`app-id=1540216913, app-argument=beatmatch://events/event:${provider}:${eventId}`} />}
          {Boolean(provider && eventId) && <meta name="branch:deeplink:eventId" content={`event:${provider}:${eventId}`} />}
          {Boolean(provider && eventId) && <meta name="branch:deeplink:eventName" content={name} />}
          {Boolean(provider && eventId) && <meta name="branch:deeplink:$deeplink_path" content={`events/event:${provider}:${eventId}`} />}
          <link rel="canonical" href={metaUrl} />
          {Boolean(date) && (
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org",
                  "@type": "Event",
                  "name": "${name}",
                  ${Boolean(description) ? `"description": "${description.replaceAll('"', '\'')}",` : ''} 
                  ${Boolean(date) ? `"startDate": "${date}",` : ''}
                  ${Boolean(endDate) ? `"endDate": "${endDate}",` : ''}
                  "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
                  "eventStatus": "https://schema.org/EventScheduled",
                  "location": {
                    "@type": "Place",
                    "name": "${venue && venue.name}",
                    "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "${line2 ? `${line1}, ${line2}` : line1}",
                      "addressLocality": "${city || ''}",
                      "postalCode": "${zipCode || ''}",
                      "addressRegion": "${state || ''}",
                      "addressCountry": "${country || ''}"
                    }
                  },
                  ${Boolean(images && images.length) ? `"image": [${images.map(({ url }: any) => `"${url}"`)}],` : ''}
                  "performer": [${artistItems.map(({ name }: any) => `{ "@type": "Person", "name": "${name}" }`).join(',')}],
                  "potentialAction": ${JSON.stringify(potentialActions)}
                }
              `}
            </script>
          )}
        </Helmet>
        <ScrollToTop />
        <Navbar showLogo={true} alwaysFillNavbar={false} />
        {loading && !event ? (
          <div className="d-flex justify-content-center align-items-center" style={{ marginTop: -75, paddingTop: 75, minHeight: '100vh', backgroundColor: '#060710' }}>
            <Spinner />
          </div>
        ) : (
          <div className="bm-Event" style={{ marginTop: -75, position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                overflow: 'hidden',
                maxWidth: '100%',
                minHeight: '100vh',
                height: '100%',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  transform: 'scale(1.1)',
                  objectFit: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(6, 7, 16, 1), rgba(6, 7, 16, 1)), url(${finalEventImageUrl})`,
                  filter: 'blur(15px)',
                  position: 'fixed',
                }}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                top: -75,
                left: 0,
                width: '100%',
                background: 'linear-gradient(rgba(6, 7, 16, 0.85), rgba(6, 7, 16, 1), rgba(6, 7, 16, 1))',
                paddingTop: 100,
              }}
            >
              <section
                className="bm-Event_wrapper d-flex justify-content-center container-fluid"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="container d-flex justify-content-center" style={{ marginTop: 75 }}>
                  <div className="" style={{ borderRadius: 20, overflow: 'hidden' }}>
                    <div className="bm-Event__container d-flex align-items-start mb-5" style={{ position: 'relative' }}>
                      <section className="bm-Event__imageContainer mb-2">
                        {Boolean(finalEventImageUrl) && (
                          <Image
                            className="bm-Event__image"
                            src={finalEventImageUrl}
                            width="30%"
                            height={undefined}
                            style={{
                              minWidth: 200,
                              maxHeight: 425,
                              aspectRatio: `${eventImageAspectRatio}`,
                              objectFit: 'cover',
                              borderRadius: 20,
                            }}
                          />
                        )}
                        {!Boolean(finalEventImageUrl) && (
                          <div
                            className="bm-Event__image bg-secondaryButton d-flex justify-content-center align-items-center"
                            style={{
                              aspectRatio: '1',
                              minWidth: 200,
                              maxHeight: 425,
                              borderRadius: 20,
                            }}
                          >
                            <Icon
                              name={Icons.ticket}
                              className="bm-Icon--primary"
                              size={125}
                            />
                          </div>
                        )}
                        <div className="d-none d-lg-block">
                          <PostShareButtons url={shareUrl} text={`Want to go to ${name}? Join me on #Beatmatch!`} imageUrl={finalEventImageUrl} />
                        </div>
                      </section>
                      <section
                        className="bm-Event__rightSection container d-flex flex-column justify-content-center"
                        style={{ textOverflow: 'ellipsis' }}
                      >
                        {Boolean(momentDate) && (
                          <div className="d-flex mb-1">
                            <h3 className="text-bold text-primary mr-2" style={{ fontWeight: 700 }}>
                              {momentDate.tz(timezone).format('ddd').toUpperCase()}
                            </h3>
                            <h3 className="text-thin mr-2 text-white" style={{ fontWeight: 400 }}>
                              {`${momentDate.tz(timezone).format('MMM').toUpperCase()} ${momentDate.tz(timezone).format('D')}`}
                            </h3>
                            <h3 className="text-thin mr-2 text-white" style={{ fontWeight: 400 }}>
                              {`${momentDate.tz(timezone).format('h:mm A')}${Boolean(momentEndDate) ? ` – ${momentEndDate.tz(timezone).format('h:mm A')}` : ''}`}
                            </h3>
                          </div>
                        )}
                        <div className="mb-4">
                          <h1 className="bm-Event_title mb-2 text-bold text-white">{name}</h1>
                          {Boolean(venue) && (
                            <h3 className="bm-Event__horizontalItemSubtext font-weight-light" style={{ fontWeight: 400 }}>
                              {venue.name}
                            </h3>
                          )}
                        </div>
                        {Boolean(showGuestlist && userItems.length) && (
                          <div className="d-flex mb-5 align-items-center">
                            <div className="d-flex align-items-center mr-3">
                              {shuffle(userItems).slice(0, 3).map((user: User, i: number) => {
                                const userImageUrl = get(user, 'images[0].url');

                                if (Boolean(userImageUrl)) {
                                  return (
                                    <Image
                                      key={i}
                                      src={userImageUrl}
                                      style={{
                                        zIndex: userItems.slice(0,3).length - i,
                                        height: 50,
                                        width: 50,
                                        borderRadius: 25,
                                        marginLeft: i === 0 ? 0 : -10,
                                        borderWidth: 2,
                                        borderStyle: 'solid',
                                        borderColor: '#000',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  );
                                }

                                return (
                                  <UserDefaultAvatar
                                    key={i}
                                    user={user}
                                    size={50}
                                    style={{
                                      zIndex: userItems.slice(0,3).length - i,
                                      height: 50,
                                      width: 50,
                                      borderRadius: 25,
                                      marginLeft: i === 0 ? 0 : -10,
                                      borderWidth: 2,
                                      borderStyle: 'solid',
                                      borderColor: '#000',
                                      objectFit: 'cover',
                                    }}
                                  />
                                );
                              })}
                              {Boolean(userItems.length && userItems.length > 3) && (
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{
                                    height: 45,
                                    width: 45,
                                    borderRadius: 22.5,
                                    backgroundColor: '#9966ff',
                                    // borderWidth: 2,
                                    // borderStyle: 'solid',
                                    // borderColor: '#fff',
                                    marginLeft: -10,
                                  }}
                                >
                                  <h6 className="text-white text-bold m-0">{`+${userTotal - 3}`}</h6>
                                </div>
                              )}
                            </div>
                            <h6 className="text-white m-0">
                              <span className="text-bold" style={{ marginLeft: -5 }}>Joined by <span className="text-capitalize text-bold">{get(userItems, '[0].name', '')}</span>{Boolean(userTotal > 1) ? ` and ${userTotal - 1} others!` : ''}</span>
                            </h6>
                          </div>
                        )}
                        {Boolean(creator) && (
                          <div className="d-flex align-items-center p-4 bg-overlay mb-5" style={{ width: '100%', borderRadius: 20 }}>
                            <Item
                              item={creator}
                              textColor="light"
                              wrap={false}
                              enableLink={true}
                              right={Boolean(get(creator, 'type') === 'community') && (
                                <JoinCommunityButton
                                  community={creator}
                                  showIcon={false}
                                  fontSize="1rem"
                                  width={90}
                                  inactiveBackgroundColor={Colors.white}
                                  inactiveTextColor={Colors.background}
                                  activeTextColor={Colors.white}
                                />
                              )}
                            />
                          </div>
                        )}
                        <div className="d-flex flex-wrap">
                          {Boolean(hasTicketOptions) && (
                            <div
                              className={`bm-Event__eventStatus ${Boolean(authorized || hasEventPassed || hasEventCancelled || hasEventInactive) ? 'd-flex' : 'd-none d-lg-flex'} flex-wrap bg-overlay align-items-center pt-4 pb-4 pl-4 pr-4`}
                              style={{ width: '100%', borderRadius: 20 }}
                            >
                              {Boolean(hasEventPassed || hasEventCancelled || hasEventInactive) ? (
                                <>
                                  <div className="bm-Event__eventStatus_text">
                                    <h5 className="text-white text-bold m-0 p-0 mb-1">{`${Boolean(hasEventPassed) ? 'This event has passed' : Boolean(hasEventCancelled) ? 'This event has been cancelled' : Boolean(hasEventInactive) ? 'This event is inactive' : ''}`}</h5>
                                    <p className="m-0 p-0" style={{ color: Colors.inactiveTab }}>Check out more events from this organizer.</p>
                                  </div>
                                    {Boolean(creatorId && creatorId.startsWith('community')) && (
                                      <Link
                                        to={`/communities/${creatorId.replace('community:', '')}/events`}
                                        className="btn btn-secondary"
                                        style={{ borderRadius: 20 }}
                                      >
                                        View upcoming events
                                      </Link>
                                    )}
                                </>
                              ) : (
                                <div style={{ width: '100%' }}>
                                  <div className="bm-Event__eventStatus d-flex justify-content-between align-items-center flex-wrap" style={{ width: '100%' }}>
                                    <div className="bm-Event__eventStatus_text">
                                      <h3 className="text-white text-bold m-0 p-0 mb-1">{`${cheapestAvailableTicketPrice === 0 ? 'Free' : `From $${getFinalItemAmount(cheapestAvailableTicket, 1, DEFAULT_FEES)}`}`}</h3>
                                      <p className="m-0 p-0" style={{ color: Colors.inactiveTab }}>Upfront pricing. Pay no more; no less.</p>
                                    </div> 
                                    <BuyButton event={event} />
                                  </div>
                                  {Boolean(orderedItem) && (
                                    <div style={{ width: '100%' }}>
                                      <Divider spacing={4} />
                                      <Link
                                        to={`/tickets/${get(order, 'id', '').replace('order:', '')}`}
                                        style={{ textDecoration: 'none' }}
                                      >
                                        <MediaObject
                                          text={(
                                            <h5 className="text-white text-bold m-0 p-0">{`${get(currentUser, 'name', 'Congrats')}, you're going!`}</h5>
                                          )}
                                          image={(
                                            <Icon name={Icons.ticket2} className="bm-Icon--white" size={24} style={{ transform: 'rotate(90deg)' }} />
                                          )}
                                          imageSize={16}
                                          right={(
                                            <h5 className="m-0 p-0" style={{ color: Colors.inactiveTab }}>{`${orderedQuantity} x ${orderedTicketName}`}</h5>
                                          )}
                                          wrap={false}
                                        />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          {!Boolean(hasTicketOptions) && (
                            <div className={`bm-Event__eventStatus ${Boolean(authorized || hasEventPassed || hasEventCancelled || hasEventInactive) ? 'd-flex' : 'd-none d-lg-flex'} flex-wrap align-items-center ${Boolean(hasEventPassed || hasEventCancelled || hasEventInactive) ? 'bg-overlay pt-4 pb-4 pl-4 pr-4' : ''}`} style={{ width: '100%', borderRadius: 20 }}>
                              {Boolean(hasEventPassed || hasEventCancelled || hasEventInactive) ? (
                                <>
                                  <div className="bm-Event__eventStatus_text">
                                    <h3 className="text-white text-bold m-0 p-0 mb-1">{`${Boolean(hasEventPassed) ? 'This event has passed' : Boolean(hasEventCancelled) ? 'This event has been cancelled' : Boolean(hasEventInactive) ? 'This event is inactive' : ''}`}</h3>
                                    <p className="m-0 p-0" style={{ color: Colors.inactiveTab }}>Check out more events from this organizer.</p>
                                  </div>
                                    {Boolean(creatorId && creatorId.startsWith('community')) && (
                                      <Link
                                        to={`/communities/${creatorId.replace('community:', '')}/events`}
                                        className="btn btn-secondary"
                                        style={{ borderRadius: 20 }}
                                      >
                                        View upcoming events
                                      </Link>
                                    )}
                                </>
                              ) : (
                                <>
                                  {Boolean(externalUrl) && (
                                    <Button
                                      className="bm-Event__getTicketsButton bm-Event__getTicketsButton--authorized"
                                      type="button"
                                      variant="secondary"
                                      target="_blank"
                                      href={externalUrl}
                                      style={{
                                        width: 150,
                                        borderRadius: 30,
                                        fontWeight: 700,
                                        height: 150,
                                      }}
                                    >
                                      <p className="m-0 p-0" style={{ fontSize: '1.1rem' }}>Get tickets</p>
                                    </Button>
                                  )}
                                  <JoinEventButton
                                    event={event}
                                    width={150}
                                    showIcon={Boolean(event && event.added)}
                                    inactiveText="Join event"
                                    fontSize="1.1rem"
                                    style={{
                                      height: 150,
                                      width: 150,
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          )}
                          <div className="d-flex justify-content-center d-lg-none mt-5" style={{ width: '100%' }}>
                            <PostShareButtons text={`Want to go to ${name}? Join me on #Beatmatch!`} imageUrl={finalEventImageUrl} />
                          </div>
                        </div>
                        {Boolean(description && provider !== 'ticketmaster') && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="text-white mb-3 text-bold">Description</h3>
                              <p className="text-white" style={{ whiteSpace: 'pre-line' }}>{description}</p>
                            </div>
                          </>
                        )}
                        {Boolean(artistItems.length) && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="bm-Event__sectionHeader text-white mb-4 text-bold">Lineup</h3>
                              <div className="bm-Event__linupArtistsContainer d-flex" style={{ width: '100%' }}>
                                {artistItems.map((artist: any, i: number) => {
                                  const { name, images = [], provider, providerId } = artist;
                                  const slug = slugify(name);

                                  return (
                                    <div
                                      key={i}
                                      className={`d-flex flex-column align-items-center${
                                        i < artistItems.length - 1 ? ' mr-4' : ''
                                      }`}
                                    >
                                      <Link
                                        key={i}
                                        to={`/artists/${providerToProviderCode[provider] || 's'}/${providerId}/${slug}`}
                                        className="d-flex flex-column align-items-center mb-1"
                                      >
                                        <Image
                                          key={i}
                                          src={get(images, '[0].url')}
                                          className="mb-3"
                                          style={{
                                            height: 100,
                                            width: 100,
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                          }}
                                        />
                                        <h6 className="text-bold text-white text-center" style={{ width: '100%' }}>
                                          {name}
                                        </h6>
                                      </Link>
                                      <FollowArtistButton
                                        artist={artist}
                                        showIcon={false}
                                        fontSize="0.85rem"
                                        width={80}
                                        inactiveBackgroundColor={Colors.white}
                                        inactiveTextColor={Colors.background}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                        {Boolean(showGuestlist && userItems.length) && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="bm-Event__sectionHeader text-white mb-4 text-bold">Guestlist</h3>
                              <div className="bm-Event__horizontalContainer d-flex" style={{ width: '100%' }}>
                                {userItems.map((user: any, i: number) => {
                                  let { name, images = [] } = user;
                                  const userImageUrl = get(user, 'images[0].url');
                                  name = capitalizeFirstLetter((name || '').trim());

                                  return (
                                    <SubscribeButton
                                      key={i}
                                      header={(
                                        <div className="d-flex flex-column align-items-center">
                                          {Boolean(userItems && userItems.length) && (
                                            <div className="d-flex align-items-center mb-2">
                                              {uniqBy([user, ...shuffle(userItems)], 'id').slice(0, 3).map((user: User, i: number) => {
                                                const userImageUrl = get(user, 'images[0].url');

                                                if (Boolean(userImageUrl)) {
                                                  return (
                                                    <Image
                                                      key={i}
                                                      src={userImageUrl}
                                                      style={{
                                                        zIndex: userItems.slice(0,3).length - i,
                                                        height: 100,
                                                        width: 100,
                                                        borderRadius: 50,
                                                        marginLeft: i === 0 ? 0 : -20,
                                                        borderWidth: 4,
                                                        borderStyle: 'solid',
                                                        borderColor: '#FFF',
                                                        objectFit: 'cover',
                                                      }}
                                                    />
                                                  );
                                                }

                                                return (
                                                  <UserDefaultAvatar
                                                    key={i}
                                                    user={user}
                                                    size={100}
                                                    style={{
                                                      zIndex: userItems.slice(0,3).length - i,
                                                      height: 100,
                                                      width: 100,
                                                      borderRadius: 50,
                                                      marginLeft: i === 0 ? 0 : -20,
                                                      borderWidth: 4,
                                                      borderStyle: 'solid',
                                                      borderColor: '#FFF',
                                                      objectFit: 'cover',
                                                    }}
                                                  />
                                                );
                                              })}
                                            </div>
                                          )}
                                          <h1 className="text-center mb-3">Meet {name} and everyone going on Beatmatch!</h1>
                                        </div>
                                      )}
                                      render={({ onClick }) => (
                                        <button
                                          key={i}
                                          onClick={onClick as any}
                                          className={`bm-Event__userButton d-flex flex-column align-items-center p-0 m-0${
                                            i < userItems.length - 1 ? ' mr-4' : ''
                                          }`}
                                          style={{
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                          }}
                                        >
                                          {(Boolean(userImageUrl)) ? (
                                            <Image
                                              key={i}
                                              className="mb-3"
                                              src={userImageUrl}
                                              style={{
                                                height: 100,
                                                width: 100,
                                                borderRadius: '50%',
                                                objectFit: 'cover',
                                              }}
                                            />
                                          ) : (
                                            <div className="mb-3">
                                              <UserDefaultAvatar
                                                key={i}
                                                user={user}
                                                size={100}
                                                style={{
                                                  height: 100,
                                                  width: 100,
                                                  borderRadius: '50%',
                                                  objectFit: 'cover',
                                                }}
                                              />
                                            </div>
                                          )}
                                          <h6 className="text-bold text-white text-center" style={{ width: '100%' }}>
                                            {name}
                                          </h6>
                                        </button>
                                      )}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                        {Boolean(trackItems && trackItems.length) && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="bm-Event__sectionHeader text-bold mb-4 text-white">Vibe check</h3>
                              {trackItems.slice(0,4).map((track: any, i: number) => {
                                if (i === 0) {
                                  return (
                                    <div key={i} className="bg-overlay mb-4 p-4" style={{ borderRadius: 20 }}>
                                      <Track track={track} imageSize={150} textColor="light" wrap={false} />
                                    </div>
                                  );
                                }

                                return (
                                  <div className={i === trackItems.length - 1 ? '' : 'mb-3'}>
                                    <Track track={track} textColor="light" wrap={false} />
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                        {Boolean(venueItem) && (
                          <>
                            {divider}
                            <div className="container mb-4">
                              <h3 className="bm-Event__sectionHeader text-bold mb-4 text-white">Venue</h3>
                              {Boolean(venueItem.id) ? (
                                <div className="mb-4">
                                  <MediaObject
                                    text={(
                                      <>
                                        {Boolean(venueItem.id) ? (
                                          <Link
                                            to={`/venues/${get(venueItem, 'id', '').replace('venue:', '')}/${slugify(get(venueItem, 'name', ''))}`}
                                            className="bm-Event__horizontalItemSubtext"
                                          >
                                            {venueContent}
                                          </Link>
                                        ) : venueContent}
                                      </>
                                    )}
                                    textColor="light"
                                    subtext={Boolean(addressFull) ? addressFull : `${city || ''}, ${state || ''} ${zipCode || ''}`}
                                    showImage={false}
                                    wrap={false}
                                    image={(
                                      <Link
                                        to={`/venues/${get(venueItem, 'id', '').replace('venue:', '')}/${slugify(get(venueItem, 'name', ''))}`}
                                      >
                                        {Boolean(get(venueItem, 'images[0].url')) ? (
                                          <Image
                                            src={get(venueItem, 'images[0].url')}
                                            style={{ width: 125, aspectRatio: '1', borderRadius: 10, objectFit: 'cover' }}
                                          />
                                        ) : (
                                          <div className="d-flex justify-content-center align-items-center bg-secondaryButton" style={{ width: 125, aspectRatio: '1', borderRadius: 10 }}>
                                            <Icon name={Icons.glassCocktail} className="bm-Icon--primary" size={50} />
                                          </div>
                                        )}
                                      </Link>
                                    )}
                                    right={(
                                      // <Button
                                      //   className="bm-Event__button text-black mr-3 align-items-center"
                                      //   variant="secondary"
                                      //   href={window.encodeURI(`https://maps.google.com/?q=${venueItem.name} ${line1 || ''} ${line2 || ''} ${city}, ${state} ${zipCode || ''}`)}
                                      //   target="_blank"
                                      //   style={{ borderRadius: 30, fontWeight: 700, height: 45 }}
                                      // >
                                      //   <Pin className="mr-2" width={18} height={18} fill="#212529" />
                                      //   <h6 className="m-0 p-0" style={{ color: '#212529' }}>Open in maps</h6>
                                      // </Button>
                                      <Link
                                        to={`/venues/${get(venueItem, 'id', '').replace('venue:', '')}/${slugify(get(venueItem, 'name', ''))}`}
                                      >
                                        {Boolean(get(venueItem, 'images[0].url')) ? (
                                          <Image
                                            src={get(venueItem, 'images[0].url')}
                                            style={{ width: 100, aspectRatio: '1', borderRadius: 10, objectFit: 'cover' }}
                                          />
                                        ) : (
                                          <div className="d-flex justify-content-center align-items-center bg-secondaryButton" style={{ width: 125, aspectRatio: '1', borderRadius: 10 }}>
                                            <Icon name={Icons.glassCocktail} className="bm-Icon--primary" size={50} />
                                          </div>
                                        )}
                                      </Link>
                                    )}
                                  />
                                </div>
                              ) : (
                                <div className="d-flex justify-content-between flex-wrap" style={{ width: '100%' }}>
                                  <div>
                                    {Boolean(venueItem.name) && (
                                      <h5 className="text-white">
                                        {venueItem.name}
                                      </h5>
                                    )}
                                    {Boolean(venueItem.addressFull) ? (
                                      <p className="text-white">
                                        {venueItem.addressFull}
                                      </p>
                                    ) : (
                                      <>
                                        {line1 && (
                                          <p className="text-white mb-1">
                                            {line1}
                                          </p>
                                        )}
                                        {line2 && (
                                          <p className="text-white mb-1">
                                            {line2}
                                          </p>
                                        )}
                                        {city && (
                                          <div className="d-flex align-items-center">
                                            <p className="text-white">
                                              {city}, {state} {zipCode}
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <Button
                                    className="bm-Event__button text-black mr-3 align-items-center"
                                    variant="secondary"
                                    href={window.encodeURI(`https://maps.google.com/?q=${venueItem.name} ${line1 || ''} ${line2 || ''} ${city}, ${state} ${zipCode || ''}`)}
                                    target="_blank"
                                    style={{ borderRadius: 30, fontWeight: 700, height: 45 }}
                                  >
                                    <Pin className="mr-2" width={18} height={18} fill="#212529" />
                                    <h6 className="m-0 p-0" style={{ color: '#212529' }}>Open in maps</h6>
                                  </Button>
                                </div>
                              )}
                            </div>
                            {Boolean(venueLatitude && venueLongitude) && (
                              <Map
                                mapId="c68d694374038063"
                                style={{
                                  height: 300,
                                  width: '100%',
                                  borderRadius: 20,
                                  overflow: 'hidden',
                                }}
                                defaultCenter={{
                                  lat: venueLatitude,
                                  lng: venueLongitude,
                                }}
                                defaultZoom={12}
                                gestureHandling={'greedy'}
                                disableDefaultUI={true}
                              >
                                <AdvancedMarker
                                  position={{
                                    lat: venueLatitude,
                                    lng: venueLongitude,
                                  }}
                                >
                                  <PinMarker
                                    background={Colors.primary}
                                    borderColor={Colors.darkPurple}
                                    glyphColor={Colors.darkPurple}
                                  />
                                </AdvancedMarker>
                              </Map>
                            )}
                          </>
                        )}
                        {Boolean(activityItems.length) && (
                          <>
                            {divider}
                            <div className="container mb-4">
                              <h3 className="bm-Event__sectionHeader text-white mb-4 text-bold">Upcoming plans</h3>
                              <div className="bm-Event__linupArtistsContainer d-flex" style={{ width: '100%' }}>
                                {activityItems.filter(({ images = [] }) => Boolean(images.length)).map(({ id, name, images = [], date, location, participants, requests }: any, i: number) => {
                                  const momentDate = moment(date);
                                  const today = moment();
                                  const tomorrow = moment().add(1, 'day');
                                  const isToday = momentDate.isSame(today, 'day');
                                  const isTomorrow = momentDate.isSame(tomorrow, 'day');
                                  const city = get(location, 'city.name');
                                  const state = get(location, 'state.code');
                                  const participantItems = get(participants, 'items', []);
                                  const participantTotal = get(participants, 'total', participantItems.length);
                                  const requestItems = get(requests, 'items', []);
                                  const requestTotal = get(requests, 'total', requestItems.length);
                                  const totalUsersLength = participantTotal + requestTotal;

                                  return (
                                    <div
                                      key={i}
                                      className={`d-flex flex-column ${
                                        i < relatedEventItems.length - 1 ? ' mr-4' : ''
                                      }`}
                                      style={{ width: '20%', minWidth: 200 }}
                                    >
                                      <Link
                                        to={`/plans/${id.replace('activity:', '')}`}
                                      >
                                        <Image
                                          key={i}
                                          src={get(images, '[0].url')}
                                          className="mb-3"
                                          style={{
                                            height: undefined,
                                            width: '100%',
                                            aspectRatio: '1/1',
                                            borderRadius: 20,
                                            objectFit: 'cover',
                                          }}
                                        />
                                      </Link>
                                      <div style={{ width: '100%' }}>
                                        <div className="d-flex mb-1">
                                          {(isToday || isTomorrow) && (
                                            <>
                                              <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                                                {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.tz(timezone).format('ddd').toUpperCase()}
                                              </h6>
                                              <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                                              {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()}
                                              </h6>
                                            </>
                                          )}
                                          {!isToday && !isTomorrow && (
                                            <>
                                              <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                                                {momentDate.tz(timezone).format('ddd').toUpperCase()}
                                              </h6>
                                              <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                                                {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()} -{' '}
                                                {momentDate.tz(timezone).format('h:mm A')}
                                              </h6> 
                                            </>
                                          )}
                                        </div>
                                        <Link
                                          to={`/plans/${id.replace('activity:', '')}`}
                                        >
                                          <h5 className="text-bold text-white mb-1" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</h5>
                                        </Link>
                                        {Boolean(totalUsersLength) && <h5 className="bm-Artist__events__subtext">{totalUsersLength} {`${totalUsersLength === 1 ? 'member' : 'members'}`}</h5>}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                        {Boolean(relatedEventItems.length) && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="bm-Event__sectionHeader text-white mb-4 text-bold">More like this</h3>
                              <div className="bm-Event__linupArtistsContainer d-flex" style={{ width: '100%' }}>
                                {relatedEventItems.filter(({ images = [] }) => Boolean(images.length)).map((event: any, i: number) => {
                                  const {
                                    name, images = [], date,
                                    startDate, endDate, venue,
                                    provider, providerId,
                                  } = event;

                                  const momentDate = moment(startDate || date);
                                  const timezone = get(event, 'timezone', 'America/Los_Angeles');
                                  const today = moment();
                                  const tomorrow = moment().add(1, 'day');
                                  const isToday = momentDate.isSame(today, 'day');
                                  const isTomorrow = momentDate.isSame(tomorrow, 'day');
                                  const slug = slugify(name);
                                  const venueContent = (
                                    <h6
                                      className="bm-Event__horizontalItemSubtext"
                                      style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                    >
                                      {(venue as any).name}
                                    </h6>
                                  );

                                  return (
                                    <div
                                      key={i}
                                      className={`d-flex flex-column ${
                                        i < relatedEventItems.length - 1 ? ' mr-4' : ''
                                      }`}
                                      style={{ width: '20%', minWidth: 200 }}
                                    >
                                      <Link
                                        to={`/events/${eventProviderToProviderCode[provider]}/${providerId}/${slug}`}
                                      >
                                        <Image
                                          key={i}
                                          src={get(images, '[0].url')}
                                          className="mb-3"
                                          style={{
                                            height: undefined,
                                            width: '100%',
                                            aspectRatio: '1/1',
                                            borderRadius: 20,
                                            objectFit: 'cover',
                                          }}
                                        />
                                      </Link>
                                      <div style={{ width: '100%' }}>
                                        <div className="d-flex mb-1">
                                          {(isToday || isTomorrow) && (
                                            <>
                                              <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                                                {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.tz(timezone).format('ddd').toUpperCase()}
                                              </h6>
                                              <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                                              {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()}
                                              </h6>
                                            </>
                                          )}
                                          {!isToday && !isTomorrow && (
                                            <>
                                              <h6 className="text-bold text-primary mr-2" style={{ marginBottom: 0 }}>
                                                {momentDate.tz(timezone).format('ddd').toUpperCase()}
                                              </h6>
                                              <h6 className="text-bold text-white" style={{ marginBottom: 0 }}>
                                                {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()} -{' '}
                                                {momentDate.tz(timezone).format('h:mm A')}
                                              </h6> 
                                            </>
                                          )}
                                        </div>
                                        <Link
                                          to={`/events/${providerCode}/${providerId}/${slug}`}
                                        >
                                          <h6 className="text-bold text-white mb-1" style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</h6>
                                        </Link>
                                        {Boolean(venue) && (
                                          <>
                                            {Boolean(venue.id) ? (
                                              <Link
                                                to={`/venues/${get(venue, 'id', '').replace('venue:', '')}/${slugify(get(venue, 'name', ''))}`}
                                                className="bm-Event__horizontalItemSubtext"
                                              >
                                                {venueContent}
                                              </Link>
                                            ) : venueContent}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                        {Boolean(creator) && (
                          <>
                            {divider}
                            <div className="container">
                              <h3 className="bm-Event__sectionHeader text-white mb-4 text-bold">Hosted by</h3>
                              <div className="bm-Event__horizontalContainer d-flex" style={{ width: '100%' }}>
                                <div className="d-flex align-items-center p-4 bg-overlay" style={{ width: '100%', borderRadius: 20 }}>
                                  <Item
                                    item={creator}
                                    textColor="light"
                                    wrap={false}
                                    enableLink={true}
                                    right={Boolean(get(creator, 'type') === 'community') && (
                                      <JoinCommunityButton
                                        community={creator}
                                        showIcon={false}
                                        fontSize="1rem"
                                        width={90}
                                        inactiveBackgroundColor={Colors.white}
                                        inactiveTextColor={Colors.background}
                                        activeTextColor={Colors.white}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {divider}
                        <div className="container">
                          <h3 className="bm-Event__sectionHeader text-white text-bold mb-4">Download the Beatmatch app</h3>
                          <p className="bm-Text--secondaryText mb-4">
                            {APP_PROMO_DESCRIPTION}
                          </p>
                          <div className="bm-Event__downloadButtonsContainer mb-4 flex-wrap" style={{ width: '100%' }}>
                            <div style={{ flex: 1 }}>
                              <SubscribeButton variant="secondary" color="#212529" text="Get the app">
                                <h6 className="pt-1">Get the app</h6>
                              </SubscribeButton>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
              {!Boolean(authorized || hasEventPassed || hasEventCancelled || hasEventInactive) && (
                <nav
                  className="d-lg-none fixed-bottom pt-1 pb-1"
                  style={{ backgroundColor: 'rgba(0,0,0,1)', borderTop: '0.25px solid rgba(255,255,255,0.25)' }}
                >
                  <div className="container d-flex justify-content-center" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                      <div
                        className="d-flex justify-content-between align-items-center p-4"
                        style={{ width: '100%', borderRadius: 20, flexWrap: 'nowrap' }}
                      >
                        <>
                          {Boolean(hasTicketOptions) && (
                            <>
                              <div>
                                <h3 className="text-white text-bold m-0 p-0">{`${cheapestAvailableTicketPrice === 0 ? 'Free' : `From $${getFinalItemAmount(cheapestAvailableTicket, 1, DEFAULT_FEES)}`}`}</h3>
                                <p className="m-0 p-0" style={{ color: Colors.inactiveTab }}>Upfront pricing. Pay no more; no less.</p>
                              </div>
                              <BuyButton event={event} />
                            </>
                          )}
                          {!Boolean(hasTicketOptions) && (
                            <>
                              {Boolean(externalUrl) && (
                                <Button
                                  className="bm-Event__getTicketsButton"
                                  type="button"
                                  variant="secondary"
                                  target="_blank"
                                  href={externalUrl}
                                  style={{
                                    width: '100%',
                                    borderRadius: 30,
                                    fontWeight: 700,
                                    height: 150,
                                  }}
                                >
                                  <h5 className="pt-1">Get tickets</h5>
                                </Button>
                              )}
                              <JoinEventButton
                                event={event}
                                showIcon={Boolean(event && event.added)}
                                inactiveText="Join event"
                                style={{
                                  height: 150,
                                }}
                              />
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </nav>
              )}
              {Boolean(authorized) && (
                <nav
                  className="fixed-bottom pt-4 pb-4"
                  style={{ backgroundColor: 'rgba(0,0,0,1)', borderTop: '0.25px solid rgba(255,255,255,0.25)' }}
                >
                  <div className="container d-flex justify-content-center" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                      <Link
                        to={`/event/${providerCode}/${eventId}/manage/insights`}
                        className="mr-3"
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          variant="secondary"
                          style={{
                            width: '100%',
                            borderRadius: 30,
                            fontWeight: 700,
                            height: 125,
                            backgroundColor: Colors.transparent,
                            borderColor: Colors.white,
                            borderWidth: 0.25,
                            minWidth: 175,
                          }}
                        >
                          <h6 className="m-0 p-0 text-bold text-white">View insights</h6>
                        </Button>
                      </Link>
                      <Link
                        to={`/event/${providerCode}/${eventId}/manage`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          className="bm-CreateEvent__submitButton"
                          variant="secondary"
                          style={{
                            borderRadius: 30,
                            fontWeight: 700,
                            height: 125,
                            color: Colors.background,
                            minWidth: 175,
                          }}
                        >
                          <h6 className="m-0 p-0 text-bold">Manage event</h6>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </nav>
              )}
              <Footer />
            </div>
          </div>
        )}
        {/* {!loading && !event && <Redirect to="/not-found" />} */}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState, { match: { params } }: InjectedProps) => {
  const { eventId, providerCode } = params;
  const provider = providerCodeToProvider[providerCode];

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, `event:${provider}:${eventId}`),
  };
};

export default connect(mapStateToProps)(Event);
