/* External dependencies */
import * as React from 'react';

type OwnProps = {
  spacing?: number;
  borderBottom?: string;
};

type Props = OwnProps;

const Divider: React.FC<Props> = ({ spacing = 5, borderBottom = '0.5px solid rgba(255,255,255,0.4)' }) => <div className={`d-flex mt-${spacing} mb-${spacing}`} style={{ width: '100%', borderBottom }} />;

export default Divider;
