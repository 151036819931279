/* External dependencies */
import React, { useCallback, useEffect } from 'react';
import { Dispatch } from 'redux';
import { Accordion, Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import get from 'lodash/get';

/* Internal dependencies */
import Event from 'src/event/Event';
import { ApplicationState } from 'src/store';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import { getEvent } from 'src/store/ducks/events';
import Icon, { Icons } from 'src/icon/Icon';
import Colors from 'src/colors';
import MediaObject from 'src/mediaObject/MediaObject';
import CheckoutButton from './CheckoutButton';
import './CheckoutTicket.scss';
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';
import { DEFAULT_FEES, getFinalItemAmount } from 'src/store/helpers/tickets';
import { isTicketAvailable } from 'src/store/helpers/events';
import Analytics, { AnalyticsEventType } from 'src/analytics/Analytics';
import Promotion, { DiscountReward, PromotionRewardType } from 'src/types/Promotion';
import { cleanObject } from 'src/common/cleanObject';

type OwnProps = {
  event: any;
  incrementStepIndex(): void;
  jumpStepIndex(index: number): void;
  quantity: number;
  onQuantityChange(quantity: number): void;
  activeTicketIndex: string;
  onActiveTicketIndexChange(activeTicketIndex: string): void;
  activePromotion?: Promotion;
  clearActivePromotion(): void;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & StateProps & DispatchProps;

const CheckoutTicket: React.FC<Props> = ({
  currentUser,
  event, incrementStepIndex, jumpStepIndex, onQuantityChange,
  quantity, activeTicketIndex, onActiveTicketIndexChange,
  addNotification,
  activePromotion, clearActivePromotion,
}) => {
  const ticketItems = get(event, 'tickets.items', []);
    // Reward
    const activeReward = get(activePromotion, 'rewards.items[0]');
    const rewardType = get(activeReward, 'rewardType');
    const hasDiscount = rewardType === PromotionRewardType.discount;
    const rewardDiscount = get(activeReward, 'payload') as DiscountReward;

  useEffect(() => {
    Analytics.track(AnalyticsEventType.checkoutStepViewed, { step: 0 });

    return () => {
      Analytics.track(AnalyticsEventType.checkoutStepCompleted, { step: 0 });
    };
  }, []);

  const incrementTicketQuantity = useCallback(() => {
    onQuantityChange(quantity + 1);
  }, [quantity]);

  const decrementTicketQuantity = useCallback(() => {
    onQuantityChange(quantity - 1);
  }, [quantity]);

  const handleKeySelect = useCallback(([key]: any) => {
    const ticket = ticketItems[Number.parseInt(key)];
    const ticketAvailable = isTicketAvailable(ticket);
    const minPerOrder = get(ticket, 'minPerOrder', 1);

    if (!ticket || !ticketAvailable) {
      addNotification({
        title: 'Ticket not available',
        message: 'This ticket is not available.',
        variant: 'warning',
      });
      return
    }

    onQuantityChange && onQuantityChange(minPerOrder);
    Boolean(key) && onActiveTicketIndexChange(key);
  }, [onActiveTicketIndexChange, addNotification, ticketItems, event]);

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="bm-CheckoutTicket__tickets d-flex flex-column align-items-center" style={{ width: '100%' }}>
        <div className="d-flex flex-column align-items-center mb-5" style={{ width: 'auto', maxWidth: '100%', overflow: 'hidden' }}>
          <Event className="bm-CheckoutTicket__event--mobile" event={event} textSize="1.1rem" textColor="light" wrap={false} imageSize={100} style={{ maxWidth: '100%' }} />
        </div>
        {Boolean(activePromotion) && (
          <div className="d-flex justify-content-center mb-4" style={{ width: '100%' }}>
            <div
              className="bm-CheckoutTicket__discount d-flex align-items-center align-self-start bg-success pl-4 pr-4 pt-2 pb-2"
              style={{ borderRadius: 10 }}
            >
              <div className="d-flex align-items-center mr-3" style={{ flex: 1 }}>
                <Icon name={Icons.tag2} className="bm-Icon--white mr-3" size={20} solid />
                <div>
                  <h6 className="text-bold text-white m-0 p-0 mb-1">{get(activePromotion, 'promoCode')}</h6>
                  <h6 className="m-0 p-0" style={{ color: Colors.inactiveTab }}>Applied</h6>
                </div>
              </div>
              <button
                className="btn"
                onClick={clearActivePromotion}
              >
                <Icon name={Icons.cross} className="bm-Icon--white" size={20} />
              </button>
            </div>
          </div>
        )}
        <Accordion className="bm-CheckoutTicket__accordion" defaultActiveKey={activeTicketIndex} activeKey={activeTicketIndex} onSelect={handleKeySelect} alwaysOpen={true}>
          {ticketItems.map((ticket: any, i: number) => {
            const { id, name, description, priceValue = 0, status } = ticket;
            const minPerOrder = get(ticket, 'minPerOrder', 1);
            const maxPerOrder = get(ticket, 'maxPerOrder', 4);
            let badgeBg;
            let badgeText;

            if (Boolean(status === 'postSale')) {
              badgeBg = 'dark';
              badgeText = 'Sale ended';
            } else if (Boolean(status === 'offSale')) {
              badgeBg = 'info';
              badgeText = 'Scheduled';
            } else if (Boolean(status === 'soldOut')) {
              badgeBg = 'danger';
              badgeText = 'Sold Out';
            }

            return (
              <Accordion.Item className="bm-CheckoutTicket__accordionItem" key={id} eventKey={`${i}`}>
                <Accordion.Header as="div">
                  <MediaObject
                    text={(
                      <div className="d-flex align-items-center">
                        <h6
                          className={`text-left m-0 p-0 mb-1 mr-2 ${Boolean(status === 'postSale' || status === 'soldOut') ? 'bm-Text--strikeText' : ''}`}
                          style={{ color: Boolean(status === 'postSale' || status === 'soldOut') ? '#808080' : '#212529', textDecorationThickness: 2 }}
                        >
                          {name}
                        </h6>
                        {Boolean(badgeBg && badgeText) && (
                          <Badge
                            // pill
                            bg={badgeBg}
                            className="text-white pl-2 pr-2 pt-2 pb-2"
                          >
                            {badgeText}
                          </Badge>
                        )}
                      </div>
                    )}
                    subtext={(
                      <div className="d-flex align-items-center">
                        <h3
                          className={`text-left text-bold m-0 p-0 ${Boolean(status === 'postSale' || status === 'soldOut') ? 'bm-Text--strikeText' : ''}`}
                          style={{ color: Boolean(status === 'postSale' || status === 'soldOut') ? '#808080' : '#212529', textDecorationThickness: 2 }}
                        >
                          {Boolean(priceValue === 0) ? 'Free' : `$${getFinalItemAmount(ticket, 1, DEFAULT_FEES, rewardDiscount).toFixed(2)}`}
                        </h3>
                        {Boolean(hasDiscount) && (
                          <div className="d-flex align-items-center ml-2">
                            <h5
                              className="text-left text-success m-0 p-0"
                              style={cleanObject({
                                textDecorationLine: 'line-through',
                                textDecorationStyle: 'solid',
                              })}
                            >
                              {Boolean(priceValue === 0) ? 'Free' : `$${getFinalItemAmount(ticket, 1, DEFAULT_FEES).toFixed(2)}`}
                            </h5>
                            {/* <AppText
                              type="bold"
                              size="h5"
                              color={Colors.success}
                              style={cleanObject({
                                textAlign: 'left',
                                marginLeft: HorizontalSpacing.xs,
                              })}
                            >
                              {`(${Boolean(rewardDiscountType === DiscountType.amount) ? '$' : ''}${rewardValue}${Boolean(rewardDiscountType === DiscountType.percentage) ? '%' : ''} off)`}
                            </AppText> */}
                          </div>
                        )}
                      </div>
                    )}
                    image={(
                      <Icon name={Icons.ticket} size={30} />
                    )}
                    imageSize={30}
                    wrap={false}
                  />
                </Accordion.Header>
                <Accordion.Body>
                  <p className="mb-3" style={{ color: Colors.background }}>{description}</p>
                  <div className="bm-CheckoutTicket__purchaseOptions p-4">
                    <div className="d-flex align-items-center mb-2">
                      <button disabled={Boolean(quantity <= minPerOrder)} className="btn" onClick={decrementTicketQuantity}><h2>–</h2></button>
                      <h1 className="text-center lead ml-2 mr-2" style={{ fontSize: 38, width: 75 }}>{quantity}</h1>
                      <button disabled={Boolean(quantity >= maxPerOrder)} className="btn" onClick={incrementTicketQuantity}><h2>+</h2></button>
                    </div>
                    <CheckoutButton
                      currentUser={currentUser}
                      event={event}
                      ticket={ticket}
                      quantity={quantity}
                      incrementStepIndex={incrementStepIndex}
                      jumpStepIndex={jumpStepIndex}
                      activePromotion={activePromotion}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState, { event }: OwnProps) => {
  const eventId = get(event, 'id');

  return {
    currentUser: getCurrentUser(state),
    event: getEvent(state, eventId) || event,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTicket);