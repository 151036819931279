import React from 'react';

export type SectionListData<ItemT, SectionT = {}> = {
  title: string;
  data: ItemT[];
  extraData?: SectionT;
}

export type SectionListRenderItem<ItemT, SectionT = {}> = (info: {
  item: ItemT;
  index: number;
  section: SectionListData<ItemT, SectionT>;
}) => React.ReactElement | null;

type SectionListProps<ItemT, SectionT = {}> = {
  sections: SectionListData<ItemT, SectionT>[];
  renderItem?: SectionListRenderItem<ItemT, SectionT>;
  renderSectionHeader?:
    | ((info: { section: SectionListData<ItemT, SectionT> }) => React.ReactElement | null)
    | undefined;
  renderSectionFooter?:
    | ((info: { section: SectionListData<ItemT, SectionT> }) => React.ReactElement | null)
    | undefined;
  keyExtractor?: (item: ItemT, index: number) => string;
  style?: React.CSSProperties;
  listHeaderComponent?: React.ReactNode;
  listFooterComponent?: React.ReactNode;
  className?: string;
}

const SectionList = <ItemT, SectionT = {}>({
  className,
  sections,
  renderItem,
  renderSectionHeader,
  renderSectionFooter,
  keyExtractor = (_, index) => `${index}`, // Default keyExtractor,
  style,
  listHeaderComponent,
  listFooterComponent,
}: SectionListProps<ItemT, SectionT>) => {
  return (
    <div className={className} style={{ ...style, overflowY: 'auto' }}>
      {listHeaderComponent && <div>{listHeaderComponent}</div>}

      {sections.map((section, sectionIndex) => (
        <div key={`section-${sectionIndex}`}>
          {renderSectionHeader && (
            <div style={{ fontWeight: 'bold', margin: '10px 0' }}>
              {renderSectionHeader({ section })}
            </div>
          )}

          {section.data.map((item, index) => (
            <div key={keyExtractor(item, index)} style={{ marginBottom: '5px' }}>
              {renderItem && renderItem({ item, index, section })}
            </div>
          ))}

          {renderSectionFooter && (
            <div style={{ marginTop: '10px' }}>
              {renderSectionFooter({ section })}
            </div>
          )}
        </div>
      ))}

      {listFooterComponent && <div>{listFooterComponent}</div>}
    </div>
  );
};

export default SectionList;
