/* Internal dependencies */
import Community from './Community';
import { PaginationResponse } from './Pagination';
import User, { Image } from './User';

export enum PromotionRewardType {
  points = 'points',
  discount = 'discount',
  unlockItem = 'unlockItem', // (i.e. unlock ticket)
}

export enum DiscountType {
  amount = 'amount',
  percentage = 'percentage',
}

export type DiscountReward = {
  discountType: DiscountType | string;
  value: number;
};

type UnlockItemReward = {
  itemId: string;
  itemType: string;
};

export type PromotionRewardPayload = DiscountReward | UnlockItemReward;

type PromotionReward = {
  id: string;
  name: string;
  description?: string;
  type: 'promotionReward' | string;
  rewardType?: PromotionRewardType | string;
  payload: PromotionRewardPayload;
};

type PromotionCondition = {
  id: string;
  name: string;
  description: string;
  type: 'promotionCondition' | string;
  /**
   * The type of condition for an achievement.
   */
  // conditionType?: PromotionConditionType;
  // action: PromotionConditionAction;
  // actionPayload?: PromotionConditionActionPayload;
  /**
   * The minium number for this condition to be met.
   */
  target: number;
};

type Promotion = {
  id: string;

  name: string;

  description?: string;

  type: 'promotion';

  promoCode: string;

  images: Image[];

  provider: 'beatmatch';

  providerId: string;

  // Zero capacity means unlimited or infinite
  capacity: number;

  date: Date;

  startDate?: Date; // alias for "date". Optional until we backfill every existing event in the database with this attribute

  endDate?: Date;

  creatorId?: string;

  creators: PaginationResponse<User | Community>;

  /**
   * The rewards that a user receives for redeeming the promotion (i.e. 20% discount, unlocked tickets, etc.).
   */
  rewards: PaginationResponse<Partial<PromotionReward>>;

  /**
   * The conditions that must be met in order to redeem the promotion (i.e. first-time purchase, etc.).
   */
  conditions: PaginationResponse<Partial<PromotionCondition>>;

  /**
   * The users who have either been invited to use the promo code, redeemed the promo code, or applied the reward from the code. The "status" of the participant denotes the respective state.
   */
  participants: PaginationResponse<User>;
};

export default Promotion;