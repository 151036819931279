import React from 'react';

type OwnProps = {
  delay: number;
  onSingleTap: (e: MouseEvent) => void;
  onDoubleTap: (e: MouseEvent) => void;
  children: React.ReactNode;
  style?: any;
};

type Props = OwnProps;

export default class DoubleTap extends React.Component<Props> {
  lastTap: number | undefined;
  firstTap: number | undefined;
  secondTap: number | undefined;

  static defaultProps: Props = {
    delay: 250,
    onSingleTap: () => null,
    onDoubleTap: () => null,
    children: null,
  };

  handleSingleTap = (e: MouseEvent) => {
    const { onSingleTap } = this.props;
    onSingleTap && onSingleTap(e);
  };

  handleDoubleleTap = (e: MouseEvent) => {
    const { onDoubleTap } = this.props;
    onDoubleTap && onDoubleTap(e);
  };

  handleTap = (e: MouseEvent) => {
    const { delay } = this.props;
    const now = Date.now();

    if (!this.firstTap) {
      this.firstTap = now;
    } else if (!this.secondTap) {
      this.secondTap = now;
    }

    setTimeout(() => {
      if (this.firstTap && this.secondTap) {
        this.handleDoubleleTap(e);
      } else if (this.firstTap && !this.secondTap) {
        this.handleSingleTap(e);
      }

      this.firstTap = undefined;
      this.secondTap = undefined;
    }, delay);
  };

  render() {
    return (
      <button
        className="btn m-0 p-0"
        onClick={this.handleTap as any}
        style={{
          ...this.props.style,
          maxWidth: '100%',
          maxHeight: 'none',
        }}
      >
        {this.props.children}
      </button>
    );
  }
}
