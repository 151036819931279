/* External dependencies */
import get from 'lodash/get';
import { DiscountReward, DiscountType } from 'src/types/Promotion';

export enum FeeType {
  orderValue = 'orderValue',
  perItem = 'perItem',
}

export type Fee = {
  type: FeeType;
  value: number;
};

export const DEFAULT_FEES = [
  {
    type: FeeType.orderValue,
    value: 0.1,
  },
  {
    type: FeeType.perItem,
    value: 0.99,
  },
];

export const getApplicationFee = (item: any, quantity: number, fees: Fee[]): number => {
  const itemPrice = get(item, 'priceValue', 0);
  const amount = (itemPrice * quantity);
  const orderValueFees = fees.filter(({ type }) => type === FeeType.orderValue);
  const perItemFees = fees.filter(({ type }) => type === FeeType.perItem);
  const totalOrderValueFee: number = orderValueFees.reduce((acc, { value }) => {
    return acc + (amount * value);
  }, 0);
  const totalPerItemFee: number = perItemFees.reduce((acc, { value }) => {
    return acc + (quantity * value);
  }, 0);

  return parseFloat((totalOrderValueFee + totalPerItemFee).toFixed(2));
};

export const getDiscountAmount = (item: any, quantity: number, discount?: DiscountReward): number => {
  const itemPrice = get(item, 'priceValue', 0);
  let amount = (itemPrice * quantity);
  let discountAmount: number = 0;

  if (Boolean(discount)) {
    const discountType = get(discount, 'discountType');
    const discountValue = get(discount, 'value')!;
    const isPercentage = Boolean(discountType === DiscountType.percentage);
    const isAmount = Boolean(discountType === DiscountType.amount);

    if (discountValue) {
      if (isPercentage) {
        discountAmount = (amount * (discountValue / 100));
      } else if (isAmount) {
        discountAmount = discountValue;
      }
    }
  }

  return parseFloat(discountAmount.toFixed(2));
};

export const getFinalItemAmount = (item: any, quantity: number, fees: Fee[], discount?: DiscountReward): number => {
  const itemPrice = get(item, 'priceValue', 0);
  let amount = (itemPrice * quantity);
  const application_fee_amount = getApplicationFee(item, quantity, fees);

  if (Boolean(discount)) {
    const discountType = get(discount, 'discountType');
    const discountValue = get(discount, 'value')!;
    const isPercentage = Boolean(discountType === DiscountType.percentage);
    const isAmount = Boolean(discountType === DiscountType.amount);

    if (discountValue) {
      if (isPercentage) {
        amount = amount - (amount * (discountValue / 100));
      } else if (isAmount) {
        amount = amount - discountValue;
      }
    }
  }

  if (amount < 0) {
    amount = 0;
  }

  return parseFloat((amount + application_fee_amount).toFixed(2));
};

export const convertToAmountInPennies = (amount: number): number => {
  return parseFloat((amount * 100).toFixed(2));
};