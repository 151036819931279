/* External dependencies */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';

/* Internal dependencies */
import { segment } from './Analytics';

const RouteTracker: React.FC = () => {
  const location = useLocation();
  const pathname = get(location, 'pathname');
  const search = get(location, 'search');

  useEffect(() => {
    segment.page(); // Tracks the initial page load
  }, []);

  useEffect(() => {
    // Function to track page views using Segment
    const trackPageView = () => {
      const path = pathname + search;
      segment.page({
        path,
        title: document.title,
        url: window.location.href,
      });
      console.log(`Tracked page view: ${path}`); // Optional for debugging
    };

    trackPageView();
  }, [pathname, search]);

  return null; // This component does not render anything
};

export default RouteTracker;
