/* External dependencies */
import React, { FormEvent, MouseEventHandler, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Form, Spinner } from 'react-bootstrap';
import get from 'lodash/get';

/* Internal dependencies */
import DrawerButton from 'src/drawer/DrawerButton';
import MediaObject from 'src/mediaObject/MediaObject';
import { ApplicationState } from 'src/store';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import ModalButton from 'src/modal/ModalButton';
import SectionList from 'src/sectionList/SectionList';
import Colors from 'src/colors';
import Icon, { Icons } from 'src/icon/Icon';
import { getEvents } from 'src/store/ducks/events';
import { getPostsFeedItems, setPostsFeedItems } from 'src/store/ducks/postsFeedItems';
import Item from 'src/item/Item';
import { getEvent } from 'src/api/events';
import { getRecommendations, getSpotifyOrAppleMusicMyRecentlyPlayedTracks } from 'src/api/spotify';
import './CreateFeedPostDrawerButton.scss';
import { addFeedPostForUser } from 'src/api/feedPosts';
import { cleanObject } from 'src/common/cleanObject';
import { addNotification } from 'src/store/ducks/notifications';
import { updatePost } from 'src/store/ducks/posts';
import { FeedItemType } from 'src/feed/FeedItem';
import Analytics, { AnalyticsEventType } from 'src/analytics/Analytics';
import DoneButton from 'src/button/DoneButton';
import Button from 'src/button/Button';
import { Notification } from 'src/types/Notification';
import { Link } from 'react-router-dom';
import { Switch } from '@mui/material';


type OwnProps = {
  feedPost?: any;
  selectedThread?: any;
  isEditing?: boolean;
  render(onClick: () => void): React.ReactNode;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
  events: Event[];
  feedItems: any[];
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
  setPostsFeedItems(item: any[]): void;
  updatePost(post: any): void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  drawerOpen: boolean;
  audienceModalOpen: boolean;
  selectedThread?: Object;
  selectedAttachment?: Object;
  feedPost: Object;
  suggestedTracks: any[];
  giphyOpen: boolean;
  hasScrolled: boolean;
};

const MAX_DESCRIPTION_LENGTH = 250;

const CreateFeedPostDrawerButton: React.FC<Props> = ({
  currentUser, render,
  feedPost: feedPostFromProps,
  selectedThread: selectedThreadFromProps,
  isEditing = false,
  events = [],
  feedItems = [],
  addNotification,
  setPostsFeedItems,
  updatePost,
}) => {
  const initialFeedPost = {
    type: 'feedPost',
    items: {
      items: [],
    }, // attachments,
    description: undefined,
    sendEmail: false,
    sendSMS: false,
    ...feedPostFromProps, // Should always update with current user location but we can override the rest of the feed post
    location: get(currentUser, 'location'),
  };
  const [drawerOpen, setDrawerOpen] = useState<State['drawerOpen']>(false);
  const [audienceModalOpen, setAudienceModalOpen] = useState<State['audienceModalOpen']>(false);
  const [selectedThread, setSelectedThread] = useState<State['selectedThread']>(selectedThreadFromProps);
  const [feedPost, setFeedPost] = useState<State['feedPost']>(initialFeedPost);
  const [suggestedTracks, setSuggestedTracks] = useState<State['suggestedTracks']>([]);

  const selectedThreadId = get(selectedThread, 'id');
  const selectedThreadType = get(selectedThread, 'type');
  const selectedThreadAuthorized = get(selectedThread, 'authorized', false);

  useEffect(() => {
    const getSuggestedTracks = async () => {
      let items: any[] = [];
      
      if (selectedThreadType) {
        const event = await getEvent(selectedThreadId, { cache: true });
        const artistIds = get(event, 'artists.items', []).filter(({ provider }: any) => provider === 'spotify').map(({ id }: any) => id);
  
        if (!artistIds || !artistIds.length) return;
  
        const response = await getRecommendations({
          limit: 10,
          seed_artists: artistIds,
        });
        items = get(response, 'items', []);
      } else {
        items = await getSpotifyOrAppleMusicMyRecentlyPlayedTracks(currentUser!, { limit: 10 });
      }
  
      setSuggestedTracks(items);
    };

    getSuggestedTracks();
  }, [selectedThreadId]);

  if (!currentUser) return null;

  const { name: currentUserName, communities: currentUserCommunities, events: currentUserEvents = [], artists: currentUserArtists = [] } = currentUser!;
  const selectedThreadVisible = selectedThread && Boolean([...currentUserEvents, ...currentUserCommunities].find(({ id }) => id === get(selectedThread, 'id')));
  const description = get(feedPost, 'description', '');
  const sendEmail = get(feedPost, 'sendEmail', true);
  const sendSMS = get(feedPost, 'sendSMS', false);
  const attachments = get(feedPost, 'items.items', []);
  const [attachment] = attachments;
  let descriptionPlaceholder = Boolean(currentUserName) ? `${currentUserName}, what\'s going on?`: 'What\'s going on?';

  if (attachment) {
    if (attachment.type === 'poll') {
      descriptionPlaceholder = 'Ask a question...';
    }
  }


  const openDrawer = () => { setDrawerOpen(true); };
  const closeDrawer = () => {
    setDrawerOpen(false);
    setFeedPost(initialFeedPost);
  };
  const openAudienceModal = () => { setAudienceModalOpen(true); };
  const closeAudienceModal = () => { setAudienceModalOpen(false); };

  const handleDescriptionChange = (e: FormEvent) => {
    const description = get(e, 'target.value', '');

    setFeedPost({
      ...feedPost,
      description,
    });
  };

  const handleSharePost = async () => {
    const feedId = selectedThreadId;
    let newFeedPost = feedPost;

    // if (isEditing) {
    //   console.log('feedPost to update', feedPost);
    //   newFeedPost = await updateFeedPostForUser(currentUser.id, cleanObject({
    //     ...feedPost,
    //     feedId,
    //     location: currentUser.location,
    //   }));
    //   // updateFeedPost(newFeedPost);
    // } else {
      console.log('handleSharePost currentUser.location', currentUser.location);
      newFeedPost = await addFeedPostForUser(currentUser.id, cleanObject({
        ...feedPost,
        feedId,
        location: currentUser.location,
        items: {
          items: get(feedPost, 'items.items', []).map((attachment: Object) => {
            const { type } = attachment as any;

            if (type !== 'image') return attachment;

            return cleanObject({
              ...attachment,
              url: undefined, // Don't pass base64 URL to prevent bloating API request
              uri: undefined,
            });
          }),
        }
      }));

      console.log('newFeedPost from create', newFeedPost);
      const firstPostIndex = feedItems.findIndex(({ type }) => type === FeedItemType.feedPost);

      updatePost(newFeedPost);

      if (firstPostIndex === -1) {
        setPostsFeedItems([
          {
            type: FeedItemType.feedPost,
            payload: newFeedPost,
          },
          ...feedItems,
        ]);
      } else {
        setPostsFeedItems([
          ...feedItems.slice(0, firstPostIndex),
          {
            type: FeedItemType.feedPost,
            payload: newFeedPost,
          },
          ...feedItems.slice(firstPostIndex),
        ]);
      }

      // TODO: Set community/event feeds as well based on threadId
      
      closeDrawer();

      const newFeedPostId = get(newFeedPost, 'id');
      
      addNotification({
        title: 'Post shared',
        message: (
          <span>
            Your post has been successfully shared! {Boolean(newFeedPostId) && <Link className="mt-1" to={`/posts/${newFeedPostId.replace('feedPost:', '')}`}>View post</Link>}
          </span>
        ),
        variant: 'primary',
        items: [feedPost],
      });
    // }
  };

  const createHandleThreadSelected = (item: any) => () => {
    const attachment = get(feedPost, 'items.items[0]');
    const attachmentType = get(attachment, 'type');
    const feedType = get(item, 'type');
    const shouldClearAttachments = Boolean(feedType === 'event' && attachmentType === 'event');

    setFeedPost({
      ...feedPost,
      items: {
        ...get(feedPost, 'items', {}),
        items: shouldClearAttachments ? [] : [attachment],
      },
      location: currentUser.location,
    });
    setSelectedThread(item);
    closeAudienceModal();
  };

  const validatePollOption = (pollOption: any): boolean => {
    let valid = true;
    const description = get(pollOption, 'description');
    const attachments = get(pollOption, 'attachments.items', []);

    if (!description && !attachments.length) {
      valid = false;
    }

    return valid;
  };

  const validatePoll = (poll: any): boolean => {
    let valid = true;
    const pollOptions: any[] = get(poll, 'options.items', []);

    pollOptions.some((pollOption: any) => {
      if (!validatePollOption(pollOption)) {
        valid = false;
        return true;
      }

      return false;
    });

    return valid;
  };


  const updateSendEmail = (sendEmail: boolean) => {
    setFeedPost({
      ...feedPost,
      sendEmail,
    });
  };

  const updateSendSMS = (sendSMS: boolean) => {
    setFeedPost({
      ...feedPost,
      sendSMS,
    });
  };

  const validateForm = (): string | undefined => {
    const attachments = get(feedPost, 'items.items', []);
    const [attachment] = attachments;
    const attachmentType = get(attachment, 'type');
    let message: string | undefined;
    const validators = [
      {
        invalid: Boolean(!description && !attachment),
        message: 'Add a post description.',
      },
      {
        invalid: !Boolean(description) && attachmentType === 'poll',
        message: 'Add a poll question',
      },
      {
        invalid: attachmentType === 'poll' && !validatePoll(attachment),
        message: 'Complete poll options',
      },
      {
        invalid: Boolean(get(description, 'length', 0) > MAX_DESCRIPTION_LENGTH),
        message: `Description must be shorter than ${MAX_DESCRIPTION_LENGTH} characters.`,
      },
    ];

    validators.forEach((validator) => {
      if (get(validator, 'invalid', false)) {
        message = get(validator, 'message');
      }
      
      return !Boolean(message);
    });

    return message;
  };

  const errorMessage = validateForm();
  const shareDisabled = Boolean(errorMessage);

  const sections = [
    {
      id: 'public',
      title: '',
      data: [{
        name: 'Public',
        description: 'Anyone near you can view your post.',
        icon: (
          <div
            className="d-flex justify-content-center align-items-center bg-secondaryButton"
            style={{
              width: '100%',
              aspectRatio: '1',
              borderRadius: 10,
            }}
          >
            <Icon name={Icons.globe} className="bm-Icon--white" size={26} />
          </div>
        ),
      }],
      showSeeAll: false,
    },
  ];

  if (selectedThread && !selectedThreadVisible) {
    sections.push({
      id: 'selectedThread',
      title: 'Current selection',
      data: [selectedThread as any],
      showSeeAll: false,
    });
  }

  if (currentUserCommunities && currentUserCommunities.length) {
    sections.push({
      id: 'communities',
      title: 'My Communities',
      data: currentUserCommunities.filter(({ id }) => id !== get(selectedThread, 'id')).slice(0,5) as any[],
      showSeeAll: true,
    });
  }

  if (currentUserEvents && currentUserEvents.length) {
    sections.push({
      id: 'events',
      title: 'My Events',
      data: currentUserEvents.filter(({ id }) => id !== get(selectedThread, 'id')).slice(0,5) as any[],
      showSeeAll: true,
    });
  } else if (events && events.length) {
    sections.push({
      id: 'suggestedEvents',
      title: 'Suggested Events',
      data: events.slice(0,5) as any[],
      showSeeAll: true,
    });
  }

  const shareButton = (
    <DoneButton
      disabled={shareDisabled}
      asyncOnClick={handleSharePost}
      render={(onClick, loading) => (
        <Button
          disabled={loading}
          variant="primary"
          onClick={async () => {
            if (shareDisabled) {
              addNotification({
                title: 'Error',
                message: errorMessage!,
                variant: 'danger',
                items: [feedPost],
              });
            } else {
              await onClick();
              await Analytics.track(isEditing ? AnalyticsEventType.postUpdated : AnalyticsEventType.postCreated, cleanObject({
                postFeedId: get(selectedThread, 'id', 'public'),
                postFeedType: get(selectedThread, 'type', 'public'),
                postId: get(feedPost, 'id'),
                postAttachmentType: get(feedPost, 'items.items[0].type', get(feedPost, 'attachments.items[0].type')),
              }));
            }
          }}
          style={{
            width: '100%',
            height: 50,
            backgroundColor: Colors.primary,
            borderRadius: 50,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading && <Spinner color={Colors.white} size="sm" animation="border" variant="secondary" />}
          {!loading && (
            <h5 className="text-bold text-white m-0 p-0" style={{ fontSize: '1.1rem' }}>
              {isEditing ? 'Done' : 'Share'}
            </h5>
          )}
        </Button>
      )}
    />
  );

  return (
    <DrawerButton
      open={drawerOpen}
      openDrawer={openDrawer}
      closeDrawer={closeDrawer}
      header={(
        <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
          <div>
            <h3 className="text-bold text-white">New post</h3>
            {Boolean(selectedThreadAuthorized) && <p className="m-0 p-0 bm-Text--secondaryText">{`Share announcements, exclusives, and more with your ${Boolean(selectedThreadType === 'event') ? 'guests' : 'members'}.`}</p>}
          </div>
          <button onClick={closeDrawer} className="btn pr-0">
            <span className="material-icons text-white">close</span>
          </button>
        </div>
      )}
      content={(
        <div className="p-1">
          <div className="mb-4">
            <MediaObject
              image={get(currentUser, 'images[0].url')}
              imageSize={55}
              imageType="circle"
              right={<div style={{ flex: 1 }} />}
              wrap={false}
              text={(
                <div className="d-flex justify-content-between align-items-center">
                  {/* <AppText type="bold" size="h4" style={{ flex: 1, marginRight: HorizontalSpacing.lg }}>{(get(currentUser, 'name'))}</AppText> */}
                  <ModalButton
                    open={audienceModalOpen}
                    openModal={openAudienceModal}
                    closeModal={closeAudienceModal}
                    modalProps={{
                      className: 'bm-CreateFeedPostDrawerButton__modal bm-Modal--mobile text-white',
                      contentClassName: 'bg-secondaryButton',
                      scrollable: true,
                    }}
                    content={(
                      <div style={{ flex: 1, width: '100%' }}>
                        <div className="mb-4">
                          <h3 className="text-bold text-center mb-3">Choose where to post</h3>
                        </div>
                        <div
                          className="d-flex flex-column justify-content-between pt-3 pb-4"
                          style={{ minHeight: '100%' }}
                        >
                          <SectionList
                            renderItem={({ item, index, section }) => {
                              const sectionId = get(section, 'id');

                              if (sectionId === 'public') {
                                const { name, description, icon } = item;
                                const selected = !selectedThread;

                                return (
                                  <button
                                    className="btn mb-3"
                                    onClick={() => {
                                      setFeedPost({
                                        ...feedPost,
                                        location: currentUser.location,
                                      });
                                      setSelectedThread(undefined);
                                      closeAudienceModal();
                                    }}
                                    style={{ maxHeight: 'none', maxWidth: 'none', width: '100%' }}
                                  >
                                    <MediaObject
                                      text={name}
                                      textSize="h5"
                                      textColor="light"
                                      subtext={description}
                                      image={icon}
                                      imageSize={50}
                                      wrap={false}
                                      right={(
                                        <Form.Check
                                          className="bm-CreateFeedPostDrawerButton_checkbox"
                                          checked={selected}
                                          type="radio"
                                          color={Colors.primary}
                                        />
                                      )}
                                    />
                                  </button>
                                );
                              }
      
                              return (
                                <>
                                  <UpdatePostThread
                                    item={item}
                                    index={index}
                                    selected={Boolean(selectedThreadId === get(item, 'id'))}
                                    onClick={createHandleThreadSelected(item)}
                                  />
                                  {/* {Boolean(showSeeAll) && index === data.length - 1 && (
                                    <NavigationButton
                                      screen={Screens.Search}
                                      screenProps={id === 'events' ? searchEventsScreenProps : searchCommunitiesScreenProps}
                                      render={(onClick) => (
                                        <button
                                          className="btn"
                                          activeOpacity={0.9}
                                          onClick={() => {
                                            this.closeAudienceDrawer();
                                            onClick();
                                          }}
                                          style={{
                                            borderTopWidth: index !== 0 ? 0.2 : 0,
                                            borderColor: 'rgba(255,255,255,0.075)',
                                            width: '100%',
                                            paddingVertical: verticalScale(20),
                                          }}
                                        >
                                          <MediaObject
                                            text={`See all ${id === 'events' ? 'events' : 'communities'}`}
                                            textSize="h5"
                                            noPadding={true}
                                            imageType="circle"
                                            imageSize={moderateScale(50)}
                                            image={(
                                              <View
                                                style={{
                                                  width: '100%', aspectRatio: 1, borderRadius: 10,
                                                  backgroundColor: Colors.secondaryButton,
                                                  justifyContent: 'center', alignItems: 'center',
                                                }}
                                              >
                                                <Icon name={Icons.more} size={22} color={Colors.secondaryIcon} />
                                              </View>
                                            )} 
                                          />
                                        </button>
                                      )}
                                    />
                                  )} */}
                                </>
                              );
                            }}
                            renderSectionHeader={({ section: { title } }) => Boolean(title) ? (
                              <h4 className="text-bold">
                                {title}
                              </h4>
                            ) : null}
                            sections={sections}
                            renderSectionFooter={({ section }) => Boolean(get(section, 'id') !== 'events') ? <div style={{ height: 24 }} /> : null}
                          />
                        </div>
                      </div>
                    )}
                    render={(onClick) => (
                      <button
                        disabled={selectedThreadFromProps}
                        className="btn pl-3 pr-3 pt-2 pb-2"
                        onClick={onClick}
                        style={{
                          borderWidth: 1.5,
                          borderColor: Colors.inactiveTab,
                          borderRadius: 50,
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <MediaObject
                          text={get(selectedThread, 'name', '') || 'Public'}
                          textSize="p"
                          textColor="light"
                          wrap={false}
                          image={Boolean(selectedThread) ? get(selectedThread, 'images[0].url') : (
                            <div
                              style={{
                                width: '100%', aspectRatio: '1', borderRadius: 10,
                                backgroundColor: Colors.secondaryButton,
                                justifyContent: 'center', alignItems: 'center',
                              }}
                            >
                              <Icon name={Icons.globe} className="bm-Icon--white" size={26} />
                            </div>
                          )}
                          imageSize={32.5}
                          imageType="rounded"
                          right={!Boolean(selectedThreadFromProps) && <Icon name={Icons.chevronDown} className="bm-Icon--white" size={18} />}
                        />
                      </button>
                    )}
                  />
                </div>
              )}
            />
          </div>
          <Form.Control
            // ref={(i) => {
            //   this.descriptionInput = i!;
            // }}
            type="text"
            className="bm-CreateFeedPostDrawerButton__formControl mb-2 pt-2 text-white"
            value={description}
            disabled={audienceModalOpen}
            placeholder={descriptionPlaceholder}
            onInput={handleDescriptionChange}
            autoCapitalize="sentences"
            inputMode="text"
            autoFocus={true}
            autoCorrect="true"
            spellCheck={true}
            as="textarea"
            rows={6}
            style={{
              fontSize: 18,
              width: '100%',
              borderRadius: 20,
            }}
          />
          <p
            className="mb-3 m-0 p-0"
            style={{
              color: description && description.length > MAX_DESCRIPTION_LENGTH ? Colors.danger : 'rgba(255,255,255,0.35)',
            }}
          >
            {description.length}/{MAX_DESCRIPTION_LENGTH}
          </p>
          {Boolean(selectedThreadType === 'event' || selectedThreadType === 'community') && Boolean(selectedThreadAuthorized) && ( // TODO: Check if user is authorized for audience before showing
            <div className="mt-3 pt-4" style={{ width: '100%', borderTopWidth: 0.25, borderTopColor: 'rgba(255,255,255,0.25)' }}>
              <SectionList
                renderItem={({ item }) => {
                  const { title, description, value, iconName, onValueChange } = item;

                  return (
                    <div
                      className="d-flex justify-content-between mb-4"
                      style={{ width: '100%' }}
                    >
                      <MediaObject
                        text={title}
                        textSize="p"
                        textColor="light"
                        subtext={description}
                        subtextSize="p"
                        image={(
                          <div
                            className="bg-secondaryButton d-flex justify-content-center align-items-center"
                            style={{
                              backgroundColor: Colors.background,
                              width: 50, aspectRatio: '1', borderRadius: 25,
                            }}
                          >
                            <Icon name={iconName} className="bm-Icon--white" size={24} />
                          </div>
                        )}
                        imageSize={50}
                        wrap={false}
                        right={(
                          <Switch
                            defaultChecked={value}
                            checked={value}
                            onChange={(_, checked) => {
                              onValueChange(checked);
                            }}
                            sx={{
                              '& .MuiSwitch-switchBase.Mui-checked': {
                                color: Colors.primary,
                              },
                              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: Colors.primary,
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  );
                }}
                renderSectionHeader={({ section: { title } }) => (
                  <h5 className="text-white text-bold mb-3">
                    {title}
                  </h5>
                )}
                sections={[
                  {
                    title: 'Send to',
                    data: [
                      {
                        id: 'SMS',
                        title: 'SMS',
                        description: `Notify ${Boolean(selectedThreadType === 'event') ? 'guests' : 'members'} with a SMS blast.`,
                        value: sendSMS,
                        onValueChange: updateSendSMS,
                        iconName: Icons.comment,
                      },
                      {
                        id: 'email',
                        title: 'Email',
                        description: `Notify ${Boolean(selectedThreadType === 'event') ? 'guests' : 'members'} with an email blast.`,
                        value: sendEmail,
                        onValueChange: updateSendEmail,
                        iconName: Icons.email,
                      },
                    ],
                  },
                ]}
                keyExtractor={(item) => item.id}
                className="mb-3"
              />
            </div>
          )}
        </div>
      )}
      footer={shareButton}
      render={render}
    />
  );
};

const UpdatePostThread: React.FC<{ item: any; index: number; onClick: MouseEventHandler; selected: boolean; }> = ({
  item,
  index,
  onClick,
  selected,
}) => (
  <button
    className="btn pt-3 pb-3"
    onClick={onClick}
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    }}
  >
    <Item
      item={item}
      imageSize={50}
      borderRadius={5}
      link={false}
      wrap={false}
      right={(
        <Form.Check
          className="bm-CreateFeedPostDrawerButton_checkbox"
          checked={selected}
          type="radio"
          color={Colors.primary}
        />
      )}
    />
  </button>
);

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
  events: getEvents(state),
  feedItems: getPostsFeedItems(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
  setPostsFeedItems: (feedItems: any[]) => {
    dispatch(setPostsFeedItems(feedItems));
  },
  updatePost: (post: any) => {
    dispatch(updatePost(post));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFeedPostDrawerButton);