/* External dependencies */
import React, { MouseEventHandler } from 'react';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import Button, { Props as ButtonProps } from 'src/button/Button';
import SearchModal, { Props as SearchModalProps } from './SearchModal';

type OwnProps = ButtonProps & {
  modalHeader?: SearchModalProps['header'];
  modalSubheader?: SearchModalProps['subheader'];
  variant?: 'primary' | 'success' | 'secondary';
  children?: React.ReactNode;
  text?: string;
  color?: string;
  backgroundColor?: string;
  render?: (func: MouseEventHandler) => React.ReactNode;
} & Pick<
  SearchModalProps,
  'items' | 'setItems' | 'empty' | 'renderItem' |
  'onInitialLoad' | 'onQueryUpdate' | 'onRefresh' |
  'onLoadNextPage' | 'pageSize' | 'placeholder' |
  'onItemClick' | 'loadingImageType' | 'loadingImageSize'
>;

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = OwnProps & StateProps;

type State = {
  modalOpen: boolean;
};

class SearchModalButton extends React.Component<Props, State> {
  state = { modalOpen: false };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  hideModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const {
      items = [],
      setItems,
      onInitialLoad,
      onQueryUpdate,
      onRefresh,
      onLoadNextPage,
      onItemClick,
      pageSize,
      placeholder,
      renderItem,
      modalHeader, modalSubheader, children,
      variant, color, backgroundColor,
      loadingImageType, loadingImageSize,
      text, render
    } = this.props;
    const { modalOpen } = this.state;
    const searchModal = Boolean(modalOpen) && (
      <SearchModal
        header={modalHeader}
        subheader={Boolean(modalSubheader) ? modalSubheader : undefined}
        items={items}
        setItems={setItems}
        onInitialLoad={onInitialLoad}
        onQueryUpdate={onQueryUpdate}
        onRefresh={onRefresh}
        onLoadNextPage={onLoadNextPage}
        pageSize={pageSize}
        placeholder={placeholder}
        renderItem={renderItem}
        open={modalOpen}
        onOpen={this.openModal}
        onClose={this.hideModal}
        onItemClick={onItemClick}
        loadingImageType={loadingImageType}
        loadingImageSize={loadingImageSize}
      />
    );

    if (render) {
      return (
        <>
          {render(this.openModal)}
          {searchModal}
        </>
      );
    }

    return (
      <>
        <Button
          {...omit(this.props, ['currentUser', 'modalHeader', 'modalSubheader'])}
          variant={variant}
          onClick={this.openModal}
          style={{
            width: '100%',
            borderRadius: 30,
            fontWeight: 700,
            height: 150,
            color,
            backgroundColor,
          }}
        >
          {children || text}
        </Button>
        {searchModal}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps)(SearchModalButton);