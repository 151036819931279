/* External dependencies */
import React, { useEffect, useState } from 'react';
import './ForOrganizers.scss';
import { Link } from 'react-router-dom';

/* Internal dependencies */
import ForOrganizersEvent from '../assets/images/for-organizers-event.png';
import ForOrganizersCreateEvent from '../assets/images/for-organizers-create-event.png';
import ForOrganizersGrowCommunity from '../assets/images/for-organizers-grow-community.png';
import ForOrganizersKnowYourFans from '../assets/images/for-organizers-know-your-fans.png';
import ForOrganizersGetMoney from '../assets/images/for-organizers-get-money.png';
import FeatureSection from './FeatureSection';
import Marquee from 'react-fast-marquee';
import { ReactComponent as WarnerMusic } from '../assets/images/warner-music-group.svg';
import { ReactComponent as QualityControlMusic } from '../assets/images/quality-control-music.svg';
import { ReactComponent as SonyMusic } from '../assets/images/sony-music.svg';
import { ReactComponent as HYBEMusic } from '../assets/images/hybe-music.svg';
import { ReactComponent as RightHandMusic } from '../assets/images/right-hand-music-group.svg';
import { ReactComponent as ConcordMusic } from '../assets/images/concord-music.svg';
import ScrollToTop from 'src/scrollToTop/ScrollToTop';
import { listPosts } from 'src/api/posts';
import FlatList from 'src/flatList/FlatList';
import PostPreview from 'src/blog/PostPreview';

type State = {
  posts: any[];
};

const FEATURE_SECTIONS = [
  {
    heading: 'Create epic events in seconds',
    subheading: 'Sell tickets for your next party, concert, art show, or festival and boost sales.',
    image: ForOrganizersCreateEvent,
    actionButton: (
      <Link
        className="btn btn-secondary bm-ForOrganizers__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5"
        to="/events/new"
        style={{ borderRadius: 100 }}
      >
        <h5 className="pt-1 text-dark">Get started</h5>
      </Link>
    ),
  },
  {
    heading: 'Grow your community with ease',
    subheading: 'Reach new people and turn your audience into loyal attendees with free SMS blasts and community tools.',
    image: ForOrganizersGrowCommunity,
    actionButton: (
      <Link
        className="btn btn-secondary bm-ForOrganizers__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5"
        to="/events/new"
        style={{ borderRadius: 100 }}
      >
        <h5 className="pt-1 text-dark">Get started</h5>
      </Link>
    ),
  },
  {
    heading: 'Know your fans',
    subheading: 'Analyze rich audience insights like demographics, traffic, revenue, customer loyalty, and more.',
    image: ForOrganizersKnowYourFans,
    actionButton: (
      <Link
        className="btn btn-secondary bm-ForOrganizers__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5"
        to="/events/new"
        style={{ borderRadius: 100 }}
      >
        <h5 className="pt-1 text-dark">Get started</h5>
      </Link>
    ),
  },
  {
    heading: 'Get your money instantly',
    subheading: 'Cover upfront expenses with confidence by accessing your money through daily or instant payouts.',
    image: ForOrganizersGetMoney,
    actionButton: (
      <Link
        className="btn btn-secondary bm-ForOrganizers__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5"
        to="/events/new"
        style={{ borderRadius: 100 }}
      >
        <h5 className="pt-1 text-dark">Get started</h5>
      </Link>
    ),
  },
];

const ForOrganizers: React.FC = () => {
  const [posts, setPosts] = useState<State['posts']>([]);

  useEffect(() => {
    const load = async () => {
      try {
        const { items = [] } = await listPosts({ tags: ['creators'], limit: 5 });
        setPosts(items);
      } catch (e) {
        console.log(e);
      }
    };

    load();
  }, []);

  return (
    <>
      <section className="bm-ForOrganizers" style={{ overflow: 'hidden' }}>
        <div data-aos="fade-in">
          <section className="bm-ForOrganizers__heroSection container-fluid mb-5">
            <FeatureSection
              heading="Amplify your events"
              headingSize="lg"
              subheading="The easiest way to sell tickets, grow your community, and delight your fans."
              image={ForOrganizersEvent}
              reverse={true}
              actionButton={(
                <Link
                  className="btn btn-primary bm-ForOrganizers__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5"
                  to="/events/new"
                  style={{ borderRadius: 100 }}
                >
                  <h5 className="pt-1">Get started</h5>
                </Link>
              )}
            />
          </section>
          <section className="bm-ForOrganizers_section6">
            <h5 className="text-white" data-aos="fade-up">Trusted by the best in entertainment</h5>
            <div className="d-flex flex-column align-items-center" data-aos="fade-up" style={{ width: '100%', overflowX: 'hidden' }}>
              <div className="d-md-none" style={{ overflowX: 'hidden' }}>
                <Marquee
                  direction="left"
                  speed={5}
                  pauseOnHover={true}
                  gradient={true}
                >
                  <div className="bm-ForOrganizers_investors container d-flex justify-content-center align-items-center">
                    <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <WarnerMusic fill="#fff" />
                    </div>
                    <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <QualityControlMusic fill="#fff" />
                    </div>
                    <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center">
                      <SonyMusic fill="#fff" />
                    </div>
                    <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <HYBEMusic fill="#fff" />
                    </div>
                    <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <RightHandMusic fill="#fff" />
                    </div>
                    <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                      <ConcordMusic fill="#fff" />
                    </div>
                  </div>
                </Marquee>
              </div>
              <div className="bm-ForOrganizers_investors container d-md-flex justify-content-center align-items-center d-none">
                <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                  <WarnerMusic fill="#fff" />
                </div>
                <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                  <QualityControlMusic fill="#fff" />
                </div>
                <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center">
                  <SonyMusic fill="#fff" />
                </div>
                <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                  <HYBEMusic fill="#fff" />
                </div>
                <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                  <RightHandMusic fill="#fff" />
                </div>
                <div className="bm-ForOrganizers_investorLogo d-flex justify-content-center align-items-center mr-5">
                  <ConcordMusic fill="#fff" />
                </div>
              </div>
            </div>
          </section>
          <section className="bg-dark container-fluid">
            {FEATURE_SECTIONS.map(({ heading, subheading, image, actionButton }, i) => (
              <div className="bm-ForOrganizers__section" data-aos="fade-up">
                <FeatureSection
                  heading={heading}
                  subheading={subheading}
                  image={image}
                  reverse={i % 2 !== 0}
                  actionButton={actionButton}
                />
              </div>
            ))}
          </section>
          {Boolean(posts && posts.length) && (
            <section className="bg-dark container">
              <h1 className={`bm-FeatureSection_heading bm-FeatureSection_heading--md mb-2 text-white text-bold`}>Want to know more?</h1>
              <h3 className="bm-FeatureSection_subheading bm-Text--secondaryText mb-5">Dive in to learn more about Beatmatch for Creators.</h3>
              <FlatList
                data={posts.slice(0,2)} // Skip hero post
                grid={true}
                gridOnMobile={false}
                gridGap="3rem"
                numColumns={2}
                renderItem={({ item: post }) => {

                  return (
                    <div
                      data-aos="fade-in"
                      data-aos-offset="100"
                      data-aos-delay="25"
                      data-aos-once="true"
                      className="bm-Blog_postContainer mb-3"
                    >
                      <PostPreview post={post} showShareButtons={false} />
                    </div>
                  );
                }}
              />
              <FlatList
                data={posts.slice(2,5)} // Skip hero post
                grid={true}
                gridOnMobile={false}
                gridGap="3rem"
                numColumns={3}
                renderItem={({ item: post }) => {

                  return (
                    <div
                      data-aos="fade-in"
                      data-aos-offset="100"
                      data-aos-delay="25"
                      data-aos-once="true"
                      className="bm-Blog_postContainer mb-3"
                    >
                      <PostPreview post={post} showShareButtons={false} />
                    </div>
                  );
                }}
              />
            </section>
          )}
          {/* <div className="mr-4" style={{ flex: 1 }}>
            <h1 className="bm-ForOrganizers_heading text-left mb-4 text-white text-bold">Amplify your events</h1>
            <h3 className="bm-ForOrganizers_subheading text-left mb-4 text-white">
              The easiest way to sell tickets, grow your community, and delight your fans.
            </h3>
            <Link
              className="btn btn-primary bm-ForOrganizers__primaryCTA pt-2 pb-2 pl-4 pr-4 mb-5"
              to="/events/new"
            >
              <h5 className="pt-1">Create event</h5>
            </Link>
          </div>
          <div style={{ flex: 1 }}>
            <Image
              src={ForOrganizersEvent}
              style={{ width: '100%', aspectRatio: '1' }}
            />
          </div> */}
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default ForOrganizers;