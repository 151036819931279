/* Internal dependencies */
import { cacheify } from '../helpers/cacheify';
import { PaginationOptions } from '../types/Pagination';
import { Image } from 'src/types/User';
import { cleanObject } from 'src/common/cleanObject';
import API from '.';
import { modelUser } from 'src/store/helpers/users';

export const addArtistForUser = async (userId: string, artist: any) =>
  await API.post({
    path: `/users/${userId}/artists`,
    options: {
      body: artist,
    },
  });

export const removeArtistForUser = async (userId: string, artistId: string) =>
  await API.del({
    path: `/users/${userId}/artists/${artistId}`,
  });

export const addArtist = async (artist: any) =>
  await API.post({
    path: `/artists`,
    options: {
      body: artist,
    },
  });

export const addImageForArtist = async (artistId: string, image: Omit<Image, 'url'>) =>
  await API.post({
    path: `/artists/${artistId}/images`,
    options: {
      body: image,
    },
  });

export const updateImageForArtist = async (artistId: string, imageIndex: number, image: Omit<Image, 'url'>) =>
  await API.put({
    path: `/artists/${artistId}/images/${imageIndex}`,
    options: {
      body: image,
    },
  });

export const listArtistsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get({
    path: `/users/${userId}/artists`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: Boolean(offset) ? JSON.stringify(offset) : undefined,
      }),
    },
  });

export const getArtists = async (artistIds: string[]) =>
  await API.get({
    path: `/artists/getArtists`,
    options: {
      queryParams: {
        artistIds: artistIds.join(','),
      },
    },
  });

export const getArtist = async (artistId: string, options?: { cache: boolean }) =>
  await API.get({
    path: `/artists/${artistId}`,
    options: {
      queryParams: cleanObject({
        cache: options && options.cache,
      }),
    },
  });

const cachedSearchArtists = cacheify<(...args: any[]) => Promise<{ items: any[] }>, { items: any[] }>(
  async (q: string, { limit, offset }: PaginationOptions) =>
    await API.get({
      path: '/search',
      options: {
        queryParams: {
          q,
          type: 'artist',
          limit,
          offset,
        },
      },
    }) as any,
  60000
);

export const searchArtists = async (q: string, options: PaginationOptions) => await cachedSearchArtists()(q, options);

export const listUsersForArtist = async (
  artistId: string,
  { limit, offset }: PaginationOptions
) => {
  const response = await API.get({
    path: `/artists/${artistId}/users`,
    options: {
      queryParams: {
        limit,
        offset: JSON.stringify(offset),
      },
    }
  });

  console.log('listUsersForArtist response', response);

  return {
    ...response,
    items: response.items && response.items.map(modelUser),
  };
};