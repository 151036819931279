/* External dependencies */
// import React from 'react';
import get from 'lodash/get';
import branch from 'branch-sdk';

/* Internal dependencies */
import { capitalizeFirstLetter } from 'src/store/helpers/users';
import { Item } from 'src/types/Item';
import { providerToProviderCode } from 'src/events/Events';
import { providerToProviderCode as artistsProviderToProviderCode } from 'src/artist/ArtistDetail';
import { promisifyCallback } from 'src/common/promisify/promisify';

const getDesktopUrlFromItem = (item: Item | any) => {
  const { id, type, provider, providerId } = item;
  const providerCode = providerToProviderCode[provider];
  
  let path = `${type}s/${providerId}`;
  
  if (type === 'event') {
    path = `events/${providerCode}/${providerId}`;
  } else if (type === 'activity') {
    path = `plans/${id.replace('activity:', '')}`;
  } else if (type === 'feedPost') {
    path = `posts/${id.replace('feedPost:', '')}`;
  } else if (type === 'community') {
    path = `communities/${id.replace('community:', '')}`;
  } else if (type === 'artist') {
    const providerCode = artistsProviderToProviderCode[provider];
    path = `artists/${providerCode}/${providerId}`;
  } else if (type === 'venue') {
    path = `venues/${id.replace('venue:', '')}`;
  } else if (type === 'collection') {
    path = `collections/${id.replace('collection:', '')}`;
  }

  const desktopURL = `https://www.beatmatch.app/${path}`;
  return desktopURL;
};

const getUriFromItem = (item: Item | any) => {
  const { id, type } = item;
  let uri = `beatmatch://${type}s/${id}`;

  if (type === 'activity') {
    uri = `beatmatch://plans/${id}`;
  } else if (type === 'feedPost') {
    uri = `beatmatch://posts/${id}`;
  } else if (type === 'community') {
    uri = `beatmatch://communities/${id}`;
  } else if (type === 'venue') {
    uri = `beatmatch://venues/${id}`;
  } else if (type === 'collection') {
    uri = `beatmatch://collections/${id}`;
  }

  return uri;
};

const getShareMessageFromItem = (item: Item | any) => {
  const { type } = item;
  let message;

  if (type === 'event') {
    message = `Want to go to ${get(item, 'name')}?`;
  } else if (type === 'track') {
    message = `Have you listened to ${get(item, 'name')} by ${get(item, 'artists[0].name')}?`;
  } else if (type === 'artist') {
    message = `Check out music from ${get(item, 'name')}!`;
  } else if (type === 'activity') {
    const creatorName = get(item, 'creator.name');
    const eventName = get(item, 'events.items[0].name');

    if (creatorName) {
      if (eventName) {
        message = `Want to join ${creatorName} at ${eventName}?`;
      } else {
        message = `Want to join ${creatorName}'s plan?`;
      }
    } else {
      message = 'Want to join this plan?';
    }
  } else if (type === 'collection') {
    message = `Browse upcoming events in ${get(item, 'name')}.`;
  } else if (type === 'venue') {
    message = `Let's check out ${get(item, 'name')}!`;
  }

  return message;
};

export const generateShareUrlFromItem = async (item: Item | any) => {
  const { name, type, images = [] } = item;
  const shareMessage = getShareMessageFromItem(item);

  const linkProperties = {
    feature: 'item-share',
    channel: 'app',
    campaign: `${capitalizeFirstLetter(type)} Share`,
  };
  const desktopURL = getDesktopUrlFromItem(item);
  const uri = getUriFromItem(item);

  const controlParams = {
    $canonical_url: desktopURL,
    $desktop_url: desktopURL,
    $deeplink_path: uri,
    $fallback_url: desktopURL,
    $after_click_url: desktopURL,
    $og_title: name,
    $og_description: shareMessage,
    $og_image_url: get(images, '[0].url'),
    $og_image_height: get(images, '[0].height'),
    $og_image_width: get(images, '[0].width'),
    $og_url: 'beatmatch.app',
    $twitter_title: name,
    $twitter_description: shareMessage,
    $twitter_image_url: get(images, '[0].url'),
    $twitter_site: 'Beatmatch',
    custom: JSON.stringify({
      type: get(item, 'type'),
      id: get(item, 'id'),
    }),
  };

  const generateLink = (): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      branch.link({ ...linkProperties, data: controlParams }, (err, link) => {
        if (err) {
          reject(err);
        } else {
          resolve(link!);
        }
      });
    });
  };

  try {
    const url = await generateLink();
    return url;
  } catch (err) {
    console.error('Error generating share URL:', err);
    return undefined;
  }
};