enum Colors {
  primary = '#9966FF',
  lightPurple = '#EFEAFC',
  lightPrimary = 'rgba(153, 102, 255, 0.5)',
  darkPurple = '#271845',
  greyBackground = '#f7f7f7',
  topNav = '#1a1a1a',
  white = '#FFF',
  black = '#000',
  lightGrey = '#E9E8ED',
  superLightGrey = '#F2F2F4',
  mediumGrey = '#5C5880',
  darkGrey = '#23203C',
  transparent = 'transparent',
  success = '#28a745',
  danger = '#dc3545',
  warning = '#f0ad4e',
  backgroundLight = '#FFF',
  background = '#121212',
  backgroundMidDark = 'rgba(255,255,255,0.1)',
  backgroundDark = '#121212',
  backgroundOverlay = 'rgba(255,255,255,0.075)',
  bottomDrawerBackground = '#121212',
  primaryText = '#FFF',
  secondaryText = '#E9E8ED',
  primaryIcon = '#FFF',
  secondaryIcon = '#E9E8ED',
  primaryButton = '#9966FF',
  secondaryButton = '#1E1E1E',
  secondaryButtonOpacity = 'rgba(255,255,255,0.05)',
  searchBar = '#191919',
  activeTab = '#FFF',
  inactiveTab = 'rgba(255,255,255,0.65)',
  spotify = '#1ed761',
  appleMusic = '#f94c57',
  primaryOpacity = 'rgba(153,102,255,0.15)',
  whiteOpacity = 'rgba(255,255,255,0.15)',
}

export default Colors;
