/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { getCurrentUser } from '../ducks/currentUser';
import { addLikeForItem, removeLikeForItem } from '../../api/likes';
import { setLikesLoading, updateLikes } from '../ducks/likes';
import { put, select } from '../reduxStory';
import { getActivity, updateActivity } from '../ducks/activities';
import { listChatsForUser } from '../../api/chats';
import { updatePost } from '../ducks/posts';
import { updateComment } from '../ducks/comments';
import { fetchAll } from '../../common/helpers/fetchAll';
// import Analytics, { AnalyticsEventType } from '../../analytics/Analytics';

type Options = {
  limit: number;
};

export const listLikes = async (options: Options = { limit: 10 }) => {
  const currentUser = select(getCurrentUser);

  if (!currentUser) return;

  const { items: likes = [] } = await listChatsForUser(currentUser.id, { ...options, status: 'pending', types: 'user' });

  try {
    put(updateLikes(likes));
    return likes;
  } catch (e) {
    console.log('error loading likes');
  } finally {
    put(setLikesLoading(false));
  }
};

export const addLikeForActivity = async (activityId: string, activity?: any) => {
  if (!activity) {
    activity = select(getActivity, activityId);
  }

  const currentUser = select(getCurrentUser);

  if (!currentUser) return;

  const shouldIncrement = !Boolean(get(activity, 'liked', false));
  addLikeForItem(currentUser.id, activityId);

  put(updateActivity({
    ...activity,
    liked: true,
    likes: {
      ...activity.likes,
      items: [
        ...activity.likes.items,
        {
          userId: currentUser.id,
          itemId: activityId,
          user: currentUser,
        },
      ],
      total: shouldIncrement ? activity.likes.total + 1 : activity.likes.total,
    },
  }));
};

const removeLikeForActivity = async (activityId: string) => {
  const currentUser = select(getCurrentUser);

  if (!currentUser) return;

  const activity = select(getActivity, activityId);
  const shouldDecrement = Boolean(get(activity, 'liked', false));
  removeLikeForItem(currentUser.id, `like:${activityId}`);

  put(updateActivity({
    ...activity,
    liked: false,
    likes: {
      ...activity.likes,
      items: [
        ...activity.likes.items,
        {
          userId: currentUser.id,
          itemId: activityId,
          user: currentUser,
        },
      ],
      total: shouldDecrement ? activity.likes.total - 1 : activity.likes.total,
    },
  }));
};

export const addLikeForPost = async (post: any) => {
  if (!post) {
    return;
  }

  const currentUser = select(getCurrentUser);

  if (!currentUser) return;

  const shouldIncrement = !Boolean(get(post, 'liked', false));
  addLikeForItem(currentUser.id, post.id);

  put(updatePost({
    ...post,
    liked: true,
    likes: {
      ...post.likes,
      items: [
        ...post.likes.items,
        {
          userId: currentUser.id,
          itemId: post.id,
          user: currentUser,
        },
      ],
      total: shouldIncrement ? post.likes.total + 1 : post.likes.total,
    },
  }));
};

const removeLikeForPost = async (post: any) => {
  if (!post) {
    return;
  }

  const currentUser = select(getCurrentUser);

  if (!currentUser) return;

  const shouldDecrement = Boolean(get(post, 'liked', false));
  removeLikeForItem(currentUser.id, `like:${post.id}`);

  const postLikes = get(post, 'likes.items', []);
  const postLikeIndex  = postLikes.findIndex(({ userId }: any) => userId === currentUser.id);

  put(updatePost({
    ...post,
    liked: false,
    likes: {
      ...post.likes,
      items: [
        ...postLikes.slice(0, postLikeIndex),
        ...postLikes.slice(postLikeIndex + 1),
      ],
      total: shouldDecrement ? post.likes.total - 1 : post.likes.total,
    },
  }));
};

export const addLikeForComment = async (post: any, comment: any) => { // TODO: Support adding like for reply
  if (!post || !comment) {
    return;
  }

  const currentUser = select(getCurrentUser);

  if (!currentUser) return;
  
  const shouldIncrement = !Boolean(get(comment, 'liked', false));
  console.log('about to add like to API...', comment);
  addLikeForItem(currentUser.id, comment.id);

  const commentLikes = get(comment, 'likes.items', []);
  const commentLikesTotal = get(comment, 'likes.total', commentLikes.length);
  const newComment = {
    ...comment,
    liked: true,
    likes: {
      ...comment.likes,
      items: [
        ...commentLikes,
        {
          userId: currentUser.id,
          itemId: comment.id,
          user: currentUser,
        },
      ],
      total: shouldIncrement ? commentLikesTotal + 1 : commentLikesTotal,
    },
  };

  console.log('about to add like to state...', newComment);

  put(updateComment(newComment));
  put(updatePost({ ...post }));
};

const removeLikeForComment = async (post: any, comment: any) => {
  if (!post || !comment) {
    return;
  }

  const currentUser = select(getCurrentUser);

  if (!currentUser) return;

  const shouldDecrement = Boolean(get(comment, 'liked', false));
  console.log('about to remove like from API...', comment);
  removeLikeForItem(currentUser.id, `like:${comment.id}`);

  const commentLikes = get(comment, 'likes.items', []);
  const commentLikeIndex  = commentLikes.findIndex(({ userId }: any) => userId === currentUser.id);
  const commentLikesTotal = get(comment, 'likes.total', commentLikes.length);
  const newComment = {
    ...comment,
    liked: false,
    likes: {
      ...comment.likes,
      items: [
        ...commentLikes.slice(0, commentLikeIndex),
        ...commentLikes.slice(commentLikeIndex + 1),
      ],
      total: shouldDecrement ? commentLikesTotal - 1 : commentLikesTotal,
    },
  };

  console.log('about to remove like from state...', newComment);

  put(updateComment(newComment));
  put(updatePost({ ...post }));
};

export const toggleLikeForActivity = async (activityId: string, activity?: any) => {
  if (!activity) {
    activity = select(getActivity, activityId);
  }

  const likedAlready = get(activity, 'liked', false);

  if (likedAlready) {
    return await fetchAll([
      removeLikeForActivity(activityId),
      // Analytics.track(AnalyticsEventType.activityUnliked, {
      //   planId: activityId,
      // }),
    ]);
  } else {
    return await fetchAll([
      addLikeForActivity(activityId),
      // Analytics.track(AnalyticsEventType.activityLiked, {
      //   planId: activityId,
      // }),
    ]);
  }
};

export const toggleLikeForPost = async (post: any) => {
  const likedAlready = get(post, 'liked', false);

  if (likedAlready) {
    return await fetchAll([
      removeLikeForPost(post),
      // Analytics.track(AnalyticsEventType.postUnliked, {
      //   postId: get(post, 'id'),
      // }),
    ]);
  } else {
    return await fetchAll([
      addLikeForPost(post),
      // Analytics.track(AnalyticsEventType.postLiked, {
      //   postId: get(post, 'id'),
      // }),
    ]);
  }
};

export const toggleLikeForComment = async (post: any, comment: any) => {
  const likedAlready = get(comment, 'liked', false);

  if (likedAlready) {
    return await fetchAll([
      removeLikeForComment(post, comment),
      // Analytics.track(AnalyticsEventType.commentUnliked, {
      //   postId: get(post, 'id'),
      //   commentId: get(comment, 'id'),
      // }),
    ]);
  } else {
    return await fetchAll([
      addLikeForComment(post, comment),
      // Analytics.track(AnalyticsEventType.commentLiked, {
      //   postId: get(post, 'id'),
      //   commentId: get(comment, 'id'),
      // }),
    ]);
  }
};
