/* Internal dependencies */
import { modelUser } from '../store/helpers/users';
import { PaginationOptions } from '../types/Pagination';
import API from '.';

export const listLikesForUser = async (userId: string, { limit = 15, offset }: PaginationOptions) => {
  const response = await API.get({
    path: `/users/${userId}/likes`,
    options: {
      queryParams: {
        limit,
        offset: (Boolean(offset) && JSON.stringify(offset)) || undefined,
      },
    },
  }) as any;

  response.likes = response.likes.map((like: any) => ({
    ...like,
    user: modelUser(like.user),
  }));

  return response;
};

export const addLikeForItem = async (userId: string, itemId: string) =>
  await API.post({
    path: `/users/${userId}/likes`,
    options: {
      body: { itemId },
    },
  });

export const removeLikeForItem = async (userId: string, likeId: string) =>
  await API.del({
    path: `/users/${userId}/likes/${likeId}`,
  });
