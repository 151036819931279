/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { modelUser } from '../store/helpers/users';
import { PaginationOptions } from 'src/types/Pagination';
import { cleanObject } from 'src/common/cleanObject';
import { User } from 'src/types/User';
import API from '.';

export const addFeedPostForUser = async (userId: string, feedPost: any) =>
  await API.post({
    path: `/users/${userId}/feedPosts`,
    options: {
      body: feedPost,
    },
  });

export const removeFeedPostForUser = async (userId: string, feedPostId: string) =>
  await API.del({
    path: `/users/${userId}/feedPosts/${feedPostId}`,
  });

export const getFeedPost = async (feedPostId: string, options: { cache?: boolean } = { cache: true }) => {
  const response = await API.get({
    path: `/feedPosts/${feedPostId}`,
    options: {
      queryParams: {
        cache: options.cache,
      },
    },
  }) as any;

  return {
    ...response,
    users: response.users && {
      ...response.users,
      items: response.users.items.map(modelUser),
    },
  };
};

export const feedView = async (options: PaginationOptions & { latitude: number; longitude: number; contentType?: 'feedPost' | 'event'; eventId?: string; friendsOnly?: boolean }) => {
  const response = await API.get({
    path: '/feedPosts/feedView',
    options: {
      queryParams: cleanObject(options),
    },
  }) as any;

  const { items: feedItems = [] } = response;
  feedItems.forEach((feedItem: any) => {
    if (feedItem.type === 'collection') {
      const collection = feedItem.payload;
      const { items: collectionItems = [] } = collection;

      collection.items = collectionItems.map((item: any) => {
        if (item.type === 'user') {
          item = modelUser(item);
        }

        if (item.type === 'activity') {
          item.creator = modelUser(item.creator);
          item.creators = {
            ...item.creators,
            items: get(item, 'creators.items', []).map(modelUser),
          };
          item.participants = {
            ...item.participants,
            items: get(item, 'participants.items', []).map((item: User) => modelUser(item)),
          };
          item.requests = {
            ...item.requests,
            items: get(item, 'requests.items', []).map((item: User) => modelUser(item)),
          };
        }

        if (item.type === 'event') {
          item.users = {
            ...item.users,
            items: get(item, 'users.items', []).map((item: User) => modelUser(item)),
          };
        }

        return item;
      });
    } else if (feedItem.type === 'feedPost') {
      const post = feedItem.payload;
      const { items: attachments = {}, comments } = post;
      const { items: postAttachments = [] } = attachments;
      const { items: postComments = [] } = comments;

      post.creators = {
        ...post.creators,
        items: get(post, 'creators.items', []).map(modelUser),
      };

      postComments.forEach((comment: any) => {
        comment.users = {
          ...comment.users,
          items: get(comment, 'users.items', []).map(modelUser),
        };

        const { replies = {} } = comment;
        const { items: commentReplies = [] } = replies;

        commentReplies.forEach((reply: any) => {
          reply.users = {
            ...reply.users,
            items: get(reply, 'users.items', []).map(modelUser),
          };
        });
      });

      attachments.items = postAttachments.map((attachment: any) => {
        if (attachment.type === 'activity') {
          attachment.creator = attachment.creator && modelUser(attachment.creator);
          attachment.creators = {
            ...attachment.creators,
            items: get(attachment, 'creators.items', []).map(modelUser),
          };
          attachment.participants = attachment.participants && {
            ...attachment.participants,
            items: get(attachment, 'participants.items', []).map(modelUser),
          };
          attachment.requests = attachment.requests && {
            ...attachment.requests,
            items: get(attachment, 'requests.items', []).map(modelUser),
          };
        }

        if (attachment.type === 'event') {
          attachment.users = attachment.users && {
            ...attachment.users,
            items: get(attachment, 'users.items', []).map(modelUser),
          };
        }

        return attachment;
      });
    }
  });

  return response;
};

export const listCommentsForFeedPost = async (feedPostId: string, options: PaginationOptions) => {
  const response = await API.get({
    path: `/feedPosts/${feedPostId}/comments`,
    options: {
      queryParams: cleanObject({ ...options }),
    },
  }) as any;

  response.items.forEach((comment: any) => {
    comment.users = {
      ...comment.users,
      items: get(comment, 'users.items', []).map(modelUser),
    };

    const { replies = {} } = comment;
    const { items: commentReplies = [] } = replies;

    commentReplies.forEach((reply: any) => {
      reply.users = {
        ...reply.users,
        items: get(reply, 'users.items', []).map(modelUser),
      };
    });
  });

  return response;
};

export const listRepliesForComment = async (feedPostId: string, commentId: string, options: PaginationOptions) => {
  const response = await API.get({
    path: `/feedPosts/${feedPostId}/comments/${commentId}/replies`,
    options: {
      queryParams: cleanObject({ ...options }),
    },
  }) as any;

  response.items.forEach((reply: any) => {
    reply.users = {
      ...reply.users,
      items: get(reply, 'users.items', []).map(modelUser),
    };
  });

  return response;
};

export const listLikesForFeedPost = async (feedPostId: string, options: PaginationOptions) => {
  const response = await API.get({
    path: `/feedPosts/${feedPostId}/likes`,
    options: {
      queryParams: cleanObject({ ...options }),
    },
  }) as any;

  response.items = response.items.map(modelUser);

  return response;
};
