/* Internal dependencies */
import { modelUser } from '../store/helpers/users';
import { cleanObject } from '../common/cleanObject';
import { PaginationOptions } from '../types/Pagination';
import API from '.';

type ListChatsForUserOption = {
  status: 'confirmed' | 'pending';
  types: string;
} & PaginationOptions;

export const listChatsForUser = async (userId: string, { limit = 15, offset, status = 'confirmed', types = 'user,activity' }: ListChatsForUserOption) => {
  const response = await API.get({
    path: `/users/${userId}/chats`,
    options: {
      queryParams: cleanObject({
        limit,
        offset,
        status,
        types,
      }),
    },
  }) as any;

  response.items = response.items.map((chat: any) => {
    return {
      ...chat,
      user: chat && chat.user && modelUser(chat.user),
      participants: chat.participants && {
        ...chat.participants,
        items: chat.participants.items && chat.participants.items.map(modelUser),
      },
    };
  });

  return response;
};
