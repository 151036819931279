/* External dependencies */
import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

/* Internal dependencies */
import Button, { Props as ButtonProps } from 'src/button/Button';
import './DrawerButton.scss';

type OwnProps = ButtonProps & {
  open: boolean;
  openDrawer(): void;
  closeDrawer(): void;
  header?: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
  modalProps?: Partial<ModalProps>;
  render?: (onClick: () => void) => React.ReactNode;
};

type Props = OwnProps;

const DrawerButton: React.FC<Props> = (props) => {
  const {
    open,
    openDrawer,
    closeDrawer,
    header,
    content,
    footer,
    modalProps,
    render,
  } = props;

  const handleClick = () => {
    openDrawer && openDrawer();
  };

  const modal = (
    <Modal
      {...modalProps}
      show={open}
      onShow={openDrawer}
      onHide={closeDrawer}
      className="bm-DrawerButton__modal bm-Modal--mobile text-white"
      contentClassName="bg-secondaryButton"
      scrollable={false}
      size="lg"
    >
      {Boolean(header) && (
        <Modal.Header>
          {header}
        </Modal.Header>
      )}
      <Modal.Body>
        {content}
      </Modal.Body>
      {Boolean(footer) && (
        <Modal.Footer>
          {footer}
        </Modal.Footer>
      )}
    </Modal>
  );

  if (render) {
    return (
      <>
        {render(handleClick)}
        {modal}
      </>
    )
  }

  return (
    <>
      <Button {...props} onClick={handleClick} />
      {modal}
    </>
  );
};

export default DrawerButton;